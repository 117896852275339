import React, { useMemo, useState } from 'react';
import { SelectField } from '@beewise/select-field';
import PropTypes from 'prop-types';
import { fetchFeedBhomes } from 'components/views/Dashboard/actions';
import { useDispatch } from 'react-redux';
import Grid from '../Grid';
import { feedingColumns } from '../utils';
import { getFilteredData, FED_HIVES_OPTIONS, FILTER_VALUES } from './utils';
import FeedButton from './FeedButton';

const FeedGrid = ({ data, isFetched, setApiRef }) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(FILTER_VALUES.ALL);
    const [selectedRows, setSelectedRows] = useState([]);

    const filteredData = useMemo(() => getFilteredData({ filter, data }), [filter, data]);

    const handleFetchFeedBhomes = () => dispatch(fetchFeedBhomes(selectedRows, () => setSelectedRows([])));

    return (
        <>
            <div className="row feed-grid-actions">
                <SelectField options={FED_HIVES_OPTIONS} size="small" value={filter} onChange={setFilter} />
                <FeedButton disabled={!selectedRows.length} onClick={handleFetchFeedBhomes} />
            </div>
            <Grid
                columns={feedingColumns}
                data={filteredData}
                isFetched={isFetched}
                setApiRef={setApiRef}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={setSelectedRows}
                rowSelectionModel={selectedRows}
            />
        </>
    );
};

FeedGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    setApiRef: PropTypes.func,
    isFetched: PropTypes.bool,
};

export default FeedGrid;
