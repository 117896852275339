import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRegions } from 'components/views/Settings/actions';
import HiveAssessment from './HiveAssessment';
import BeehomeStats from './BeehomeStats';
import YardStats from './YardStats';

import './Widgets.scss';

const Widgets = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);

    return (
        <div className="beehome-widgets">
            <HiveAssessment />
            <BeehomeStats />
            <YardStats />
        </div>
    );
};

export default Widgets;
