import React from 'react';
import dayjs from 'dayjs';
import constants from 'appConstants';

const getDefaultDiffText = ({ diff, date = null }) => {
    let endString =
        diff === 0 ? (
            'Today'
        ) : (
            <>
                {diff} day{diff === 1 ? '' : 's'} ago
            </>
        );

    if (date) {
        endString = `Last syrup fill: ${new Date(date).toLocaleDateString()}`;
    }

    return <div>{endString}</div>;
};

const getFrameTooltip = ({ frame }) => {
    if (!frame?.frameId) {
        return null;
    }

    if (frame?.type === constants.FRAME_TYPES.FEEDER && !!frame?.previousUpdateDate) {
        const diff = dayjs().startOf('day').diff(dayjs(frame.lastUpdated).startOf('day'), 'day');
        return getDefaultDiffText({ diff, date: frame.lastUpdated });
    }
};

export default getFrameTooltip;
