import { useMemo } from 'react';

const useTransformedImagesData = imagesData =>
    useMemo(() => {
        const extractImages = (sideData, resizedData) => ({
            annotatedFileName: sideData?.annotatedFileName,
            resizedAnnotatedFileName: resizedData?.annotatedFileName,
            fileName: sideData?.fileName,
            resizedFileName: resizedData?.fileName,
        });

        const beeCountImages = {
            sideA: extractImages(imagesData?.fullImages?.sideA?.beeCount, imagesData?.resizedImages?.sideA?.beeCount),
            sideB: extractImages(imagesData?.fullImages?.sideB?.beeCount, imagesData?.resizedImages?.sideB?.beeCount),
        };

        const frameImages = {
            sideA: {
                annotatedFileName: imagesData?.fullImages?.sideA?.annotatedFileName,
                resizedAnnotatedFileName: imagesData?.resizedImages?.sideA?.annotatedFileName,
                fileName: imagesData?.fullImages?.sideA?.fileName,
                resizedFileName: imagesData?.resizedImages?.sideA?.fileName,
            },
            sideB: {
                annotatedFileName: imagesData?.fullImages?.sideB?.annotatedFileName,
                resizedAnnotatedFileName: imagesData?.resizedImages?.sideB?.annotatedFileName,
                fileName: imagesData?.fullImages?.sideB?.fileName,
                resizedFileName: imagesData?.resizedImages?.sideB?.fileName,
            },
        };

        return { beeCountImages, frameImages };
    }, [imagesData]);

export default useTransformedImagesData;
