import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';

import './SearchBox.scss';

const SearchBox = ({ onPlacesChanged, updateYardAndCoordsInfo, setNewLocationType }) => {
    const input = useRef(null);
    const searchBox = useRef(null);

    const [searchValue, setSearchValue] = useState();

    const handleOnPlacesChanged = useCallback(() => {
        if (!onPlacesChanged) return;
        const selectedPlaces = searchBox.current.getPlaces();
        onPlacesChanged(selectedPlaces);

        const firstSelectedPlace = selectedPlaces[0];
        if (firstSelectedPlace) {
            updateYardAndCoordsInfo({
                editYard: null,
                createCoords: {
                    lat: firstSelectedPlace.geometry.location.lat(),
                    lng: firstSelectedPlace.geometry.location.lng(),
                },
            });
        }
    }, [onPlacesChanged, updateYardAndCoordsInfo]);

    useEffect(() => {
        if (!searchBox.current && window?.google?.maps) {
            const inp = document.querySelector('input.search-box-input');
            searchBox.current = new window.google.maps.places.SearchBox(inp);
            searchBox.current.addListener('places_changed', handleOnPlacesChanged);
        }

        return () => {
            if (window?.google?.maps) {
                window.google?.maps?.event?.clearInstanceListeners(searchBox);
            }
        };
    }, [handleOnPlacesChanged]);

    const handleSearchChange = useCallback(e => {
        setSearchValue(e.target.value);
    }, []);

    const handleClearClick = useCallback(() => {
        setSearchValue(null);
        input.current.value = null;
    }, []);

    const handleAddClick = () => {
        window.google.maps.event.trigger(input.current, 'focus', {});
        window.google.maps.event.trigger(input.current, 'keydown', { keyCode: 13 });
        setSearchValue(null);
        input.current.value = null;
        setNewLocationType(constants.LOCATION_TYPES.IDLE);
    };

    return (
        <div className="v1-search-box">
            {searchValue ? (
                <>
                    <FontAwesomeIcon icon={faPlusCircle} className="v1-search-box-clear" onClick={handleAddClick} />
                    <FontAwesomeIcon icon={faTimesCircle} className="v1-search-box-clear" onClick={handleClearClick} />
                </>
            ) : (
                <FontAwesomeIcon icon={faSearch} className="v1-search-box-icon" />
            )}
            <input ref={input} placeholder="Search..." className="v1-search-box-input" onChange={handleSearchChange} />
        </div>
    );
};

SearchBox.propTypes = {
    onPlacesChanged: PropTypes.func,
    updateYardAndCoordsInfo: PropTypes.func,
    setNewLocationType: PropTypes.func,
};

export default SearchBox;
