import { useMemo } from 'react';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash-es';
import { isBhomeFramesFetched } from 'components/reusables/Alerts/selectors';
import { getCurrentBhomeFrames } from '../../components/views/BeeHome/selectors';

const defaultResult = [];

const useMarkedHives = () => {
    const data = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);
    const existingMarkedHives = useSelector(state => state.beehome.markedHives, arrayOfObjectsShallowEqual);
    const isFetched = useSelector(isBhomeFramesFetched);

    return useMemo(() => {
        if (!isFetched || !data?.length) {
            return defaultResult;
        }

        return sortBy(existingMarkedHives, ['station', 'from']);
    }, [data?.length, existingMarkedHives, isFetched]);
};

export default useMarkedHives;
