import { useMemo } from 'react';
import { parseImageName } from 'utils/hive';

const useCombFrameImages = (frame, frameImages, side, showAnnotatedPhotos) =>
    useMemo(() => {
        const sideKey = `side${side}`;

        if (showAnnotatedPhotos) {
            const { annotatedFileName, resizedAnnotatedFileName } = parseImageName(frame, frameImages, sideKey);
            return { url: annotatedFileName, resizedUrl: resizedAnnotatedFileName };
        }

        const sideImages = frameImages[sideKey] || {};

        return { url: sideImages?.fileName, resizedUrl: sideImages?.resizedFileName };
    }, [frame, frameImages, showAnnotatedPhotos, side]);

export default useCombFrameImages;
