import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faAngleDown, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import Activity from './Activity';

import './Panes.scss';

const QueuePane = ({ pendingActivities, currentBhomeId, handleGoInsideHiveOrFrame }) => {
    const [paginationCounter, setPaginationCounter] = useState(1);

    const handleShowMoreClick = useCallback(() => {
        setPaginationCounter(prevValue => prevValue + 1);
    }, []);

    const dataToShow = useMemo(
        () => pendingActivities.data.slice(0, 5 * paginationCounter),
        [paginationCounter, pendingActivities]
    );

    return (
        <div className="queue-pane">
            <div className="queue-pane-estimation">
                <FontAwesomeIcon icon={faClock} className="clock-icon" />
                {pendingActivities.data.length} action
                {pendingActivities.data.length === 1 ? '' : 's'} pending |{' '}
                {Math.floor(pendingActivities.estimatedGeneralTime)}min (estimated)
            </div>
            <div className="queue-pane-list">
                {dataToShow.map(activity => (
                    <Activity
                        key={activity.renderId || activity.id}
                        activity={activity}
                        currentBhomeId={currentBhomeId}
                        isInProgress
                        handleGoInsideHiveOrFrame={handleGoInsideHiveOrFrame}
                    />
                ))}
            </div>
            {pendingActivities.data.length > 5 * paginationCounter && (
                <div className="pagination-button" role="presentation" onClick={handleShowMoreClick}>
                    <FontAwesomeIcon icon={faAngleDown} className="pagination-button-arrow" /> Show more
                </div>
            )}
            <div className="populated-areas-warning">
                <FontAwesomeIcon icon={faCircleInfo} className="circle-info" />
                <div className="populated-areas-warning-text">
                    Frame extraction activities that involve populated areas will be carried out during the daytime
                    only. The queue remains for the following day.
                </div>
            </div>
        </div>
    );
};

QueuePane.propTypes = {
    pendingActivities: PropTypes.shape(),
    currentBhomeId: PropTypes.number,
    handleGoInsideHiveOrFrame: PropTypes.func,
};

export default QueuePane;
