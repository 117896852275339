import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash-es';
import constants from 'appConstants';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { Tab, TabPane } from '@beewise/tab';
import { getInProgressActivities, getDoneActivities, getCurrentBeehomeId } from 'components/views/BeeHome/selectors';
import { setFrameToInspectByRfid } from 'components/views/BeeHome/actions';
import { getDataWithTimePerActivity } from './utils';
import CurrentActivity from './CurrentActivity';
import HistoryPane from './HistoryPane';
import QueuePane from './QueuePane';
import { EmptyPanePlaceholder, getGroupedData } from '../utils';

import './Activities.scss';

const Activities = ({ frame = false }) => {
    const dispatch = useDispatch();
    const inProgressData = useSelector(getInProgressActivities, arrayOfObjectsShallowEqual);
    const historyData = useSelector(getDoneActivities, arrayOfObjectsShallowEqual);
    const currentBhomeId = useSelector(getCurrentBeehomeId);

    const currentActivity = useMemo(
        () => inProgressData?.filter(activity => activity.status === constants.MESSAGE_STATUS.PROCESSING)[0],
        [inProgressData]
    );

    const getActivitiesToShow = useCallback(
        data => {
            if (!isNumber(frame.id)) {
                return data;
            } else {
                return data.filter(activity => {
                    switch (activity.command) {
                        case constants.COMMANDS.SCAN:
                        case constants.COMMANDS.COUNT_BEES:
                            return activity.sequences.some(seq => {
                                if (
                                    seq.command === constants.COMMANDS.SCAN_FRAME ||
                                    seq.command === constants.COMMANDS.COUNT_BEES_FRAME
                                ) {
                                    return seq?.params?.frame?.id === frame.rfid;
                                }
                                return false;
                            });
                        case constants.COMMANDS.FILL_FEEDER:
                            return activity.sequences.some(seq => {
                                if (seq.command === constants.COMMANDS.FILL_SYRUP) {
                                    return seq?.params?.frame?.id === frame.rfid;
                                }
                                return false;
                            });
                        default:
                            return false;
                    }
                });
            }
        },
        [frame.id, frame.rfid]
    );

    const dataWithTimePerActivity = useMemo(
        () => getDataWithTimePerActivity(getActivitiesToShow(inProgressData), currentActivity),
        [getActivitiesToShow, inProgressData, currentActivity]
    );

    const handleGoInsideHiveOrFrame = useCallback(
        activity => e => {
            e.stopPropagation();

            if (activity.rfid) {
                dispatch(setFrameToInspectByRfid(activity.rfid));
            }
        },
        [dispatch]
    );

    const panes = [
        {
            menuItem: 'Queue',
            render: () => (
                <TabPane>
                    {dataWithTimePerActivity?.data?.length ? (
                        <QueuePane
                            pendingActivities={dataWithTimePerActivity}
                            currentBhomeId={currentBhomeId}
                            handleGoInsideHiveOrFrame={handleGoInsideHiveOrFrame}
                        />
                    ) : (
                        <EmptyPanePlaceholder text="No activities in the queue" />
                    )}
                </TabPane>
            ),
        },
        {
            menuItem: 'History',
            render: () => (
                <TabPane>
                    {getActivitiesToShow(historyData).length ? (
                        <HistoryPane
                            groupedHistoryData={getGroupedData(getActivitiesToShow(historyData), 'sent_at')}
                            handleGoInsideHiveOrFrame={handleGoInsideHiveOrFrame}
                        />
                    ) : (
                        <EmptyPanePlaceholder text="No activity history to display" />
                    )}
                </TabPane>
            ),
        },
    ];

    return (
        <div className="right-menu-block activities">
            <div className="right-menu-block-header">Activities</div>
            {currentActivity && (
                <CurrentActivity
                    currentActivity={currentActivity}
                    frame={frame}
                    handleGoInsideHiveOrFrame={handleGoInsideHiveOrFrame}
                />
            )}
            <div className="right-menu-block-body">
                <Tab panes={panes} />
            </div>
        </div>
    );
};

Activities.propTypes = {
    frame: PropTypes.shape().isRequired,
};

export default Activities;
