import { put, takeEvery } from 'redux-saga/effects';
import { CLEAR_OPERATION_ERRORS, SET_CURRENT_BHOME, FETCH_CURRENT_BHOME } from 'components/views/BeeHome/actionTypes';
import { MODAL_CLOSED } from 'actionTypes';

function* onOperationModalClose() {
    yield put({ type: CLEAR_OPERATION_ERRORS });
}

function* onFetchBhome(action) {
    if (action.data.bhome.id) {
        yield put({ type: SET_CURRENT_BHOME, bhome: action.data.bhome });
    }
}

function* root() {
    yield takeEvery(FETCH_CURRENT_BHOME.success, onFetchBhome);
    yield takeEvery(MODAL_CLOSED, onOperationModalClose);
}

export default root;
