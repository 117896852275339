import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faCircleArrowUpRight, faCircleArrowDownRight } from '@fortawesome/pro-light-svg-icons';

import useMarkedHives from 'utils/useMarkedHives';
import { getHiveAmountPerGrade } from './utils';

const HiveAssessment = () => {
    const markedHives = useMarkedHives();
    const hivesPerGrades = getHiveAmountPerGrade(markedHives);

    const getWeakHivesIcon = () => {
        if (hivesPerGrades?.weakHives === hivesPerGrades?.historyWeakHives) {
            return faMinus;
        }
        return hivesPerGrades?.weakHives >= hivesPerGrades?.historyWeakHives
            ? faCircleArrowUpRight
            : faCircleArrowDownRight;
    };

    const getMediumHivesIcon = () => {
        if (hivesPerGrades?.mediumHives === hivesPerGrades?.historyMediumHives) {
            return faMinus;
        }
        return hivesPerGrades?.mediumHives >= hivesPerGrades?.historyMediumHives
            ? faCircleArrowUpRight
            : faCircleArrowDownRight;
    };

    const getStrongHivesIcon = () => {
        if (hivesPerGrades?.strongHives === hivesPerGrades?.historyStrongHives) {
            return faMinus;
        }
        return hivesPerGrades?.strongHives >= hivesPerGrades?.historyStrongHives
            ? faCircleArrowUpRight
            : faCircleArrowDownRight;
    };

    const getDeadHivesIcon = () => {
        if (hivesPerGrades?.deadHives === hivesPerGrades?.historyDeadHives) {
            return faMinus;
        }
        return hivesPerGrades?.deadHives >= hivesPerGrades?.historyDeadHives
            ? faCircleArrowUpRight
            : faCircleArrowDownRight;
    };

    return (
        <div className="hive-assessment-widget">
            <div className="hive-assessment-widget-block">
                <div className="hive-assessment-widget-data">
                    <h2>Weak</h2>
                    <h3>
                        {hivesPerGrades?.weakHives} hive
                        {hivesPerGrades?.weakHives === 1 ? '' : 's'}
                    </h3>
                </div>
                <FontAwesomeIcon icon={getWeakHivesIcon()} className="hive-assessment-widget-icon" />
            </div>
            <div className="hive-assessment-widget-block">
                <div className="hive-assessment-widget-data">
                    <h2>Medium</h2>
                    <h3>
                        {hivesPerGrades?.mediumHives} hive
                        {hivesPerGrades?.mediumHives === 1 ? '' : 's'}
                    </h3>
                </div>
                <FontAwesomeIcon icon={getMediumHivesIcon()} className="hive-assessment-widget-icon" />
            </div>
            <div className="hive-assessment-widget-block">
                <div className="hive-assessment-widget-data">
                    <h2>Strong</h2>
                    <h3>
                        {hivesPerGrades?.strongHives} hive
                        {hivesPerGrades?.strongHives === 1 ? '' : 's'}
                    </h3>
                </div>
                <FontAwesomeIcon icon={getStrongHivesIcon()} className="hive-assessment-widget-icon" />
            </div>
            <div className="hive-assessment-widget-block">
                <div className="hive-assessment-widget-data">
                    <h2>Dead</h2>
                    <h3>
                        {hivesPerGrades?.deadHives} hive
                        {hivesPerGrades?.deadHives === 1 ? '' : 's'}
                    </h3>
                </div>
                <FontAwesomeIcon icon={getDeadHivesIcon()} className="hive-assessment-widget-icon" />
            </div>
        </div>
    );
};

export default HiveAssessment;
