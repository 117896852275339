import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { getFrameStation } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { setFrameToInspectByRfid } from 'components/views/BeeHome/actions';
import { getActionData } from 'components/reusables/RightPanel/components/BhomeActions/actionsData';

export const UnsavedActivitiesList = ({ actionMode, unsavedActivities }) => {
    const dispatch = useDispatch();

    const activityData = getActionData(actionMode);

    const handleActivitySourceClick = useCallback(
        rfid => () => {
            dispatch(setFrameToInspectByRfid(rfid));
        },
        [dispatch]
    );

    const renderUnsavedActivitiesList = useCallback(() => {
        if (actionMode === constants.COMMANDS.FILL_FEEDER) {
            return unsavedActivities.map(activity => (
                <div
                    key={`uale-${activity.rfid}`}
                    className="unsaved-activities-list-item-content-activity"
                    role="presentation"
                    onClick={handleActivitySourceClick(activity.rfid)}
                >
                    Hive {activity?.hiveNumber}({activity?.station.toUpperCase()}) Frame {activity?.frameNumber}
                </div>
            ));
        } else if (actionMode === constants.COMMANDS.SCAN) {
            return unsavedActivities.map(activity => (
                <div
                    key={`uale-${activity.rfid}`}
                    className="unsaved-activities-list-item-content-activity"
                    role="presentation"
                    onClick={handleActivitySourceClick(activity.rfid)}
                >
                    Station {getFrameStation(activity.slotIndex)} / Frame {activity.slotIndex + 1}
                </div>
            ));
        }
        return null;
    }, [actionMode, unsavedActivities, handleActivitySourceClick]);

    return (
        <div className="unsaved-activities-list">
            <div className="unsaved-activities-list-item">
                <div className="unsaved-activities-list-item-header">
                    <FontAwesomeIcon icon={activityData?.icon} className="unsaved-activities-list-item-header-icon" />
                    <div className="unsaved-activities-list-item-header-title">{activityData?.name}</div>
                </div>
                <div className="unsaved-activities-list-item-content">{renderUnsavedActivitiesList()}</div>
                <div className="unsaved-activities-list-item-time">
                    <FontAwesomeIcon icon={faClock} className="unsaved-activities-list-item-time-icon" />
                    <div className="unsaved-activities-list-item-time-est">
                        {Math.floor(activityData.time * unsavedActivities.length)} min (estimated)
                    </div>
                </div>
            </div>
        </div>
    );
};

UnsavedActivitiesList.propTypes = {
    actionMode: PropTypes.string,
    unsavedActivities: PropTypes.arrayOf(PropTypes.shape()),
};
