import React from 'react';
import { useToggle } from '@beewise/react-utils';
import PropTypes from 'prop-types';
import EditLocationForm from '../EditLocationForm';
import LocationCard from '../LocationCard';

const TooltipContent = ({ id, name, bhomeIds, handleHideTooltip }) => {
    const [isEditMode, , setEditMode, unsetEditMode] = useToggle(false);

    return isEditMode ? (
        <EditLocationForm id={id} name={name} onClose={unsetEditMode} />
    ) : (
        <LocationCard
            id={id}
            name={name}
            setEditMode={setEditMode}
            bhomeIds={bhomeIds}
            handleHideTooltip={handleHideTooltip}
        />
    );
};

TooltipContent.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    bhomeIds: PropTypes.arrayOf(PropTypes.number),
    handleHideTooltip: PropTypes.func,
};

export default TooltipContent;
