import React, { memo, useCallback, useRef } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '@beewise/tooltip';
import { SelectField } from '@beewise/select-field';
import { useToggle } from '@beewise/react-utils';
import { useNavigate } from 'react-router-dom';
import { toggleInitialRender } from 'actions';
import AsidePanel from 'components/reusables/AsidePanel';
import { setZoomLevel } from 'components/views/BeeHome/actions';
import CustomScrollbars from './components/CustomScrollbars';
import BhomeItem from './components/BhomeItem';
import { getCurrentBhomeId } from '../../selectors';
import useFilters, { OTHER_LOCATIONS } from '../../hooks/useFilters';

import './LeftPanel.scss';

const tooltipText = (
    <div className="left-panel-header-tooltip-text">
        <p>Empty, bee, brood, and honey</p>
        <p>are Beehome frame averages</p>
    </div>
);

const LeftPanel = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isPanelOpen, , setIsPanelOpen, setIsPanelClose] = useToggle();

    const leftPanelHeaderRef = useRef();

    const currentBhomeId = useSelector(getCurrentBhomeId);

    const {
        entityOptions,
        entityInstanceOptions,
        filteredBhomes,
        entityFilter,
        entityInstanceFilter,
        handleSetEntityFilter,
        handleSetEntityInstanceFilter,
    } = useFilters();

    const handleItemClick = useCallback(
        bhome => {
            if (currentBhomeId !== bhome.id) {
                navigate(`/bhome/${bhome.id}`);
            }

            dispatch(toggleInitialRender(false));
            dispatch(setZoomLevel({ zoomLevel: null }));
        },
        [currentBhomeId, dispatch, navigate]
    );

    const renderRow = useCallback(
        ({ index, style }) => (
            <BhomeItem
                style={style}
                bhome={filteredBhomes[index]}
                key={index}
                handleItemClick={handleItemClick}
                isActive={filteredBhomes[index]?.id === currentBhomeId}
                syrupLevel={filteredBhomes[index]?.sensors?.syrupLevel}
            />
        ),
        [currentBhomeId, filteredBhomes, handleItemClick]
    );

    return (
        <div
            className={cx('left-panel-wrapper', { opened: isPanelOpen })}
            onMouseEnter={setIsPanelOpen}
            onMouseLeave={setIsPanelClose}
            role="presentation"
        >
            <FontAwesomeIcon
                className={cx('left-panel-icon', { opened: isPanelOpen })}
                icon={isPanelOpen ? faChevronLeft : faChevronRight}
            />
            <AsidePanel isPanelOpen={isPanelOpen} handleOpenPanel={setIsPanelOpen} onClose={setIsPanelClose}>
                <div className="left-panel">
                    <div ref={leftPanelHeaderRef}>
                        <div className="left-panel-header">
                            <div className="left-panel-header-block">
                                <p className="large-title">Beehomes</p>
                                <Tooltip content={tooltipText} position="top center">
                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                                </Tooltip>
                            </div>
                        </div>
                        <div className="left-panel-header left-panel-filter">
                            <div className="left-panel-filter-wrapper">
                                <SelectField
                                    className="left-panel-filter-block-dropdown"
                                    options={entityOptions}
                                    onChange={handleSetEntityFilter}
                                    value={entityFilter}
                                />
                                {entityFilter !== OTHER_LOCATIONS && (
                                    <SelectField
                                        className="left-panel-filter-block-dropdown"
                                        options={entityInstanceOptions}
                                        onChange={handleSetEntityInstanceFilter}
                                        value={entityInstanceFilter}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                height={height - (leftPanelHeaderRef?.current?.offsetHeight || 0)}
                                itemCount={filteredBhomes?.length}
                                itemSize={115}
                                width={width}
                                // use custom scroll bar with position
                                outerElementType={CustomScrollbars}
                            >
                                {renderRow}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            </AsidePanel>
        </div>
    );
};

export default memo(LeftPanel);
