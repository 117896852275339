import { chain, capitalize } from 'lodash-es';

const groupBhomesByEntity = ({ idKey, nameKey, bhomes }) => {
    const filteredBhomes = bhomes.filter(bhome => bhome[idKey]);
    return chain(filteredBhomes)
        .groupBy(idKey)
        .map((value, key) => ({
            id: key,
            bhomes: value,
            title: value?.[0][nameKey],
        }))
        .sortBy('title')
        .value();
};

const mapItemsToVirtualizedList = ({ virtualizedListItems, entityItems, groupName }) => {
    virtualizedListItems.push({
        title: capitalize(groupName),
        id: groupName,
        items: entityItems,
    });
};

export const OTHER_LOCATIONS = 'other locations';

const LABELS = {
    RANCHES: 'ranch',
    YARDS: 'yard',
    UNASSIGNED: OTHER_LOCATIONS,
};

export const groupVirtualizedListData = bhomes => {
    const unassignedBhomes = bhomes.filter(bhome => !bhome.ranch_id && !bhome.yard_id);
    const ranches = groupBhomesByEntity({ idKey: 'ranch_id', nameKey: 'ranch_name', bhomes });
    const yards = groupBhomesByEntity({ idKey: 'yard_id', nameKey: 'yard_name', bhomes });
    const virtualizedListItems = [];
    if (ranches.length) {
        mapItemsToVirtualizedList({
            virtualizedListItems,
            entityItems: ranches,
            groupName: LABELS.RANCHES,
        });
    }
    if (yards.length) {
        mapItemsToVirtualizedList({
            virtualizedListItems,
            entityItems: yards,
            groupName: LABELS.YARDS,
        });
    }
    if (unassignedBhomes.length) {
        mapItemsToVirtualizedList({
            virtualizedListItems,
            entityItems: unassignedBhomes,
            groupName: LABELS.UNASSIGNED,
        });
    }
    return virtualizedListItems;
};

export const generateSelectOptions = items =>
    items.map(item => ({
        value: item.id,
        label: item.title,
    }));

export const galonOptions = [
    {
        label: '0.45 gal',
        value: '0.45',
    },
    {
        label: '0.9 gal',
        value: '0.9',
    },
    {
        label: '1.35 gal',
        value: '1.35',
    },
];
