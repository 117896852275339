import constants from 'appConstants';
import { isEmpty } from 'lodash-es';

export const getCapacity = (frame, showFramesHistory) =>
    Object.keys(frame).reduce((acc, key) => {
        const lowerCasedKey = key.toLowerCase();
        if (
            lowerCasedKey.includes('side') &&
            frame[key] &&
            ((!showFramesHistory && !lowerCasedKey.includes('previous')) ||
                (showFramesHistory && lowerCasedKey.includes('previous')))
        ) {
            const side = Object.keys(frame[key]).reduce((sideAcc, sideKey) => {
                if (sideKey === 'cell') {
                    return sideAcc + frame[key][sideKey];
                }
                return sideAcc;
            }, 0);
            return acc + side;
        }
        return acc;
    }, 0);

export const getIsFoundationFrame = (frame, showFramesHistory) => {
    const capacity = getCapacity(frame, showFramesHistory);

    return (
        frame.type === constants.FRAME_TYPES.COMB_FRAME &&
        ((!showFramesHistory && isEmpty(frame.sideA) && isEmpty(frame.sideB)) ||
            (!showFramesHistory &&
                !isEmpty(frame.sideA) &&
                !isEmpty(frame.sideB) &&
                frame.sideA['cell-foundation'] + frame.sideB['cell-foundation'] > capacity * 0.8) ||
            (showFramesHistory && isEmpty(frame.previousSideA) && isEmpty(frame.previousSideB)) ||
            (showFramesHistory &&
                !isEmpty(frame.previousSideA) &&
                !isEmpty(frame.previousSideB) &&
                frame.previousSideA['cell-foundation'] + frame.previousSideB['cell-foundation'] > capacity * 0.8))
    );
};
