import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@beewise/tab';
import Button from '@beewise/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons';
import { hiveStatusColumns } from './utils';
import Grid from './Grid';
import FeedGrid from './FeedGrid';

import './DashboardGrid.scss';

const ExportButton = ({ apiRef }) => (
    <Button className="btn-export text-black" onClick={apiRef?.current?.exportDataAsPrint}>
        <FontAwesomeIcon icon={faArrowDownToLine} />
        <span>Download</span>
    </Button>
);

ExportButton.propTypes = {
    apiRef: PropTypes.shape(),
};

const TABS = {
    HIVE_STATUS: 'Hive status',
    FEEDING: 'Feeding',
};

let gridState = null;
let initTabOpen = 0;

const DashboardGrid = ({ data, isFetched }) => {
    const [apiRef, setApiRef] = useState(null);
    const [tab, setTab] = useState(initTabOpen);

    useEffect(() => {
        // save api ref and open tab inside hook because apiRef may be already removed on the time of unmount
        const gridRef = apiRef?.current;

        if (gridRef && gridState) {
            gridRef?.restoreState(gridState);
        }

        return () => {
            if (gridRef) {
                gridState = gridRef?.exportState();
                initTabOpen = tab;
            }
        };
    }, [apiRef, tab]);

    const panes = [
        {
            menuItem: TABS.HIVE_STATUS,
            render: () => <Grid columns={hiveStatusColumns} data={data} isFetched={isFetched} setApiRef={setApiRef} />,
        },
        {
            menuItem: TABS.FEEDING,
            render: () => <FeedGrid data={data} isFetched={isFetched} setApiRef={setApiRef} />,
        },
    ];

    return (
        <div className="grid-container">
            <ExportButton apiRef={apiRef} />
            <Tab panes={panes} onTabChange={setTab} initialOpenTabIndex={tab} />
        </div>
    );
};

DashboardGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    isFetched: PropTypes.bool,
};

export default DashboardGrid;
