import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import './Card.scss';
import { noop } from 'lodash-es';

const CheckboxItem = ({ isSelected, handleCheckboxClick, isSelectMode, isShiftHovered }) => (
    <span
        className={cn('checkbox-icon', {
            selected: isSelected,
            'select-mode': isSelectMode,
            'shift-hovered': isShiftHovered,
        })}
        role="presentation"
        onClick={handleCheckboxClick}
    >
        {!isSelected && <FontAwesomeIcon icon={faCircle} className="checkbox-icon_unselected" />}
        <FontAwesomeIcon
            icon={faCircleCheck}
            className={cn('checkbox-icon_selected', {
                selected: isSelected,
            })}
        />
    </span>
);

CheckboxItem.propTypes = {
    isSelected: PropTypes.bool,
    handleCheckboxClick: PropTypes.func,
    isSelectMode: PropTypes.bool,
    isShiftHovered: PropTypes.bool,
};

const Card = ({
    children,
    isSelected,
    onClick,
    className,
    handleCheckboxClick,
    isCheckboxShown,
    renderTitle,
    isSelectMode,
    onHover = noop,
    onMouseLeave = noop,
    isHovered,
    isShiftHovered,
    isInspected,
}) => (
    <div
        className={cn('card', className, {
            'select-mode': isSelectMode,
            selected: isSelected,
            inspected: isInspected,
            hovered: isHovered,
        })}
        onClick={onClick}
        role="presentation"
        onMouseEnter={onHover}
        onMouseLeave={onMouseLeave}
    >
        <div className="card-header">
            {isCheckboxShown
                ? renderTitle(
                      <CheckboxItem
                          isSelected={isSelected}
                          handleCheckboxClick={handleCheckboxClick}
                          isSelectMode={isSelectMode}
                          isHovered={isHovered}
                          isShiftHovered={isShiftHovered}
                      />
                  )
                : null}
        </div>
        {children}
    </div>
);

Card.propTypes = {
    children: PropTypes.node,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    handleCheckboxClick: PropTypes.func,
    isCheckboxShown: PropTypes.bool,
    renderTitle: PropTypes.func,
    isSelectMode: PropTypes.bool,
    onHover: PropTypes.func,
    onMouseLeave: PropTypes.func,
    isHovered: PropTypes.bool,
    isShiftHovered: PropTypes.bool,
    isInspected: PropTypes.bool,
};

export default Card;
