import { FullStory, init } from '@fullstory/browser';
import { FULL_STORY_ORG } from 'config';
import { isProduction } from 'utils';

export const initFullStory = () => {
    if (isProduction()) {
        init({ orgId: FULL_STORY_ORG });
    }
};

export const identifyFullStoryUser = user => {
    if (isProduction()) {
        FullStory('setProperties', {
            type: 'user',
            properties: {
                displayName: user.email,
                id: user.id,
                email: user.email,
            },
        });
    }
};
