import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import TextField from '@beewise/text-field';
import { signOut } from 'components/views/SignIn/actions/sign.in.actions';
import Checkbox from '@beewise/checkbox-field';
import { get } from 'lodash-es';
import { getUser } from 'selectors';
import { getUserSettings } from '../../selectors';

const Profile = ({ handleCheckSettingsCheckbox }) => {
    const dispatch = useDispatch();
    const user = useSelector(getUser, shallowEqual);
    const userSettings = useSelector(getUserSettings, shallowEqual);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    return (
        <div className="settings-profile">
            <div className="settings-profile-item">
                <h2>Details</h2>
                <div className="settings-profile-form">
                    <TextField disabled name="username" label="Name" value={user?.username} />
                    <TextField disabled name="email" label="Email" value={user?.email} />
                    <div className="settings-profile-actions">
                        <div className="reset-password">Reset Password</div>
                        <button className="btn btn-secondary logout" onClick={handleSignOut}>
                            Log out
                        </button>
                    </div>
                </div>
            </div>
            <div className="settings-profile-item">
                <h2>UI Settings</h2>
                <div className="settings-profile-form">
                    <Checkbox
                        label="Collapse Ranches"
                        className="settings-profile-checkbox"
                        onChange={handleCheckSettingsCheckbox('collapseRanches', 'ui')}
                        checked={get(userSettings, 'data.ui.collapseRanches')}
                    />
                </div>
            </div>
        </div>
    );
};

Profile.propTypes = {
    handleCheckSettingsCheckbox: PropTypes.func.isRequired,
};

export default Profile;
