import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { getFrameLeftPosition } from '../../utils';

const BeeFrame = ({ frame, commonWidth, isInsideHive, hiveStart, hiveEnd, beeCountFrameShift }) => {
    const beesPercent = (frame?.sideA?.beeCount?.beesPercent || 0) + (frame?.sideB?.beeCount?.beesPercent || 0);
    const isCombFrame = constants.FRAME_TYPES.COMB_FRAME === frame.type;

    if (!beesPercent || !isCombFrame) {
        return;
    }
    return (
        <div
            className={cx('bee-count-frame', {
                'reduced-opacity': isInsideHive,
            })}
            style={{
                left: `${getFrameLeftPosition({
                    positionX: frame.place.position.x,
                    hiveStart,
                    hiveEnd,
                    frameShift: beeCountFrameShift,
                })}%`,
                height: `${beesPercent / 2}%`,
                width: `${commonWidth}%`,
            }}
        />
    );
};

BeeFrame.propTypes = {
    frame: PropTypes.shape(),
    commonWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isInsideHive: PropTypes.bool,
    hiveStart: PropTypes.number,
    hiveEnd: PropTypes.number,
    beeCountFrameShift: PropTypes.number,
};

export default BeeFrame;
