import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import {
    getInspectedFrame,
    getShowFramesHistory,
    getStations,
    getSelectedFramesForBalance,
} from 'components/views/BeeHome/selectors';
import { setFrameToInspect, setSelectedFramesForBalance } from 'components/views/BeeHome/actions';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { noop } from 'lodash-es';
import StationFrame from './StationFrame';
import { getCurrentStationData, getHiveId } from '../../selectors/helpers/general.helpers';
import StationHive from './StationHive';

import './Station.scss';

const defaultStationStart = 0;
const defaultStationEnd = constants.DEFAULT_BHOME_4_SLOT_WIDTH * constants.SLOTS_PER_BHOME_4_STATION;

const Station = ({
    stationName,
    layout,
    hives,
    settings,
    isModalMode = false,
    onHiveSelectModalMode,
    selectedHiveModalMode,
    isBeeCountMode,
}) => {
    const dispatch = useDispatch();
    const selectedFramesForBalance = useSelector(getSelectedFramesForBalance, shallowEqual);
    const showFramesHistory = useSelector(getShowFramesHistory);
    const inspectedFrame = useSelector(getInspectedFrame, shallowEqual);
    const stations = useSelector(getStations, shallowEqual);

    const stationEnd = stations?.[stationName]?.end?.x ?? defaultStationEnd;
    const stationStart = stations?.[stationName]?.start?.x ?? defaultStationStart;

    const currentStationData = useMemo(
        () => getCurrentStationData({ currentLayout: layout, stationName }),
        [layout, stationName]
    );
    const currentStationHives = useMemo(() => hives.filter(hive => hive.station === stationName), [hives, stationName]);

    const isSelected = useMemo(() => {
        if (inspectedFrame?.frameId) {
            return currentStationData.some(frame => frame.frameId === inspectedFrame.frameId);
        }
        return false;
    }, [currentStationData, inspectedFrame?.frameId]);

    const handleFrameClick = useCallback(
        frame => e => {
            e.stopPropagation();
            dispatch(setFrameToInspect(frame));
        },
        [dispatch]
    );

    const handleFrameCheckboxSelect = useCallback(
        frameData => {
            const selectedFramesForBalanceCopy = { ...selectedFramesForBalance };

            if (frameData.isSelected) {
                selectedFramesForBalanceCopy[frameData.hiveId] = frameData.frame;
                dispatch(setSelectedFramesForBalance(selectedFramesForBalanceCopy));
            } else {
                delete selectedFramesForBalanceCopy[frameData.hiveId];
                dispatch(setSelectedFramesForBalance(selectedFramesForBalanceCopy));
            }
        },
        [dispatch, selectedFramesForBalance]
    );

    const renderedFramesMemoized = useMemo(
        () =>
            currentStationData.map((frame, index) => (
                <StationFrame
                    key={`shf-${frame.frameId || frame?.place?.position?.x}-${index}`}
                    frame={frame}
                    onFrameClick={isModalMode ? noop : handleFrameClick(frame)}
                    showFramesHistory={showFramesHistory}
                    stationStart={stationStart}
                    stationEnd={stationEnd}
                    stationName={stationName}
                    currentStationHives={currentStationHives}
                    onFrameCheckboxSelect={handleFrameCheckboxSelect}
                    isInspectedFrame={inspectedFrame?.frameId === frame?.frameId}
                    isBeeCountMode={isBeeCountMode}
                />
            )),
        [
            currentStationData,
            currentStationHives,
            handleFrameCheckboxSelect,
            handleFrameClick,
            inspectedFrame?.frameId,
            isBeeCountMode,
            isModalMode,
            showFramesHistory,
            stationEnd,
            stationName,
            stationStart,
        ]
    );

    const renderedHivesMemoized = useMemo(
        () =>
            currentStationHives.map((hive, index) => (
                <StationHive
                    hive={{ ...hive, index }}
                    key={getHiveId(hive)}
                    stationStart={stationStart}
                    stationEnd={stationEnd}
                    settings={settings}
                    index={index}
                    isModalMode={isModalMode}
                    onHiveSelectModalMode={onHiveSelectModalMode}
                    selectedHiveModalMode={selectedHiveModalMode}
                />
            )),
        [
            currentStationHives,
            isModalMode,
            onHiveSelectModalMode,
            selectedHiveModalMode,
            settings,
            stationEnd,
            stationStart,
        ]
    );

    return (
        <div className={cx('station', { 'is-selected': isSelected })} role="presentation">
            <div className="station-content">{renderedFramesMemoized}</div>
            <div className="station-hives-wrapper">{renderedHivesMemoized}</div>
        </div>
    );
};

Station.propTypes = {
    layout: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    stationName: PropTypes.string.isRequired,
    hives: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    settings: PropTypes.shape(),
    isModalMode: PropTypes.bool,
    onHiveSelectModalMode: PropTypes.func,
    selectedHiveModalMode: PropTypes.shape(),
    isBeeCountMode: PropTypes.bool,
};

export default Station;
