import { useEffect, useState } from 'react';

const newYorkTime = -3;

export const unitEnum = {
    WEIGHT: 'WEIGHT',
    TEMP: 'TEMP',
};

export const measureSystem = {
    METRIC: {
        name: 'METRIC',
        weight: 'kg',
        temp: '°C',
    },
    IMPERIAL: {
        name: 'IMPERIAL',
        weight: 'lbs',
        temp: '°F',
    },
};

// :toDO add functionality to  revert to metric system

export const convertToImperialUnits = (value, unit) => {
    switch (unit) {
        case unitEnum.WEIGHT:
            return value * 0.453592;
        case unitEnum.TEMP:
            return (value * 9) / 5 + 32;
        default:
            return null;
    }
};

const useLocation = () => {
    const [system, setMeasureSystem] = useState(measureSystem.METRIC);
    useEffect(() => {
        const offset = -(new Date().getTimezoneOffset() / 60);
        if (offset < newYorkTime) {
            setMeasureSystem(measureSystem.IMPERIAL);
        }
    }, []);

    return system;
};

export default useLocation;
