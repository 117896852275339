import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';
import { cancelActivities } from 'components/reusables/RightPanel/actions/activity.actions';
import Tooltip from '@beewise/tooltip';
import useGetFrameStationHiveAndFrameNumbers from 'utils/useGetFrameStationHiveAndFrameNumbers';
import dayjs from 'dayjs';
import { getActivityIcon, getActivityDescription } from '../utils';

const activityTitle = {
    [constants.COMMANDS.SCAN]: 'Scan',
    [constants.COMMANDS.COUNT_BEES]: 'Bee scan',
    [constants.COMMANDS.FILL_FEEDER]: 'Feeding',
    [constants.COMMANDS.MOVE_FRAMES]: 'Move',
    [constants.COMMANDS.CONTRACT_HIVE]: 'Contract hive',
    [constants.COMMANDS.EXPAND_HIVE]: 'Expand hive',
    [constants.COMMANDS.SHIFT_HIVE]: 'Shift hive',
    [constants.COMMANDS.BALANCE_HIVES]: 'Balance hive',
    [constants.COMMANDS.THERMAL_SCAN_BUNDLE]: 'Thermal scan hives',
};

const Activity = ({ activity, currentBhomeId, isInProgress, handleGoInsideHiveOrFrame }) => {
    const dispatch = useDispatch();

    const isActivityFailed = useMemo(() => activity.status === constants.MESSAGE_STATUS.FAILED, [activity.status]);
    const frame = activity.sequences?.filter(obj => obj.command === 'fillSyrup')?.[0]?.params?.frame;
    const frameInfo = useGetFrameStationHiveAndFrameNumbers(frame);

    const handleCancelActivity = useCallback(() => {
        dispatch(cancelActivities(currentBhomeId, [activity.id]));
    }, [activity.id, currentBhomeId, dispatch]);

    const activityDesctiption = useMemo(() => getActivityDescription(activity), [activity]);

    return (
        <div className="activity-list-item">
            <div className="activity-list-item-header">
                <FontAwesomeIcon
                    icon={getActivityIcon(activity)}
                    className={cx('activity-list-item-icon', {
                        failed: isActivityFailed,
                    })}
                />
                <div className="activity-list-item-title">
                    {activityTitle[activity.command] || 'Unknown action'} {isActivityFailed && ' failed'}
                </div>
                <div className="activity-list-item-initiator">{activity.user}</div>
                {isInProgress && (
                    <Tooltip text="Discard activity" position="left center">
                        <FontAwesomeIcon
                            icon={faCircleMinus}
                            className="activity-list-item-discard"
                            onClick={handleCancelActivity}
                        />
                    </Tooltip>
                )}
            </div>
            {activityTitle[activity.command] === 'Feeding' && frameInfo ? (
                <div className="activity-list-item-details">
                    Hive {frameInfo?.hiveNumber}({frameInfo?.station.toUpperCase()}) was fed 57.5 oz on{' '}
                    {dayjs(activity.sent_at).utc().format('MM/DD/YY')}
                </div>
            ) : (
                <div className="activity-list-item-details">
                    <FontAwesomeIcon icon={faClock} className="activity-list-item-details-clock" />
                    <div
                        className="activity-list-item-details-link"
                        role="presentation"
                        onClick={handleGoInsideHiveOrFrame(activityDesctiption)}
                    >
                        {activityDesctiption?.text}
                        {activity.estimatedTime ? ` | ${activity.estimatedTime} (Estimated)` : ''}
                    </div>
                </div>
            )}
        </div>
    );
};

Activity.propTypes = {
    activity: PropTypes.shape().isRequired,
    isInProgress: PropTypes.bool,
    currentBhomeId: PropTypes.number,
    handleGoInsideHiveOrFrame: PropTypes.func,
};

export default Activity;
