import constants from 'appConstants';

export const calculateUserRole = (canOperate, roles) => roles.ADMIN;
// canOperate ? roles.WRITE : roles.READ;

/* TODO: For now, this is hardcoded data. Remove it later. */
const BROWNINGS_HONEY_COMPANY_ID = 317;

export const PREFERENCE_TYPES = {
    BEE_COUNT_SCAN_POLICY: 'bee_count_scan_policy',
    SMART_INSPECTION: 'smart_inspection',
    FEEDING_POLICY: 'feeding_policy',
};

export const PREFERENCE_NAMES = {
    BEE_COUNT_SCAN_POLICY: 'Bee count scan policy',
    SMART_INSPECTION: 'Smart-inspection',
    FEEDING_POLICY: 'Feeding policy',
};

export const generatePreferencesOptions = (
    currentCompanyId,
    countPreferences,
    preferences,
    totalBeehomes,
    toggleBhomesPreference,
    generateDescription
) => {
    if (currentCompanyId === BROWNINGS_HONEY_COMPANY_ID) {
        return [
            {
                name: PREFERENCE_NAMES.BEE_COUNT_SCAN_POLICY,
                description: 'Enable ongoing automated assessment of hive population.',
                value:
                    countPreferences?.auto_scan_enabled === totalBeehomes || countPreferences?.auto_scan_enabled === 0
                        ? preferences.auto_scan_policy?.auto_scan_enabled
                        : null,
                willComingSoon: false,
                onChange: value => toggleBhomesPreference(PREFERENCE_TYPES.BEE_COUNT_SCAN_POLICY, value),
            },
        ];
    }

    return [
        {
            name: PREFERENCE_NAMES.SMART_INSPECTION,
            description: generateDescription(
                'Allow ongoing automated hive inspection (frames scan).',
                countPreferences?.auto_scan_enabled &&
                    preferences?.visual_inspection_type === constants.VISUAL_INSPECTION_TYPES.FULL
                    ? countPreferences?.auto_scan_enabled
                    : 0,
                totalBeehomes
            ),
            value:
                countPreferences?.auto_scan_enabled === totalBeehomes || countPreferences?.auto_scan_enabled === 0
                    ? preferences?.visual_inspection_type === constants.VISUAL_INSPECTION_TYPES.FULL &&
                      preferences.auto_scan_policy?.auto_scan_enabled
                    : null,
            willComingSoon: false,
            onChange: value => toggleBhomesPreference(PREFERENCE_TYPES.SMART_INSPECTION, value),
        },
        {
            name: PREFERENCE_NAMES.FEEDING_POLICY,
            description: generateDescription(
                'Allow automatic feeding.',
                countPreferences?.auto_feed_enabled || 0,
                totalBeehomes
            ),
            value:
                countPreferences?.auto_feed_enabled === totalBeehomes || countPreferences?.auto_feed_enabled === 0
                    ? preferences.auto_feed_policy?.auto_feed_enabled
                    : null,
            willComingSoon: false,
            onChange: value => toggleBhomesPreference(PREFERENCE_TYPES.FEEDING_POLICY, value),
        },
        {
            name: PREFERENCE_NAMES.BEE_COUNT_SCAN_POLICY,
            description: 'Enable ongoing automated assessment of hive population.',
            value:
                countPreferences?.auto_scan_enabled === totalBeehomes || countPreferences?.auto_scan_enabled === 0
                    ? preferences.auto_scan_policy?.auto_scan_enabled
                    : null,
            willComingSoon: false,
            onChange: value => toggleBhomesPreference(PREFERENCE_TYPES.BEE_COUNT_SCAN_POLICY, value),
        },
    ];
};
