import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import Modal from '@beewise/modal';
import { InputFormField } from '@beewise/hook-form';

const RegionListModal = ({ isModalOpened, toggleModal, control, onSave, values, setValue }) => {
    const [data, setData] = useState({});

    useEffect(() => {
        setData(values.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnClose = () => {
        setValue('data', data);
        toggleModal();
    };

    return (
        <Modal
            className="region-list-modal"
            isOpen={isModalOpened}
            header="Set hive strength"
            onClose={handleOnClose}
            footerComponent={
                <div className="region-list-modal-buttons">
                    <Button className="secondary-blue" onClick={handleOnClose}>
                        Cancel
                    </Button>
                    <Button className="primary-blue" onClick={onSave}>
                        Done
                    </Button>
                </div>
            }
        >
            <div className="strength-block">
                <h3>Strong</h3>
                <div className="strength-block-line">
                    <div className="strength-block-item">
                        <div>Brood frames:</div>
                        <InputFormField name="data.strong.brood" control={control} />
                    </div>
                    <div className="strength-block-item">
                        <div>Bee frames:</div>
                        <InputFormField name="data.strong.bee" control={control} />
                    </div>
                </div>
            </div>
            <div className="strength-block">
                <h3>Medium</h3>
                <div className="strength-block-line">
                    <div className="strength-block-item">
                        <div>Brood frames:</div>
                        <InputFormField name="data.medium.brood" control={control} />
                    </div>
                    <div className="strength-block-item">
                        <div>Bee frames:</div>
                        <InputFormField name="data.medium.bee" control={control} />
                    </div>
                </div>
            </div>
            <div className="strength-block">
                <h3>Weak</h3>
                <div className="strength-block-line">
                    <div className="strength-block-item">
                        <div>Brood frames:</div>
                        <InputFormField name="data.weak.brood" control={control} />
                    </div>
                    <div className="strength-block-item">
                        <div>Bee frames:</div>
                        <InputFormField name="data.weak.bee" control={control} />
                    </div>
                </div>
            </div>
            <div className="strength-block">
                <h3>Dead</h3>
                <div className="strength-block-text">
                    All hives which are below weak will be automatically considered as dead (regardless of queen
                    presence)
                </div>
            </div>
        </Modal>
    );
};

RegionListModal.propTypes = {
    isModalOpened: PropTypes.bool,
    toggleModal: PropTypes.func,
    onSave: PropTypes.func,
    setValue: PropTypes.func,
    control: PropTypes.shape(),
    values: PropTypes.shape(),
};

export default RegionListModal;
