import { createAsyncAction } from '@beewise/utils';

export const INVITE_USERS = createAsyncAction('INVITE_USERS');
export const FETCH_DELETE_USER = createAsyncAction('FETCH_DELETE_USER');
export const GET_COMPANY = createAsyncAction('GET_COMPANY');
export const FETCH_USER_SETTINGS = createAsyncAction('FETCH_USER_SETTINGS');
export const FETCH_UPDATE_USER_ALERTS_SETTINGS = createAsyncAction('FETCH_UPDATE_USER_ALERTS_SETTINGS');
export const CHANGE_USER_ROLE = createAsyncAction('CHANGE_USER_ROLE');
export const OPEN_INVITATION_MODEL = 'OPEN_INVITATION_MODEL';
export const CLOSE_INVITATION_MODEL = 'CLOSE_INVITATION_MODEL';
export const FETCH_PREFERENCES = createAsyncAction('FETCH_PREFERENCES');
export const FETCH_UPDATE_PREFERENCES = createAsyncAction('FETCH_UPDATE_PREFERENCES');
export const CREATE_NEW_REGION = 'CREATE_NEW_REGION';
export const DELETE_NEW_REGION = 'DELETE_NEW_REGION';
export const FETCH_REGIONS = createAsyncAction('FETCH_REGIONS');
export const FETCH_CREATE_REGION = createAsyncAction('FETCH_CREATE_REGION');
export const FETCH_UPDATE_REGION = createAsyncAction('FETCH_UPDATE_REGION');
export const FETCH_DELETE_REGION = createAsyncAction('FETCH_DELETE_REGION');
export const CHANGE_COMING_SOON_PREFERENCES = 'CHANGE_COMING_SOON_PREFERENCES';
