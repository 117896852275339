import ReactGA from 'react-ga';
import { get } from 'lodash-es';
import { auth } from '@beewise/react-utils';
import constants from 'appConstants';
import { isProduction } from 'utils';

export const analyticsConstants = {
    EVENT_CATEGORIES: {
        MOVE_FRAMES: 'Move Frames',
        SCAN: 'Scan',
        ENTRANCES: 'Entrances',
        SWARM: 'Swarm',
        DISCOVER: 'Discover',
        CUSTOMIZE_VIEW: 'Customize View',
        LIVE_STREAM: 'Live stream',
        NAVIGATE: 'Navigate',
        SYSTEM_PREFERENCES: 'System preferences',
        GENERAL: 'general',
    },
    EVENT_NAMES: {
        scanBeehome: 'scanBeehome',
        approveScan: 'approveScan',
        openEntrance: 'openEntrance',
        closeEntrance: 'closeEntrance',
        preventSwarming: 'preventSwarming',
        cancelScan: 'cancelScan',
        moveFrames: 'moveFrames',
        approveMoveFrames: 'approveMoveFrames',
        cancelMoveFrames: 'cancelMoveFrames',
        cancelMoveFramesWithBees: 'cancelMoveFramesWithBees',
        backToEditing: 'backToEditing',
        redo: 'redo',
        undo: 'undo',
        showOriginal: 'showOriginal',
        moveFramesWithBees: 'moveFramesWithBees',
        approveMoveFramesWithBees: 'approveMoveFramesWithBees',
        legend: 'legend',
        statusIndicators: 'statusIndicators',
        filterIcon: 'filterIcon',
        beemap: 'beemap',
        liveStream: 'liveStream',
        activity: 'activity',
        hamburgerMenu: 'hamburgerMenu',
        backToMapView: 'backToMapView',
        hive: 'hive',
        reports: 'reports',
        settings: 'settings',
        alerts: 'alerts',
        userSettings: 'userSettings',
        setSystemPreferencesToYes: 'setSystemPreferencesToYes',
        setSystemPreferencesToNo: 'setSystemPreferencesToNo',
    },
};
export const getLabelString = (isBeehomeTrendsDataShown, isActivityShown, isBeemapShown) =>
    `beehomeData - ${isBeehomeTrendsDataShown ? 'opened' : 'collapsed'}, activity - ${
        isActivityShown ? 'opened' : 'collapsed'
    }, beeMap - ${isBeemapShown ? 'on' : 'off'}`;

/**
 * @param {Object} event
 * @property {string} event.category
 * @property {string} event.action
 * @property {string} [event.label]
 */
const sendGAEvent = event => {
    if (
        isProduction() &&
        get(auth.getUser(), 'role', '') !== constants.ROLES.SUPER_ADMIN &&
        get(auth.getUser(), 'role', '') !== constants.ROLES.SUPER_VIEWER
    ) {
        ReactGA.event(event);
    }
};

export default {
    fireGeneralEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.GENERAL,
            ...event,
        }),
    fireMoveFramesEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.MOVE_FRAMES,
            ...event,
        }),
    fireScanEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.SCAN,
            ...event,
        }),
    fireEntrancesEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.ENTRANCES,
            ...event,
        }),
    fireSwarmEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.SWARM,
            ...event,
        }),
    fireDiscoverEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.DISCOVER,
            ...event,
        }),
    fireCustomizeViewEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.CUSTOMIZE_VIEW,
            ...event,
        }),
    fireLiveStreamEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.LIVE_STREAM,
            ...event,
        }),
    fireNavigateEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.NAVIGATE,
            ...event,
        }),
    fireChangeSystemPreferencesEvent: event =>
        sendGAEvent({
            category: analyticsConstants.EVENT_CATEGORIES.SYSTEM_PREFERENCES,
            event,
        }),
};
