import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import Modal from '@beewise/modal';
import Button from '@beewise/button';
import { useToggle } from '@beewise/react-utils';
import { createBhomeNote, updateBhomeNote } from 'components/views/BeeHome/actions';

import './AddBeekeeperModal.scss';

const AddBeekeeperEntryModal = ({ isOpen, onClose, inspectedFrame, noteToEdit, currentBhomeId }) => {
    const dispatch = useDispatch();

    const textAreaRef = useRef();

    const [note, setNote] = useState();
    const [isWarningShown, , showWarning, hideWarning] = useToggle(false);

    useEffect(() => {
        if (noteToEdit.id) {
            setNote(noteToEdit.note);
        } else {
            setNote('');
        }
    }, [noteToEdit.id, noteToEdit.note]);

    useEffect(() => {
        if (textAreaRef?.current) {
            textAreaRef?.current.focus();
        }
    }, [textAreaRef]);

    const handleNoteChanged = useCallback(
        e => {
            if (showWarning) {
                hideWarning();
            }
            setNote(e.target.value);
        },
        [hideWarning, showWarning]
    );

    const handleSubmit = useCallback(() => {
        if (!note) {
            showWarning();
            textAreaRef?.current?.focus();
            return;
        }
        if (noteToEdit.id) {
            dispatch(updateBhomeNote(noteToEdit.id, note, constants.ALERT_STATUSES.UNREAD));
        } else {
            dispatch(createBhomeNote(currentBhomeId, null, inspectedFrame.frameId, note));
        }

        onClose();
    }, [currentBhomeId, dispatch, inspectedFrame.frameId, note, noteToEdit.id, onClose, showWarning]);

    return (
        <Modal header="Add log entry" isOpen={isOpen} onClose={onClose} className="add-log-entry-modal">
            <div className="add-log-entry-modal-body">
                <div className="add-log-entry-modal-label">Note</div>
                <textarea
                    className="add-log-entry-modal-text"
                    value={note}
                    onChange={handleNoteChanged}
                    ref={textAreaRef}
                />
                {isWarningShown && <div className="add-log-entry-empty-message-warning">Please write a message</div>}
                <div className="add-log-entry-modal-actions">
                    <Button className="cancel" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button className="add" role="presentation" onClick={handleSubmit}>
                        {noteToEdit.id ? 'Edit' : 'Add'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

AddBeekeeperEntryModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    inspectedFrame: PropTypes.shape(),
    noteToEdit: PropTypes.shape(),
    currentBhomeId: PropTypes.number,
};

export default AddBeekeeperEntryModal;
