import { useEffect } from 'react';
import { useToggle } from '@beewise/react-utils';
import EmptyIcon from '../components/Map/empty-location.svg';
import { modalController } from '../../ModalManager';
import { MODALS } from '../../ModalManager/config';

const defaultCursor = 'url(https://maps.gstatic.com/mapfiles/openhand_8_8.cur), default';
const addNewLocationCursor = `url(${EmptyIcon}) 10 20, default`;

const openCreateDropModal = e => {
    modalController.set({
        name: MODALS.YARD_MODAL,
        props: {
            createCoords: e.latLng.toJSON(),
        },
    });
};

const useCreateDrop = ({ mapRef }) => {
    const [isCreateDropMode, toggleIsCreateDropMode] = useToggle();

    const handleSetCreateDropMode = () => () => {
        mapRef.current.setOptions({
            draggableCursor: !isCreateDropMode ? addNewLocationCursor : defaultCursor,
        });
        toggleIsCreateDropMode();
    };

    useEffect(() => {
        mapRef.current?.addListener('click', e => isCreateDropMode && openCreateDropModal(e));
        return () => {
            // eslint-disable-next-line
            window?.google?.maps?.event?.clearListeners?.(mapRef.current, 'click');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateDropMode]);

    return {
        handleSetCreateDropMode,
        isCreateDropMode,
    };
};

export default useCreateDrop;
