import React from 'react';
import './BeeCount.scss';
import PropTypes from 'prop-types';

const WHITE = '#EEEEEE';
const YELLOW = '#EDDE8D';
const GRAY = '#9E9E9E';
const GRADIENT_PERCENT = 5;

const getGradient = beesPercent => {
    const gradientAmount = (GRADIENT_PERCENT / beesPercent) * 100;
    return `linear-gradient(${WHITE} 0%, ${YELLOW}
    ${gradientAmount}%, ${YELLOW}
    ${100 - gradientAmount}%, ${WHITE} 100%)`;
};
const emptyBeesGradient = `radial-gradient(${GRAY} 0%, ${WHITE} 77%)`;

const getFrameStyle = (beesPercent, hasBeeCount) =>
    !hasBeeCount
        ? { height: '80%', backgroundImage: emptyBeesGradient }
        : { height: `${beesPercent}%`, backgroundImage: getGradient(beesPercent) };

const BeeCount = ({ frame, showFramesHistory }) => {
    const hasBeeCount = showFramesHistory
        ? frame?.previousSideA?.beeCount || frame?.previousSideB?.beeCount
        : frame?.sideA?.beeCount || frame?.sideB?.beeCount;

    const beesPercent =
        (((showFramesHistory ? frame?.previousSideA?.beeCount?.beesPercent : frame?.sideA?.beeCount?.beesPercent) ||
            0) +
            ((showFramesHistory ? frame?.previousSideB?.beeCount?.beesPercent : frame?.sideB?.beeCount?.beesPercent) ||
                0)) /
        2;

    const { height, backgroundImage } = getFrameStyle(beesPercent, hasBeeCount);

    return <div className="bee-count" style={{ '--height': height, '--backgroundImage': backgroundImage }} />;
};

BeeCount.propTypes = {
    frame: PropTypes.shape().isRequired,
    showFramesHistory: PropTypes.bool.isRequired,
};

export default BeeCount;
