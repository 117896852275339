import { useDrop } from 'react-dnd';
import { FRAME_MOVE_TYPE } from 'config';

const useDroppable = ({ index, moveFrame }) => {
    const [, dropRef] = useDrop({
        accept: FRAME_MOVE_TYPE,
        drop: () => ({
            index,
        }),
        hover: (item, monitor) => {
            if (!dropRef.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (hoverIndex === 0 && dragIndex !== hoverIndex) {
                moveFrame(dragIndex, hoverIndex);
                item.index = hoverIndex;
                return;
            }

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveFrame(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    return dropRef;
};

export default useDroppable;
