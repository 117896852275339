import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForklift } from '@fortawesome/pro-regular-svg-icons';

const LoadingZoneIcon = () => (
    <span className="icon-loading-zone-container">
        <FontAwesomeIcon icon={faForklift} className="icon-loading-zone" />
    </span>
);

export default LoadingZoneIcon;
