import React from 'react';
import BeewiseIcon from '@beewise/icon';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ icon, className }) =>
    typeof icon === 'string' ? (
        <BeewiseIcon type={icon} className={className} />
    ) : (
        <FontAwesomeIcon icon={icon} className={className} />
    );

Icon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
    className: PropTypes.string,
};
export default Icon;
