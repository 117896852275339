import { FETCH_PUBLIC_BHOME_DATA, FETCH_PUBLIC_FRAME_DATA } from '../actionTypes';

export const fetchPublicBhomeData = code => ({
    type: 'FETCH',
    params: {
        type: FETCH_PUBLIC_BHOME_DATA.default,
        method: 'GET',
        url: `/public/bhomes/${code}`,
    },
});

export const fetchPublicFrameData = (code, frameId) => ({
    type: 'FETCH',
    params: {
        type: FETCH_PUBLIC_FRAME_DATA.default,
        method: 'GET',
        url: `/public/bhomes/${code}/frame/${frameId}`,
    },
});
