import React from 'react';
import PropTypes from 'prop-types';
import './Legend.scss';

const contentLegendData = [
    { label: 'Capped honey', class: 'capped-honey' },
    { label: 'Open honey', class: 'opened-honey' },
    { label: 'Pollen', class: 'pollen' },
    { label: 'Capped brood', class: 'brood-capped' },
    { label: 'Open brood', class: 'brood-open' },
    { label: 'Eggs', class: 'brood-egg' },
    { label: 'Bees', class: 'bees' },
    { label: 'Thermal', class: 'thermal' },
    { label: 'Previous thermal', class: 'previous-thermal' },
];

const getContentLegendDataItems = ({ isPublicView, isModalView }) => {
    if (isModalView) {
        return contentLegendData.filter(
            item => !item.label.toLowerCase().includes('thermal') && !item.label.toLowerCase().includes('bees')
        );
    } else if (isPublicView) {
        return contentLegendData.filter(item => !item.label.toLowerCase().includes('thermal'));
    }
    return contentLegendData;
};

const LegendItem = ({ item }) => (
    <div className="legend-content-item">
        <div className={`legend-octagon ${item.class}`} />
        <div className="legend-content-item-label">{item.label}</div>
    </div>
);

const ContentLegend = ({ isPublicView, isModalView }) => (
    <div className="legend">
        {getContentLegendDataItems({ isPublicView, isModalView }).map(item => (
            <LegendItem item={item} key={`cl-${item.class}`} />
        ))}
    </div>
);

ContentLegend.propTypes = {
    isPublicView: PropTypes.bool,
    isModalView: PropTypes.bool,
};

LegendItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string,
        class: PropTypes.string,
    }),
};

export default ContentLegend;
