import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash-es';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import Tooltip from '@beewise/tooltip';
import Checkbox from '@beewise/checkbox-field';
import constants from 'appConstants';
import getFrameTooltip from 'utils/getFrameTooltip';
import { getCapacity, getIsFoundationFrame } from 'components/reusables/Hive/utils';
import Comb from 'components/reusables/Hive/components/Frame/components/Comb';
import FullFrame from 'components/reusables/Hive/components/Frame/components/FullFrame';
import {
    getIsBalanceHiveModeShown,
    getIsInspectedFrame,
    getSelectedFramesForBalance,
} from 'components/views/BeeHome/selectors';
import { getFrameLeftPosition } from '../../utils';

import './StationFrame.scss';

export const getFrameComponent = ({ frame, isFoundationFrame, capacity, showFramesHistory, isEmptyFrame }) => {
    const isCombFrame = frame?.type === constants.FRAME_TYPES.COMB_FRAME && !isFoundationFrame;

    if (isEmptyFrame) {
        return <FullFrame type={frame.type} isFoundationFrame={isFoundationFrame} isEmptySlot={!frame.frameId} />;
    }

    if (isCombFrame) {
        return <Comb frame={frame} capacity={capacity} showFramesHistory={showFramesHistory} />;
    }

    return <FullFrame type={frame.type} isFoundationFrame={isFoundationFrame} isEmptySlot={!frame.frameId} />;
};

const StationFrame = ({
    frame,
    showFramesHistory,
    onFrameClick,
    hiveStart,
    hiveEnd,
    markedHives,
    onFrameCheckboxSelect,
    isEmptyFrame,
}) => {
    const selectedFramesForBalance = useSelector(getSelectedFramesForBalance, shallowEqual);

    const isBalanceHiveModeShown = useSelector(getIsBalanceHiveModeShown);
    const isInspectedFrame = useSelector(state => getIsInspectedFrame(state, frame.id));

    const [isAddedToBalanceHive, setIsAddedToBalanceHive] = useState(false);
    const tooltipText = getFrameTooltip({
        frame: { ...frame, frameIdx: frame.slotId },
        showFramesHistory,
    });
    const capacity = getCapacity(frame, showFramesHistory);

    const relatedMarkedHive =
        frame?.frameId &&
        markedHives?.find(
            hive =>
                frame?.place?.station === hive?.station &&
                frame?.place?.position?.x >= hive?.from &&
                frame?.place?.position?.x < hive?.to
        );

    const isFoundationFrame = getIsFoundationFrame(frame, showFramesHistory);

    const isInsideMarkedHive = !!relatedMarkedHive;

    const isCheckboxShown =
        isBalanceHiveModeShown && frame.type === constants.FRAME_TYPES.COMB_FRAME && isInsideMarkedHive;

    const isCheckboxDisabled =
        (Object.keys(selectedFramesForBalance).length > 1 &&
            selectedFramesForBalance[relatedMarkedHive?.hiveId]?.id !== frame.id) ||
        (selectedFramesForBalance[relatedMarkedHive?.hiveId] &&
            selectedFramesForBalance[relatedMarkedHive?.hiveId]?.id !== frame.id);

    const style = {
        left: `${getFrameLeftPosition({ positionX: frame.place.position.x, hiveStart, hiveEnd })}%`,
    };

    const handleFrameClick = [constants.FRAME_TYPES.PARTITION, constants.FRAME_TYPES.FEEDER].includes(frame.type)
        ? noop
        : onFrameClick;

    if (frame.type === constants.FRAME_TYPES.STATIC_PARTITION) {
        return null;
    }

    return (
        <div
            className={cx('station-frame-wrapper', {
                'station-frame-wrapper-outside': !isInsideMarkedHive,
                'station-frame-wrapper-inspected': isInspectedFrame,
            })}
            style={style}
        >
            {isCheckboxShown && (
                <Tooltip
                    content="You can only choose two frames to switch. Unselect one of the frames"
                    position="top center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    disabled={!isCheckboxDisabled}
                >
                    <Checkbox
                        className="balance-checkbox"
                        onChange={() => {
                            const updatedIsAddedToBalanceHive = !isAddedToBalanceHive;
                            onFrameCheckboxSelect({
                                frame,
                                hiveId: relatedMarkedHive?.hiveId,
                                isSelected: updatedIsAddedToBalanceHive,
                            });
                            setIsAddedToBalanceHive(updatedIsAddedToBalanceHive);
                        }}
                        disabled={isCheckboxDisabled}
                    />
                </Tooltip>
            )}
            {/* <StationFrameStatus frame={frame} isInsideMarkedHive={isInsideMarkedHive} /> */}
            <Tooltip
                content={tooltipText}
                position="top center"
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                hideOnScroll
                disabled={!tooltipText}
            >
                <div
                    className={cx('station-frame', {
                        'station-frame-empty': !frame?.type,
                    })}
                    role="presentation"
                    onClick={handleFrameClick}
                >
                    {getFrameComponent({
                        frame,
                        isFoundationFrame,
                        capacity,
                        showFramesHistory,
                        isEmptyFrame,
                    })}
                </div>
            </Tooltip>
        </div>
    );
};

StationFrame.propTypes = {
    frame: PropTypes.shape().isRequired,
    showFramesHistory: PropTypes.bool,
    onFrameClick: PropTypes.func,
    hiveStart: PropTypes.number.isRequired,
    hiveEnd: PropTypes.number.isRequired,
    markedHives: PropTypes.arrayOf(PropTypes.shape()),
    onFrameCheckboxSelect: PropTypes.func,
    isEmptyFrame: PropTypes.bool,
};

export default memo(StationFrame);
