import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { UI_ENV } from './config';

const isLocalDevelopment = window.location.href.includes('localhost');
const sentryDns = 'https://7a6c271217fc6f4e778e86a7e48333c8@o4504734858936320.ingest.us.sentry.io/4507884128174080';
const matchUrl = /^https:\/\/(?:dev-|stage-)?api\.beewise\.ag\//;

const getReplayIntegrations = () =>
    UI_ENV !== 'qa'
        ? [
              Sentry.replayIntegration({
                  blockAllMedia: false,
                  maskAllText: false,
                  networkCaptureBodies: true,
                  networkDetailAllowUrls: [window.location.origin, matchUrl],
                  networkDetailDenyUrls: [/sign-in/i],
                  networkRequestHeaders: ['X-Custom-Header'],
                  networkResponseHeaders: ['X-Custom-Header'],
              }),
              Sentry.replayCanvasIntegration(),
          ]
        : [];

const initSentry = () =>
    Sentry.init({
        environment: UI_ENV,
        dsn: sentryDns,
        integrations: [
            Sentry.browserTracingIntegration(),
            captureConsoleIntegration({
                levels: ['error'],
            }),
            ...getReplayIntegrations(),
        ],
        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        normalizeDepth: 5,
        tracesSampleRate: 1,
        profilesSampleRate: 0,
        enabled: !isLocalDevelopment && UI_ENV !== 'stage',
    });

export default initSentry;
