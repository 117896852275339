import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

const CheckboxItem = ({ isSelected, handleCheckboxClick, label }) => (
    <div className="checkbox-wrapper">
        <span
            className={cn('checkbox-icon', {
                selected: isSelected,
            })}
            role="presentation"
            onClick={handleCheckboxClick}
        >
            {!isSelected && <FontAwesomeIcon icon={faCircle} className="checkbox-icon_unselected" />}
            <FontAwesomeIcon
                icon={faCircleCheck}
                className={cn('checkbox-icon_selected', {
                    selected: isSelected,
                })}
            />
        </span>
        <p className="checkbox-label">{label}</p>
    </div>
);

CheckboxItem.propTypes = {
    isSelected: PropTypes.bool,
    handleCheckboxClick: PropTypes.func,
    label: PropTypes.string,
};

export default CheckboxItem;
