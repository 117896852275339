import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component: Component, redirectToHome = true, ...rest }) => {
    const isLoggedIn = useSelector(state => state.app.isLoggedIn);

    return !isLoggedIn || !redirectToHome ? <Component {...rest} /> : <Navigate to="/" />;
};

PublicRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    redirectToHome: PropTypes.bool,
};

export default PublicRoute;
