import {
    CANCEL_ACTIVITIES,
    FETCH_ACTIVITIES,
    ADD_UNSAVED_ACTIVITIES,
    REMOVE_UNSAVED_ACTIVITIES,
    CLEAR_UNSAVED_ACTIVITIES,
    SET_ACTION_MODE,
    SET_ENTRANCES_TO_OPERATE,
    TOGGLE_HALLWAY_STREAM,
    SET_HALLWAY_VIDEO_URL,
    FETCH_MODE_IN_PROGRESS,
} from '../actionTypes';

export const fetchActivities = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_ACTIVITIES.default,
        url: `/messages/${bhomeId}`,
    },
});

export const cancelActivities = (bhomeId, messageIds) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: CANCEL_ACTIVITIES.default,
        url: `/messages/cancel/${bhomeId}`,
        body: {
            messageIds,
        },
        payloadType: null,
    },
});

export const fetchModeInProgress = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_MODE_IN_PROGRESS.default,
        url: `/messages/bhomes/${bhomeId}/modes/in-progress`,
    },
});

export const addUnsavedActivities = activities => ({
    type: ADD_UNSAVED_ACTIVITIES,
    activities,
});

export const removeUnsavedActivities = activities => ({
    type: REMOVE_UNSAVED_ACTIVITIES,
    activities,
});

export const clearUnsavedActivities = () => ({
    type: CLEAR_UNSAVED_ACTIVITIES,
});

export const setActionMode = mode => ({
    type: SET_ACTION_MODE,
    mode,
});

export const setEntrancesToOperate = entrances => ({
    type: SET_ENTRANCES_TO_OPERATE,
    entrances,
});

export const toggleHallwayStream = (id, status, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: TOGGLE_HALLWAY_STREAM.default,
        url: `/stream/hallway/${id}`,
        body: {
            status,
        },
        resolver,
    },
});

export const setHallwayVideoUrl = payload => ({
    type: SET_HALLWAY_VIDEO_URL,
    payload,
});
