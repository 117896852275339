import React, { forwardRef } from 'react';
import cx from 'classnames';
import useMapOverlap from 'utils/useMapOverlap';
import PropTypes from 'prop-types';
import './MarkerTooltip.scss';
import TooltipContent from './TooltipContent';

const MARKER_WIDTH = 30;
const MAP_CARD_MARGIN = 16;

const stopPropagation = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
};

const MARGIN = 8;

const getStyleValues = (component, showLeft) => {
    const height = component.clientHeight / 2;
    const width = component.clientWidth;
    const horizontal = showLeft ? `calc(-100% - ${MARGIN}px)` : `calc(${MARGIN}px + ${width}px)`;
    return { transform: `translate(${horizontal}, calc(-50% - ${height}px)` };
};

const MarkerTooltip = forwardRef(({ id, map, name, bhomeIds, status, handleHideTooltip }, ref) => {
    const [mapCardRef, showCardLeft] = useMapOverlap(map, MARKER_WIDTH + 2 * MAP_CARD_MARGIN);

    return (
        <div
            role="presentation"
            ref={mapCardRef}
            className={cx('tooltip-card', status, {
                'show-left': showCardLeft,
            })}
            onClick={stopPropagation}
            onDoubleClick={stopPropagation}
            onWheel={stopPropagation}
            onMouseDown={stopPropagation}
            style={getStyleValues(ref?.current, showCardLeft)}
        >
            <TooltipContent id={id} name={name} bhomeIds={bhomeIds} handleHideTooltip={handleHideTooltip} />
        </div>
    );
});

MarkerTooltip.propTypes = {
    id: PropTypes.string,
    map: PropTypes.shape(),
    name: PropTypes.string,
    bhomeIds: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.string,
    handleHideTooltip: PropTypes.func,
};

export default MarkerTooltip;
