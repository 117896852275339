import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { faCalendarCheck, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@beewise/icon-button';
import Toggle from '@beewise/toggle';
import Hives from 'components/reusables/HivesV2';
import Actions from 'components/reusables/HivesV2/Actions';
import LastActionStatus from 'components/reusables/LastActionStatus';
import { setFrameToInspect, setHiveToInspect } from 'components/views/BeeHome/actions';
import { getBhomeDefinedHives, getPendingMessagesLength } from 'components/views/BeeHome/selectors';
import { isBhomeFramesFetched } from 'components/reusables/Alerts/selectors';
import { toggleBlogPanel, toggleRightPanel } from 'actions';
import { getIsBlogPanelShown, getIsRightPanelShown } from 'selectors';
import { getCurrentBhome, getLastFeedDate } from '../../selectors';
import Footer from './components/Footer';
import emptyState from './bh-empty-state-with-text.png';

import './BhomeView.scss';

const getName = (place, area) => (place && area ? `${place}, ${area}` : area || place);

const BhomeView = ({ isPublicView = false }) => {
    const dispatch = useDispatch();
    const historySliderRef = useRef();
    const [isHistoryView, setIsHistoryView] = useState(false);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);
    const pendingMessagesLength = useSelector(getPendingMessagesLength);
    const lastFeed = useSelector(getLastFeedDate);
    const isFetched = useSelector(isBhomeFramesFetched);
    const isRightPanelShown = useSelector(getIsRightPanelShown);
    const isBlogPanelOpen = useSelector(getIsBlogPanelShown);
    const areHivesEmpty = Object.values(hives).every(hive => !hive.length);

    const handleSetActionPanelStatus = useCallback(
        status => () => {
            dispatch(setFrameToInspect({}));
            dispatch(setHiveToInspect(null));
            dispatch(toggleRightPanel(status ?? !isRightPanelShown));
        },
        [dispatch, isRightPanelShown]
    );

    const handleOpenBlogPanel = useCallback(() => {
        dispatch(setFrameToInspect({}));
        dispatch(setHiveToInspect(null));
        dispatch(toggleRightPanel(false));
        dispatch(toggleBlogPanel(!isBlogPanelOpen));
    }, [dispatch, isBlogPanelOpen]);

    return (
        <section className={cn('bhome', { 'bhome-public': isPublicView })}>
            {isFetched && (
                <>
                    <div className="bhome-header-wrapper">
                        <div className="bhome-header">
                            <h3 className="large-title">BH {currentBhome.id}</h3>
                            <span className="subtitle">
                                {getName(currentBhome?.ranch_name || currentBhome?.yard_name, currentBhome.area)}
                            </span>
                            {!isPublicView && (
                                <IconButton
                                    className={cn('notification-icon', {
                                        'is-notified': !!pendingMessagesLength,
                                        'is-open': isRightPanelShown,
                                    })}
                                    icon={faInfoCircle}
                                    onClick={handleSetActionPanelStatus()}
                                    type="secondary"
                                />
                            )}
                            {isPublicView && (
                                <Toggle
                                    value={isBlogPanelOpen}
                                    name="isBlogPanelOpen"
                                    onChange={handleOpenBlogPanel}
                                    label="Blog"
                                />
                            )}
                        </div>
                        {!isPublicView && (
                            <div className="bhome-header subheader">
                                <LastActionStatus date={lastFeed} icon="feed" name="Last feed" />
                                <LastActionStatus
                                    date={currentBhome.lastVisitTime}
                                    icon={faCalendarCheck}
                                    name="Last visit"
                                />
                            </div>
                        )}
                    </div>
                    {areHivesEmpty && !isHistoryView ? (
                        <img className="empty-state" src={emptyState} alt="Empty State" />
                    ) : (
                        <>
                            {!isPublicView && (
                                <Actions
                                    currentBhome={currentBhome}
                                    isHistoryView={isHistoryView}
                                    historySliderRef={historySliderRef}
                                />
                            )}
                            <Hives
                                setIsHistoryView={setIsHistoryView}
                                historySliderRef={historySliderRef}
                                handleOpenActionPanel={handleSetActionPanelStatus(true)}
                                isPublicView={isPublicView}
                            />
                        </>
                    )}
                    {!isPublicView && <Footer />}
                </>
            )}
        </section>
    );
};

BhomeView.propTypes = {
    isPublicView: PropTypes.bool,
};

export default BhomeView;
