import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import Modal from '@beewise/modal';

import './UnsavedActivities.scss';

export const ActivityConfirmationModal = ({
    isOpen = true,
    onSubmit,
    onCancel,
    header,
    content,
    submitButtonText = 'Submit',
    withSubmitButton = true,
    withCancelButton = true,
}) => (
    <Modal header={header} className="activity-confirmation-modal" isOpen={isOpen} onClose={onCancel}>
        <div className="activity-confirmation-modal-content">{content}</div>
        <div className="activity-confirmation-modal-actions">
            {withCancelButton && (
                <Button className="secondary-blue" onClick={onCancel}>
                    Cancel
                </Button>
            )}
            <Button className={withSubmitButton ? 'primary-blue' : 'secondary-blue'} onClick={onSubmit}>
                {submitButtonText}
            </Button>
        </div>
    </Modal>
);

ActivityConfirmationModal.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    content: PropTypes.string,
    submitButtonText: PropTypes.string,
    withSubmitButton: PropTypes.bool,
    withCancelButton: PropTypes.bool,
};

export const FeedAllHivesConfirmationModal = ({ isOpen, onSubmit, onCancel }) => (
    <ActivityConfirmationModal
        isOpen={isOpen}
        header="Feed all the hives in the Beehome"
        onCancel={onCancel}
        onSubmit={onSubmit}
        content="The Beehome will feed all the hives that have a feeder in their populated area."
        submitButtonText="Feed"
    />
);

FeedAllHivesConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export const DiscardConfirmationModal = ({ isOpen, onCancel, onDiscard }) => (
    <ActivityConfirmationModal
        isOpen={isOpen}
        header="Discard unsaved activities"
        onCancel={onCancel}
        onSubmit={onDiscard}
        content="All unsaved activities will be discarded and removed."
        submitButtonText="Discard"
        withSubmitButton={false}
    />
);

DiscardConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
};

export const UnlockAllModal = ({ isOpen, onCancel, onSubmit }) => (
    <ActivityConfirmationModal
        isOpen={isOpen}
        header="Unlock all hives"
        onCancel={onCancel}
        onSubmit={onSubmit}
        content="The Beehome will unlock all the hives. Automatic activities will resume, and manual action will become enabled."
        submitButtonText="Unock All"
    />
);

UnlockAllModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export const HarvestingFramesAlertModal = ({ isOpen, handleUndo, handleApproveHarvestMove }) => (
    <Modal
        className="activity-confirmation-modal harvesting-frames-alert-modal"
        header="Frame marked for harvesting"
        isOpen={isOpen}
        suppressClose
    >
        <div className="activity-confirmation-modal-content">
            <div className="activity-modal-text">Move will discard harvesting</div>
            <div className="activity-modal-text">
                The frame you were moving is marked for future harvesting when the honey tank empties. Moving it will
                discard the harvesting activity in the queue.
            </div>
        </div>
        <div className="activity-confirmation-modal-actions">
            <Button className="activity-confirmation-modal-cancel-button" onClick={handleUndo}>
                Don&lsquo;t move
            </Button>
            <Button className="activity-confirmation-modal-submit-button" onClick={handleApproveHarvestMove}>
                Move anyway
            </Button>
        </div>
    </Modal>
);

HarvestingFramesAlertModal.propTypes = {
    isOpen: PropTypes.bool,
    handleUndo: PropTypes.func,
    handleApproveHarvestMove: PropTypes.func,
};
