import React from 'react';
import constants from 'appConstants';
import Badge, { BADGE_TYPE } from 'components/reusables/Badge';
import { capitalize } from 'lodash-es';
import { formatDateColumn } from '../../../utils';

const generalDefaultColumns = [
    {
        field: 'region_yard_id',
        headerName: 'Region',
        width: 150,
        valueGetter: ({ row }) => row.regionYardName || row.regionRanchName || row.coordsState || '-',
    },
    {
        field: 'ranchName',
        headerName: 'Yard/Ranch',
        width: 200,
        valueGetter: ({ row }) => {
            const values = [];
            if (row.yardName) {
                values.push(row.yardName);
            }
            if (row.ranchName) {
                values.push(row.ranchName);
            }
            return values.join('/') || '-';
        },
    },
    {
        field: 'id',
        headerName: 'BH ID',
        width: 110,
        cellClassName: 'grid-id-cell',
    },
];

const getBadgeData = status => {
    if (status === constants.MESSAGE_STATUS.PROCESSING) {
        return { type: BADGE_TYPE.INFO, text: 'Feeding' };
    }

    return { type: BADGE_TYPE.WARN, text: capitalize(status) };
};

export const hiveStatusColumns = [
    ...generalDefaultColumns,
    {
        field: 'totalAmountOfScannedHives',
        headerName: 'Content scan hives',
        width: 180,
        valueGetter: ({ row }) =>
            row.markedHives?.length ? `${row.totalAmountOfScannedHives}/${row.markedHives?.length || 0}` : '-',
    },
    {
        field: 'totalAmountOfBeeScannedHives',
        headerName: 'Bee scan hives',
        width: 150,
        valueGetter: ({ row }) =>
            row.markedHives?.length ? `${row.totalAmountOfBeeScannedHives}/${row.markedHives?.length}` : '-',
    },
    {
        field: 'deadHives',
        headerName: 'Dead hives',
        width: 100,
        valueGetter: ({ row }) => (row.totalThermalAssessmentDeadHives ? row.totalThermalAssessmentDeadHives : '-'),
    },
    {
        field: 'averageBrood',
        headerName: 'Avg. brood frames',
        width: 180,
        valueFormatter: ({ value }) => (value ? value.toFixed(1) : '-'),
    },
    {
        field: 'averagePopulation',
        headerName: 'Avg. bee frames',
        width: 180,
        valueFormatter: ({ value }) => (value ? value.toFixed(1) : '-'),
    },
    {
        field: 'averageHoney',
        headerName: 'Avg. honey frames',
        width: 180,
        valueFormatter: ({ value }) => (value ? value.toFixed(1) : '-'),
    },
    {
        field: 'averageEmpty',
        headerName: 'Avg. empty frames',
        width: 180,
        valueFormatter: ({ value }) => (value ? value.toFixed(1) : '-'),
    },
    {
        field: 'totalAmountOfBroodHives',
        headerName: 'Larvae',
        width: 120,
        valueGetter: ({ row }) => {
            if (!row.markedHives?.length || !row.totalAmountOfBroodHives) {
                return '-';
            }
            return `${row.totalAmountOfBroodHives}/${row.markedHives?.length}`;
        },
    },
    {
        field: 'lastScanTimestamp',
        headerName: 'Last Content Scan',
        width: 190,
        valueFormatter: formatDateColumn,
    },
    {
        field: 'lastBeeCountTimestamp',
        headerName: 'Last Bee Scan',
        width: 200,
        valueFormatter: formatDateColumn,
    },
];

export const feedingColumns = [
    ...generalDefaultColumns,
    {
        field: 'totalAmountOfFedHives',
        headerName: 'Fed hives',
        flex: 0.8,
        valueGetter: ({ row }) =>
            row.markedHives?.length ? `${row.totalAmountOfFedHives ?? 0}/${row.markedHives?.length}` : '-',
    },
    {
        field: 'syrupLevel',
        headerName: 'Syrup level (gal)',
        flex: 0.8,
        valueGetter: ({ row }) =>
            row.syrupLevel ? +((row.syrupLevel / 100) * constants.SYRUP_GALLONS).toFixed(1) : '-',
    },
    {
        field: 'lastFeedTime',
        headerName: 'Last feeding',
        flex: 0.8,
        valueFormatter: formatDateColumn,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.8,
        renderCell: ({ value, row }) => {
            const { type, text } = getBadgeData(value);
            return value ? (
                <Badge
                    type={type}
                    tooltipContent={new Date(row.statusDate).toLocaleString?.('en-US', {
                        month: 'long',
                        day: 'numeric',
                    })}
                >
                    {text}
                </Badge>
            ) : null;
        },
    },
];
