import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { modalController } from 'components/reusables/ModalManager';
import { MODALS } from 'components/reusables/ModalManager/config';
import './SearchBox.scss';

const handleOpenCreateDropModal = data => {
    modalController.set({
        name: MODALS.YARD_MODAL,
        props: {
            createCoords: { lat: data.lat, lng: data.lng },
        },
    });
};

const SearchBox = ({ onPlacesChanged }) => {
    const input = useRef(null);
    const searchBox = useRef(null);

    const [searchValue, setSearchValue] = useState();

    const handleOnPlacesChanged = useCallback(() => {
        if (!onPlacesChanged) return;
        const selectedPlaces = searchBox.current.getPlaces();
        onPlacesChanged(selectedPlaces);
    }, [onPlacesChanged]);

    useEffect(() => {
        if (!searchBox.current && window?.google?.maps) {
            const inp = document.querySelector('input.search-box-input');
            searchBox.current = new window.google.maps.places.SearchBox(inp);
            searchBox.current.addListener('places_changed', handleOnPlacesChanged);
        }

        return () => {
            if (window?.google?.maps) {
                window.google?.maps?.event?.clearInstanceListeners(searchBox);
            }
        };
    }, [handleOnPlacesChanged]);

    const handleSearchChange = e => {
        setSearchValue(e.target.value);
    };

    const handleClearClick = () => {
        setSearchValue(null);
        input.current.value = null;
    };

    const handleAddClick = () => {
        window.google.maps.event.trigger(input.current, 'focus', {});
        window.google.maps.event.trigger(input.current, 'keydown', { keyCode: 13 });

        setTimeout(() => {
            const selectedPlaces = searchBox.current.getPlaces();
            const firstSelectedPlace = selectedPlaces[0];

            if (!firstSelectedPlace) return;

            handleOpenCreateDropModal({
                lat: firstSelectedPlace.geometry.location.lat(),
                lng: firstSelectedPlace.geometry.location.lng(),
            });

            setSearchValue(null);
            input.current.value = null;
        }, 500);
    };

    return (
        <div className="search-box">
            {searchValue ? (
                <>
                    <FontAwesomeIcon icon={faPlusCircle} className="search-box-clear" onClick={handleAddClick} />
                    <FontAwesomeIcon icon={faTimesCircle} className="search-box-clear" onClick={handleClearClick} />
                </>
            ) : (
                <FontAwesomeIcon icon={faSearch} className="search-box-icon" />
            )}
            <input ref={input} placeholder="Search..." className="search-box-input" onChange={handleSearchChange} />
        </div>
    );
};

SearchBox.propTypes = {
    onPlacesChanged: PropTypes.func,
};

export default SearchBox;
