import { getBhomeLabel } from '../../utils';

export const groupBhomeOptions = bhomes => {
    const { bhomesWithLocations, bhomesWithoutLocations } = bhomes.reduce(
        (acc, bhome) => {
            const definedArray = bhome.location ? acc.bhomesWithLocations : acc.bhomesWithoutLocations;
            definedArray.push({ value: bhome.id, label: `BH${getBhomeLabel(bhome)}`, data: bhome });
            return acc;
        },
        { bhomesWithLocations: [], bhomesWithoutLocations: [] }
    );

    return [
        { label: 'Assign a Beehome from', options: bhomesWithLocations },
        { label: 'Assign a new Beehome', options: bhomesWithoutLocations },
    ];
};
