import { lazy } from 'react';

export const MODALS = {
    YARD_MODAL: 'YARD_MODAL',
    MOVE_BHOME_MODAL: 'MOVE_BHOME_MODAL',
};

export const modalsConfig = {
    [MODALS.YARD_MODAL]: lazy(() => import('components/reusables/Map/components/YardModal')),
    [MODALS.MOVE_BHOME_MODAL]: lazy(() => import('components/reusables/Map/components/MoveBhomeModal')),
};
