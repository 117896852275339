import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.scss';

const ProgressBar = ({ completed }) => (
  <div className="progress-container">
    <div className="progress-filler" style={{ width: `${completed}%` }} />
  </div>
);

ProgressBar.propTypes = {
  completed: PropTypes.number
};

export default ProgressBar;
