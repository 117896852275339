import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { ACTION_ITEM_HEIGHT, ACTION_ITEM_HEIGHT_LARGE, HEADER_HEIGHT } from './utils';

const getRowHeight = item => {
    if (item?.header) {
        return HEADER_HEIGHT;
    } else if (item?.data?.hives?.length > 6) {
        return ACTION_ITEM_HEIGHT_LARGE;
    } else {
        return ACTION_ITEM_HEIGHT;
    }
};

const useVirtualizedList = ({ data }) => {
    const listRef = useRef(null);
    const [visibleStartIndex, setVisibleStartIndex] = useState(0);

    const rowHeights = useMemo(() => data.map(getRowHeight), [data]);

    const getItemSize = useCallback(index => rowHeights[index], [rowHeights]);

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
    }, [data.length]);

    return {
        listRef,
        visibleStartIndex,
        setVisibleStartIndex,
        getItemSize,
    };
};

export default useVirtualizedList;
