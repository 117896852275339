import { useState, useRef, useLayoutEffect, useCallback } from 'react';

export default map => {
    const [isOverlap, setIsOverlap] = useState(false);

    const ref = useRef();

    const calculateOverlap = useCallback(() => {
        if (ref.current) {
            const { width, left } = ref.current.getBoundingClientRect();
            if (left + width / 2 >= window.innerWidth - 24) {
                setIsOverlap(true);
            }
        }
    }, []);

    useLayoutEffect(() => {
        if (map && window?.google?.maps) {
            const dragEndListener = map.addListener('dragend', calculateOverlap);
            const zoomChangedListener = map.addListener('zoom_changed', calculateOverlap);

            return () => {
                window?.google?.maps?.event.removeListener(dragEndListener);
                window?.google?.maps?.event.removeListener(zoomChangedListener);
            };
        }

        // eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        calculateOverlap();
        // eslint-disable-next-line
    }, []);

    return [ref, isOverlap];
};
