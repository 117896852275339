import { isNil } from 'lodash-es';
import constants from 'appConstants';
import { parseImageName } from 'utils/hive';

export const getCombFrameImages = (frame, frameImages, side, showAnnotatedPhotos) => {
    const sideKey = `side${side}`;

    if (showAnnotatedPhotos) {
        const { annotatedFileName, resizedAnnotatedFileName } = parseImageName(frame, frameImages, sideKey);

        return { url: annotatedFileName, resizedUrl: resizedAnnotatedFileName };
    }

    const sideImages = frameImages[sideKey] || {};

    return { url: sideImages?.fileName, resizedUrl: sideImages?.resizedFileName };
};

export const getIsFrameSelectable = (frame, actionMode, isInsideMarkedHive) => {
    if (actionMode === constants.COMMANDS.FILL_FEEDER) {
        return frame.type === constants.FRAME_TYPES.FEEDER && isInsideMarkedHive;
    } else if (actionMode === constants.COMMANDS.SCAN || actionMode === constants.COMMANDS.COUNT_BEES) {
        return frame.type === constants.FRAME_TYPES.COMB_FRAME && isInsideMarkedHive;
    }

    return false;
};

export const getIsFrameChecked = (frame, unsavedActivities, actionMode) => {
    if (
        actionMode === constants.COMMANDS.FILL_FEEDER ||
        actionMode === constants.COMMANDS.SCAN ||
        actionMode === constants.COMMANDS.COUNT_BEES
    ) {
        return unsavedActivities.find(activity => activity.rfid === frame.rfid);
    }
};

export const calculateFrameMetrics = ({ sideA, sideB }) => {
    let totalCell = 0;
    let totalPollen = 0;
    let totalHoney = 0;
    let totalBrood = 0;
    let totalBeesPercent = 0;
    let beeCount = 0;

    const framesSides = [sideA, sideB];

    const hasBeeCount = framesSides.some(side => !isNil(side?.beeCount?.beesPercent));

    const processSide = side => {
        if (!side) {
            return;
        }

        totalCell += side.cell || 0;
        totalPollen += side['cell-worker-pollen'] || 0;
        totalHoney += (side['cell-worker-caped_honey'] || 0) + (side['cell-worker-honey'] || 0);

        Object.keys(side).forEach(key => {
            if (key.includes('cell-worker-larva')) {
                totalBrood += side[key] || 0;
            }
        });

        totalBeesPercent += side.beeCount?.beesPercent ?? 0;
        beeCount += 1;
    };

    [sideA, sideB].forEach(processSide);

    const cellRatio = totalCell > 0 ? 100 / totalCell : 0;

    return {
        pollenPercent: +(totalPollen * cellRatio).toFixed(),
        honeyPercent: +(totalHoney * cellRatio).toFixed(),
        broodPercent: +(totalBrood * cellRatio).toFixed(),
        beesPercent: hasBeeCount ? +(beeCount > 0 ? totalBeesPercent / beeCount : 0).toFixed() : null,
    };
};
