import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Menu from '@beewise/menu';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { fetchUpdateBhomeLocation, removeMarker } from 'components/views/Dashboard/actions';
import { getCurrentBhomes } from 'components/views/BeeHome/selectors';
import { modalController } from 'components/reusables/ModalManager';
import { MODALS } from 'components/reusables/ModalManager/config';
import { groupBhomeOptions } from './utils';
import BhomeList from './BhomeList';

import './LocationCard.scss';

const Option = props => {
    const location = props.data?.data?.location || 'No location';
    return (
        <components.Option {...props}>
            <div className="menu-option">
                <span>{location}</span>
                <span>{props.data.label}</span>
            </div>
        </components.Option>
    );
};

Option.propTypes = {
    data: PropTypes.shape(),
};

const GroupHeading = props => (
    <components.GroupHeading {...props}>
        <span className="menu-group">{props.data.label}</span>
    </components.GroupHeading>
);

GroupHeading.propTypes = {
    data: PropTypes.shape(),
};

const LocationCard = ({ id, name, bhomeIds, setEditMode, handleHideTooltip }) => {
    const dispatch = useDispatch();

    const currentBhomes = useSelector(getCurrentBhomes, arrayOfObjectsShallowEqual);

    const options = groupBhomeOptions(currentBhomes);

    const values = bhomeIds ? options.flatMap(it => it.options).filter(option => bhomeIds.includes(option.value)) : [];

    const menuOptions = [
        { label: 'Edit name', onClick: setEditMode },
        { label: 'Delete location', onClick: () => dispatch(removeMarker(id)) },
    ];

    const moveBhome = (bhomeId, isRemoved) => {
        handleHideTooltip();
        const locationId = isRemoved ? null : id;
        dispatch(fetchUpdateBhomeLocation(bhomeId, locationId));
    };

    return (
        <div className="location-card">
            <div className="location-card-header">
                <span className="location-name">Drop name: {name}</span>
                {!!id && <Menu options={menuOptions} position="bottom right" />}
            </div>
            {!!id && (
                <div className="location-card-body">
                    <BhomeList bhomeIds={bhomeIds} />
                    <Select
                        isMulti
                        placeholder="Assign a bhome to this location"
                        className="bhome-select"
                        isSearchable
                        value={values}
                        options={options}
                        formatOptionLabel={it => it.value}
                        onChange={(_, item) => {
                            const isRemoved = !!item.removedValue;
                            const selected = item?.option?.data ?? item?.removedValue?.data;
                            if (selected) {
                                modalController.set({
                                    name: MODALS.MOVE_BHOME_MODAL,
                                    props: {
                                        bhome: selected,
                                        locationName: name,
                                        onSave: moveBhome,
                                        isRemoved,
                                    },
                                });
                                handleHideTooltip();
                            }
                        }}
                        components={{ Option, GroupHeading }}
                    />
                </div>
            )}
        </div>
    );
};

LocationCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    bhomeIds: PropTypes.arrayOf(PropTypes.number),
    setEditMode: PropTypes.func,
    handleHideTooltip: PropTypes.func,
};

export default LocationCard;
