import { FETCH_MOVE_FRAMES_V4 } from 'components/reusables/Hives/actionTypes';

export const fetchMoveFramesV4 = (bhomeId, body, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_MOVE_FRAMES_V4.default,
        url: `/bhomes/v4/${bhomeId}/frames/move`,
        failureToastText: 'Moving frames error',
        toastText: 'Moving frames actions have been sent to execute',
        body,
        resolver,
        rejector,
    },
});
