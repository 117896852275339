import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { showToast } from '@beewise/toast';
import { loading } from '@beewise/react-utils';
import constants from 'appConstants';
import analytics, { analyticsConstants } from 'utils/analytics';
import { getCurrentCompanyId } from 'components/views/Admin/selectors';
import { updatePreferences, changeComingSoonPreferences, fetchUserPreferences } from '../../actions';
import { getCountPreferences, getOverallPreferences, getTotalBeehomes } from '../../selectors';
import PreferencesList from './PreferencesList';
import { FETCH_UPDATE_PREFERENCES } from '../../actionTypes';

import './Preferences.scss';
import { PREFERENCE_TYPES, generatePreferencesOptions } from '../../utils';

const BeehomesSettings = () => {
    const dispatch = useDispatch();

    const preferences = useSelector(getOverallPreferences, shallowEqual);
    const countPreferences = useSelector(getCountPreferences, shallowEqual);
    const totalBeehomes = useSelector(getTotalBeehomes);
    const currentCompanyId = useSelector(getCurrentCompanyId);

    const showToastAndFetchPreferences = useCallback(() => {
        showToast('Preference saved', {
            toastType: 'toast-success',
        });
        dispatch(fetchUserPreferences());
    }, [dispatch]);

    const toggleBhomesPreference = useCallback(
        (preference, value) => () => {
            if (preference === PREFERENCE_TYPES.SMART_INSPECTION) {
                dispatch(
                    updatePreferences(
                        {
                            preferences: {
                                ...preferences,
                                auto_scan_policy: {
                                    ...preferences.auto_scan_policy,
                                    auto_scan_enabled: value,
                                },
                                visual_inspection_type: value ? constants.VISUAL_INSPECTION_TYPES.FULL : '',
                            },
                        },
                        showToastAndFetchPreferences
                    )
                );
            } else if (preference === PREFERENCE_TYPES.FEEDING_POLICY) {
                dispatch(changeComingSoonPreferences(preference));
                dispatch(
                    updatePreferences(
                        {
                            preferences: {
                                ...preferences,
                                auto_feed_policy: {
                                    ...preferences.auto_feed_policy,
                                    auto_feed_enabled: value,
                                },
                            },
                        },
                        showToastAndFetchPreferences
                    )
                );
            } else if (preference === PREFERENCE_TYPES.BEE_COUNT_SCAN_POLICY) {
                dispatch(
                    updatePreferences(
                        {
                            preferences: {
                                ...preferences,
                                auto_scan_policy: {
                                    ...preferences.auto_scan_policy,
                                    auto_scan_enabled: value,
                                },
                                visual_inspection_type: value ? constants.VISUAL_INSPECTION_TYPES.BEE_COUNT_ONLY : '',
                            },
                        },
                        showToastAndFetchPreferences
                    )
                );
            } else {
                const action = preferences[preference]
                    ? analyticsConstants.EVENT_NAMES.setSystemPreferencesToNo
                    : analyticsConstants.EVENT_NAMES.setSystemPreferencesToYes;
                analytics.fireChangeSystemPreferencesEvent({
                    action,
                    label: preference,
                });
                dispatch(changeComingSoonPreferences(preference));
            }
        },
        [dispatch, preferences, showToastAndFetchPreferences]
    );

    const generateDescription = (feature, isEnabledCount, total) => (
        <div>
            {feature}
            <div>
                {isEnabledCount} of {total} {`beehome${isEnabledCount < 2 ? '' : 's'}`}{' '}
                {isEnabledCount < 2 ? 'is' : 'are'} enabled
            </div>
        </div>
    );

    const preferencesOptions = useMemo(
        () =>
            generatePreferencesOptions(
                currentCompanyId,
                countPreferences,
                preferences,
                totalBeehomes,
                toggleBhomesPreference,
                generateDescription
            ),
        [currentCompanyId, countPreferences, preferences, totalBeehomes, toggleBhomesPreference]
    );

    return (
        <div className="preferences">
            <div className="preferences-description">Choose your Beehomes policy</div>
            <PreferencesList options={preferencesOptions} />
        </div>
    );
};

export default loading([FETCH_UPDATE_PREFERENCES.default])(BeehomesSettings);
