import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import MarkerIcon from '../MarkerIcon';
import MarkerWrapper from '../MarkerWrapper';

import './EmptyLocationMarker.scss';

const EmptyLocationMarker = props => {
    const markerRef = useRef();
    return (
        <MarkerWrapper {...props} ref={markerRef}>
            <MarkerIcon className="empty-location" icon="empty-marker" count={1} ref={markerRef} />
        </MarkerWrapper>
    );
};

EmptyLocationMarker.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    map: PropTypes.shape(),
    maps: PropTypes.shape(),
};

export default EmptyLocationMarker;
