export const FILTER_VALUES = {
    ALL: 'all',
    FULLY: 'fully',
    PARTIALLY: 'partially',
    UNFED: 'unfed',
};

export const FED_HIVES_OPTIONS = [
    { value: FILTER_VALUES.ALL, label: 'All hives' },
    {
        value: FILTER_VALUES.FULLY,
        label: 'Fully fed hives',
    },
    { value: FILTER_VALUES.PARTIALLY, label: 'Partially fed hives' },
    { value: FILTER_VALUES.UNFED, label: 'Unfed hives' },
];

export const getFilteredData = ({ filter, data }) => {
    switch (filter) {
        case FILTER_VALUES.FULLY:
            return data.filter(
                ({ totalAmountOfFedHives, markedHives }) =>
                    totalAmountOfFedHives && totalAmountOfFedHives === markedHives.length
            );
        case FILTER_VALUES.PARTIALLY:
            return data.filter(
                ({ totalAmountOfFedHives, markedHives }) =>
                    totalAmountOfFedHives && totalAmountOfFedHives < markedHives?.length
            );
        case FILTER_VALUES.UNFED:
            return data.filter(({ totalAmountOfFedHives }) => !totalAmountOfFedHives);
        default:
            return data;
    }
};
