import React from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { range } from 'lodash-es';
import { useImageWithFallback } from 'utils/useImageWithFallback';
import Magnifier from './magnifier';
import useCombFrameImages from '../useCombFrameImages';
import logo from '../no-scan-logo.svg';

import 'components/reusables/RightPanel/components/HiveBlock/FramePlaceholders.scss';

const FeederFrame = () => (
    <div className="feeder-frame-placeholder">
        <div className="feeder-frame-placeholder-rect" />
        <div className="feeder-frame-placeholder-rect">Feeder frame</div>
        <div className="feeder-frame-placeholder-rect" />
    </div>
);

const QueenExcluder = () => (
    <div className="queen-excluder-placeholder">
        {range(22 * 6).map(idx => (
            <div key={`empty-cell-${idx}`} className="queen-excluder-placeholder-empty-cell" />
        ))}
        <div className="queen-excluder-placeholder-label">Queen excluder</div>
    </div>
);

const PartitionFrame = () => (
    <div className="partition-frame-placeholder">
        <div className="partition-frame-placeholder-content">
            <div className="partition-frame-placeholder-label">Partition Frame</div>
        </div>
    </div>
);

const StaticPartitionFrame = () => (
    <div className="static-partition-frame-placeholder">
        <div className="static-partition-frame-placeholder-content">
            <div className="static-partition-frame-placeholder-label">Static Partition Frame</div>
        </div>
    </div>
);

const EmptyFrame = () => (
    <div className="empty-frame-placeholder">
        <div className="empty-frame-placeholder-content">
            <div className="empty-frame-placeholder-label">Empty slot</div>
        </div>
    </div>
);

export const NotAvailableScan = () => (
    <div className="not-available-scan-placeholder">
        <img src={logo} className="not-available-scan-icon" alt="No scan" />
        <div className="not-available-scan-placeholder-label">No available scan</div>
    </div>
);

const ImageViewer = ({ fullImageUrl, resizedImageUrl }) => {
    const { currentImage, isLoading, error } = useImageWithFallback({
        primaryImageUrl: resizedImageUrl,
        fallbackImageUrl: fullImageUrl,
    });

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <NotAvailableScan />;
    }

    return <Magnifier src={currentImage} mgShape="square" mgWidth={64} mgHeight={64} zoomFactor={6} />;
};

ImageViewer.propTypes = {
    fullImageUrl: PropTypes.string,
    resizedImageUrl: PropTypes.string,
};

export const FrameImage = ({ frame, frameImages, side, showAnnotatedPhotos }) => {
    const { url, resizedUrl } = useCombFrameImages(frame, frameImages, side, showAnnotatedPhotos);

    switch (frame.type) {
        case constants.FRAME_TYPES.COMB_FRAME: {
            return url || resizedUrl ? (
                <ImageViewer fullImageUrl={url} resizedImageUrl={resizedUrl} />
            ) : (
                <NotAvailableScan />
            );
        }
        case constants.FRAME_TYPES.FEEDER:
            return <FeederFrame />;
        case constants.FRAME_TYPES.QUEEN_EXCLUDER:
            return <QueenExcluder />;
        case constants.FRAME_TYPES.PARTITION:
            return <PartitionFrame />;
        case constants.FRAME_TYPES.STATIC_PARTITION:
            return <StaticPartitionFrame />;
        default:
            return <EmptyFrame />;
    }
};

FrameImage.propTypes = {
    frame: PropTypes.shape(),
    frameImages: PropTypes.shape(),
    showAnnotatedPhotos: PropTypes.bool.isRequired,
    side: PropTypes.string.isRequired,
};

export const FrameImageV2 = ({ frameType, url, isLoading = false, error }) => {
    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <NotAvailableScan />;
    }

    switch (frameType) {
        case constants.FRAME_TYPES.COMB_FRAME: {
            return url ? (
                <Magnifier src={url} mgShape="square" mgWidth={64} mgHeight={64} zoomFactor={6} />
            ) : (
                <NotAvailableScan />
            );
        }
        case constants.FRAME_TYPES.FEEDER:
            return <FeederFrame />;
        case constants.FRAME_TYPES.QUEEN_EXCLUDER:
            return <QueenExcluder />;
        case constants.FRAME_TYPES.PARTITION:
            return <PartitionFrame />;
        case constants.FRAME_TYPES.STATIC_PARTITION:
            return <StaticPartitionFrame />;
        default:
            return <EmptyFrame />;
    }
};

FrameImageV2.propTypes = {
    frameType: PropTypes.string.isRequired,
    url: PropTypes.string,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
};
