import constants from 'appConstants';

export const getHiveAmountPerGrade = hives =>
    hives.reduce(
        (acc, hive) => {
            switch (hive?.assessment) {
                case constants.HIVE_ASSESSMENT.STRONG:
                    acc.strongHives += 1;
                    break;
                case constants.HIVE_ASSESSMENT.MEDIUM:
                    acc.mediumHives += 1;
                    break;
                case constants.HIVE_ASSESSMENT.WEAK:
                    acc.weakHives += 1;
                    break;
                case constants.HIVE_ASSESSMENT.DEAD:
                    acc.deadHives += 1;
                    break;
                default:
                    break;
            }

            switch (hive?.historyAssessment) {
                case constants.HIVE_ASSESSMENT.WEAK.STRONG:
                    acc.historyStrongHives += 1;
                    break;
                case constants.HIVE_ASSESSMENT.MEDIUM:
                    acc.historyMediumHives += 1;
                    break;
                case constants.HIVE_ASSESSMENT.WEAK:
                    acc.historyWeakHives += 1;
                    break;
                case constants.HIVE_ASSESSMENT.DEAD:
                    acc.historyDeadHives += 1;
                    break;
                default:
                    break;
            }

            return acc;
        },
        {
            strongHives: 0,
            historyStrongHives: 0,
            mediumHives: 0,
            historyMediumHives: 0,
            weakHives: 0,
            historyWeakHives: 0,
            deadHives: 0,
            historyDeadHives: 0,
        }
    );
