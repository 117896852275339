import React from 'react';
import PropTypes from 'prop-types';
import ActiveMode from '../../ActiveMode';

const ActiveModes = ({ bhomeMode, activeMode, endActionHandler }) => (
    <>
        {bhomeMode?.visit && (
            <ActiveMode
                title="This Beehome is ready for a visit which will end automatically by the end of the day tomorrow. Other actions
            can’t be made during visit."
                clickHandler={endActionHandler}
                activeMode={activeMode}
                modeTitle={activeMode}
            />
        )}
        {bhomeMode?.transportation && (
            <ActiveMode
                title="This Beehome is ready for transportation. Other actions can’t be made during transportation."
                clickHandler={endActionHandler}
                activeMode={activeMode}
                modeTitle="Transport"
            />
        )}
    </>
);

ActiveModes.propTypes = {
    bhomeMode: PropTypes.shape().isRequired,
    activeMode: PropTypes.string.isRequired,
    endActionHandler: PropTypes.func.isRequired,
};

export default ActiveModes;
