import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendDown, faArrowTrendUp, faMinus } from '@fortawesome/pro-solid-svg-icons';
import Chart from './Chart';
import { getYardData, GRAPHS_LEVELS } from '../../utils';

const getClassName = (value, yesterdayValue, isReverse) => {
    if (value === yesterdayValue) {
        return 'workspace-list-item-icon-no-changes';
    }
    if (isReverse) {
        return value >= yesterdayValue ? 'workspace-list-item-icon-down' : 'workspace-list-item-icon-up';
    }
    return value >= yesterdayValue ? 'workspace-list-item-icon-up' : 'workspace-list-item-icon-down';
};

const getTooltipContent = (value, yesterdayValue) => {
    const diff = Number((value / yesterdayValue) * 100 - 100);

    return (
        <>
            <span className={getClassName(value, yesterdayValue)}>
                {Number.isNaN(diff) || !Number.isFinite(diff) ? '-' : Number(diff).toFixed(1)}%
            </span>{' '}
            compares to 10 days ago
        </>
    );
};

export const getIcon = (value, yesterdayValue, isReverse) => {
    if (!value || value === '-') {
        return (
            <FontAwesomeIcon
                icon={faMinus}
                className={`workspace-list-item-icon ${getClassName(value, yesterdayValue, isReverse)}`}
            />
        );
    }

    let icon = faMinus;

    if (value > yesterdayValue) {
        icon = faArrowTrendUp;
    } else if (value < yesterdayValue) {
        icon = faArrowTrendDown;
    }

    return (
        <Tooltip content={getTooltipContent(value, yesterdayValue)} position="top center">
            <FontAwesomeIcon
                icon={icon}
                className={`workspace-list-item-icon ${getClassName(value, yesterdayValue, isReverse)}`}
            />
        </Tooltip>
    );
};

const FrameDetails = ({ yardRow }) => {
    const yardData = getYardData(yardRow);

    return (
        <div className="workspace-list-item-line">
            <div className="workspace-list-item-badge">
                <div className="workspace-list-item-badge-info">
                    <div className="workspace-list-item-badge-header">
                        <Icon type="Cell" className="workspace-list-item-badge-icon" />
                        <div className="workspace-list-item-badge-subtitle">Empty</div>
                    </div>
                    <div className="workspace-list-item-badge-text">
                        {yardData?.empty} {getIcon(yardData?.empty, yardData?.yesterdayEmpty)}
                    </div>
                </div>
                <Chart dataSource={yardRow.averageEmpty} graphLevel={GRAPHS_LEVELS.empty} />
            </div>
            <div className="workspace-list-item-badge">
                <div className="workspace-list-item-badge-info">
                    <div className="workspace-list-item-badge-header">
                        <Icon type="BeeSimple" className="workspace-list-item-badge-icon" />
                        <div className="workspace-list-item-badge-subtitle">Bee</div>
                    </div>
                    <div className="workspace-list-item-badge-text">
                        {yardData?.population} {getIcon(yardData?.population, yardData?.yesterdayPopulation)}
                    </div>
                </div>
                <Chart dataSource={yardRow.averagePopulation} graphLevel={GRAPHS_LEVELS.population} />
            </div>
            <div className="workspace-list-item-badge">
                <div className="workspace-list-item-badge-info">
                    <div className="workspace-list-item-badge-header">
                        <Icon type="Brood" className="workspace-list-item-badge-icon" />
                        <div className="workspace-list-item-badge-subtitle">Brood</div>
                    </div>
                    <div className="workspace-list-item-badge-text">
                        {yardData?.brood} {getIcon(yardData?.brood, yardData?.yesterdayBrood)}
                    </div>
                </div>
                <Chart dataSource={yardRow.averageBrood} graphLevel={GRAPHS_LEVELS.brood} />
            </div>
            <div className="workspace-list-item-badge">
                <div className="workspace-list-item-badge-info">
                    <div className="workspace-list-item-badge-header">
                        <Icon type="Honey" className="workspace-list-item-badge-icon" />
                        <div className="workspace-list-item-badge-subtitle">Honey</div>
                    </div>
                    <div className="workspace-list-item-badge-text">
                        {yardData?.honey} {getIcon(yardData?.honey, yardData?.yesterdayHoney)}
                    </div>
                </div>
                <Chart dataSource={yardRow.averageHoney} graphLevel={GRAPHS_LEVELS.honey} />
            </div>
        </div>
    );
};

FrameDetails.propTypes = {
    yardRow: PropTypes.shape(),
};

export default FrameDetails;
