export default (action, state) => {
    const side = action.payload.side.toUpperCase();

    return {
        ...state,
        frames: state.frames.map(item => {
            if (item.rfid === action.payload.rfid) {
                return {
                    ...item,
                    [`previousSide${side}`]: item[`side${side}`],
                    [`side${side}`]: action.payload.dataForUpdate,
                    lastUpdated: action.payload.lastUpdated,
                    previousUpdateDate: action.payload.previousUpdateDate,
                };
            }
            return item;
        }),
    };
};
