import { put, takeEvery } from 'redux-saga/effects';
import { FETCH_SIGNIN } from 'components/views/SignIn/actionTypes';
import { SET_PASSWORD } from '../actions';

function* onChangePassword(action) {
    if (action.data.redirectLink) {
        window.location.href = action.data.redirectLink;
    } else {
        yield put({ type: FETCH_SIGNIN.success, data: action.data });
    }
}

function* root() {
    yield takeEvery(SET_PASSWORD.success, onChangePassword);
}

export default root;
