import { useState, useEffect } from 'react';

const PROMISE_STATUS = {
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
};
const loadImage = url =>
    new Promise((resolve, reject) => {
        if (!url) {
            reject(new Error('No image URL provided'));
            return;
        }

        const image = new Image();
        image.onload = () => resolve(url);
        image.onerror = error => reject(error);
        image.src = url;
    });

export const useImageWithFallback = ({ primaryImageUrl, fallbackImageUrl }) => {
    const [images, setImages] = useState({
        primaryUrl: null,
        fallbackUrl: null,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!primaryImageUrl && !fallbackImageUrl) {
            setIsLoading(false);
            setError('No image URLs provided');

            return;
        }

        Promise.allSettled([loadImage(primaryImageUrl), loadImage(fallbackImageUrl)])
            .then(results => {
                const [primaryResult, fallbackResult] = results;

                if (primaryResult.status === PROMISE_STATUS.FULFILLED) {
                    setImages(prevImages => ({
                        ...prevImages,
                        primaryUrl: primaryResult.value,
                        fallbackUrl: null,
                    }));
                    setError(null);
                } else if (fallbackResult.status === PROMISE_STATUS.FULFILLED) {
                    setImages(prevImages => ({
                        ...prevImages,
                        fallbackUrl: fallbackResult.value,
                        primaryUrl: null,
                    }));
                    setError(null);
                }

                if (
                    primaryResult.status === PROMISE_STATUS.REJECTED &&
                    fallbackResult.status === PROMISE_STATUS.REJECTED
                ) {
                    setError('Failed to load both primary and fallback images');
                }
            })
            .catch(error => setError(error.message))
            .finally(() => setIsLoading(false));
    }, [primaryImageUrl, fallbackImageUrl]);

    return { currentImage: images.primaryUrl || images.fallbackUrl, isLoading, error };
};
