import { UPDATE_HIVE_ASSESSMENT } from 'components/reusables/Hives/actionTypes';

export const updateHiveAssessment = ({ bhomeId, hiveId, body, resolver, rejector }) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: UPDATE_HIVE_ASSESSMENT.default,
        url: `/bhomes/v4/${bhomeId}/hives/${hiveId}`,
        failureToastText: 'Update hive assessment error',
        toastText: 'Hive assessment updated',
        body,
        resolver,
        rejector,
    },
});
