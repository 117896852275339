import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { noop } from 'lodash-es';
import Toggle from '@beewise/toggle';
import Checkbox from '@beewise/checkbox-field';
import { SelectField } from '@beewise/select-field';
import constants from 'appConstants';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import cn from 'classnames';
import dayjs from 'dayjs';
import { fetchLayoutSnapshot } from 'components/views/BeeHome/actions';
import { getCurrentBhome, getCurrentBhomeFrames, getSnapshots } from 'components/views/BeeHome/selectors';
import useMarkedHives from 'utils/useMarkedHives';
import Station from './components/Station';
import './Hives.scss';
import { numberOfDaysForScansOptions } from '../HivesV2/utils';

const INITIAL_DAY = 1;

const Hives = ({ isModalMode = false, onHiveSelectModalMode = noop, selectedHiveModalMode }) => {
    const dispatch = useDispatch();
    const markedHives = useMarkedHives();

    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const data = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);
    const snapshots = useSelector(getSnapshots, arrayOfObjectsShallowEqual);

    const [isBeeCountMode, setIsBeeCountMode] = useState(!isModalMode);
    const [numberOfDaysForScans, setNumberOfDaysForScans] = useState(INITIAL_DAY);
    const [showFramesHistory, setShowFramesHistory] = useState(false);
    const [selectedSnapshot, setSelectedSnapshot] = useState(null);

    useEffect(() => {
        if (!currentBhome.id) {
            return;
        }

        dispatch(fetchLayoutSnapshot(currentBhome.id));
    }, [currentBhome.id, dispatch]);

    const handleNumberOfDaysForScansChange = value => {
        setNumberOfDaysForScans(value);

        if (!snapshots?.length) {
            return;
        }
        const targetDate = dayjs().subtract(value, 'days').startOf('day');
        const snapshot = snapshots.find(s => dayjs(s.createdAt).startOf('day').isSame(targetDate));
        setSelectedSnapshot(snapshot || null);
    };

    const handleFilteringScansChange = () => {
        setShowFramesHistory(!showFramesHistory);
        if (!showFramesHistory) {
            handleNumberOfDaysForScansChange(numberOfDaysForScans);
        } else {
            setSelectedSnapshot(null);
            setNumberOfDaysForScans(INITIAL_DAY);
        }
    };

    const currentLayoutData = selectedSnapshot?.data?.frames || data;

    return (
        <div className="hives hive-stations">
            <div className={cn('hive-stations-container', { modal: isModalMode })}>
                <div className="hive-stations-header">
                    <div className="hive-stations-header-item">
                        <Checkbox onChange={handleFilteringScansChange} checked={showFramesHistory} />
                        Show scans from last
                        <SelectField
                            options={numberOfDaysForScansOptions}
                            onChange={handleNumberOfDaysForScansChange}
                            value={numberOfDaysForScans}
                            size="xsmall"
                            disabled={!showFramesHistory}
                        />
                    </div>
                    {!isModalMode && (
                        <Toggle
                            value={isBeeCountMode}
                            name="isBeeCountMode"
                            onChange={setIsBeeCountMode}
                            label="Bee Count"
                        />
                    )}
                </div>
                <div className="hive-stations-content">
                    <div className="hive-stations-lines">
                        <div className="hive-stations-line">
                            <div className="hive-stations-name">A</div>
                            <div className="hive-stations-frames">
                                <Station
                                    stationName={constants.STATIONS.A}
                                    layout={currentLayoutData}
                                    hives={markedHives}
                                    settings={currentBhome?.settings}
                                    isModalMode={isModalMode}
                                    onHiveSelectModalMode={onHiveSelectModalMode}
                                    selectedHiveModalMode={selectedHiveModalMode}
                                    isBeeCountMode={isBeeCountMode}
                                />
                            </div>
                        </div>
                        <div className="hive-stations-line">
                            <div className="hive-stations-name">B</div>
                            <div className="hive-stations-frames">
                                <Station
                                    stationName={constants.STATIONS.B}
                                    layout={currentLayoutData}
                                    hives={markedHives}
                                    settings={currentBhome?.settings}
                                    isModalMode={isModalMode}
                                    onHiveSelectModalMode={onHiveSelectModalMode}
                                    selectedHiveModalMode={selectedHiveModalMode}
                                    isBeeCountMode={isBeeCountMode}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="hive-syrup-container">
                        <span className="hive-syrup-text">{currentBhome?.sensors?.syrupLevel ?? 0}% Syrup</span>
                        <div
                            className="hive-syrup-level"
                            style={{
                                height: `${currentBhome?.sensors?.syrupLevel ?? 0}%`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Hives.propTypes = {
    isModalMode: PropTypes.bool,
    onHiveSelectModalMode: PropTypes.func,
    selectedHiveModalMode: PropTypes.shape(),
};

export default memo(Hives);
