import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
    faCircleInfo,
    faCircleMinus,
    faClock,
    faNote,
    faOctagonExclamation,
    faPen,
    faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@beewise/tooltip';
import dayjs from 'dayjs';
import { format } from 'timeago.js';
import { isNumber } from 'lodash-es';
import { setFrameToInspectByFrameId } from 'components/views/BeeHome/actions';
import { getCurrentBhomeFrames } from 'components/views/BeeHome/selectors';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import useGetFrameStationHiveAndFrameNumbers from 'utils/useGetFrameStationHiveAndFrameNumbers';

const getBeekeepersEntryIcon = entry => {
    switch (entry.type) {
        case constants.ALERT_PRIORITY.LOW_PRIORITY:
            return faCircleInfo;
        case constants.ALERT_PRIORITY.MEDIUM_PRIORITY:
            return faTriangleExclamation;
        case constants.ALERT_PRIORITY.HIGH_PRIORITY:
            return faOctagonExclamation;
        default:
            return faNote;
    }
};

const BeekeepersEntry = ({ entry, isHistory, onEdit, onDelete, inspectedFrame }) => {
    const data = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);
    const dispatch = useDispatch();

    const frame = useMemo(() => data.find(frame => frame.frameId === entry?.frame_id), [data, entry?.frame_id]);
    const frameInfo = useGetFrameStationHiveAndFrameNumbers(frame);

    const handleGoInsideHiveOrFrame = useCallback(
        e => {
            e.stopPropagation();

            if (entry.frame_id) {
                dispatch(setFrameToInspectByFrameId(entry.frame_id));
            }
        },
        [entry, dispatch]
    );

    const getEntrySource = useCallback(
        entry => {
            if (!inspectedFrame.frameId) {
                if (!entry.frame_id) {
                    return null;
                }
                return (
                    <div className="beekeeper-entry-source" role="presentation" onClick={handleGoInsideHiveOrFrame}>
                        Frame {frameInfo?.frameNumber}
                    </div>
                );
            } else {
                if (entry.frame_id) {
                    return (
                        <div className="beekeeper-entry-source" role="presentation" onClick={handleGoInsideHiveOrFrame}>
                            frameInfo?.text
                        </div>
                    );
                } else if (isNumber(entry.hive_id)) {
                    return (
                        <div className="beekeeper-entry-source" role="presentation" onClick={handleGoInsideHiveOrFrame}>
                            Hive {frameInfo?.hiveNumber}({frameInfo?.station?.toUpperCase()})
                        </div>
                    );
                }
                return null;
            }
        },
        [frameInfo, handleGoInsideHiveOrFrame, inspectedFrame.frameId]
    );

    return (
        <div
            className={cx('beekeeper-entry', {
                low: entry.type === constants.ALERT_PRIORITY.LOW_PRIORITY,
                medium: entry.type === constants.ALERT_PRIORITY.MEDIUM_PRIORITY,
                high: entry.type === constants.ALERT_PRIORITY.HIGH_PRIORITY,
            })}
        >
            <div className="beekeeper-entry-header">
                <FontAwesomeIcon icon={getBeekeepersEntryIcon(entry)} className="note-icon" />
                <div className="beekeeper-entry-title">Beekeeper note</div>
                <div className="beekeeper-entry-author">{entry.username}</div>
                {!isHistory && (
                    <Tooltip
                        text="Edit note"
                        position="left center"
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        hideOnScroll
                    >
                        <FontAwesomeIcon icon={faPen} className="pen-icon" onClick={onEdit(entry)} />
                    </Tooltip>
                )}
            </div>
            <div className="new-pane-alert-content">{getEntrySource(entry)}</div>
            <div className="beekeeper-entry-content">
                <div className="beekeeper-entry-message">{entry.note}</div>
                {!isHistory && (
                    <Tooltip
                        text="Delete note"
                        position="left center"
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        hideOnScroll
                    >
                        <FontAwesomeIcon icon={faCircleMinus} className="circle-minus-icon" onClick={onDelete(entry)} />
                    </Tooltip>
                )}
            </div>
            <div className="beekeeper-entry-footer">
                <FontAwesomeIcon icon={faClock} className="clock-icon" />
                <Tooltip
                    text={dayjs(entry.created_at).utc().format('MMM D, YYYY')}
                    position="top center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    hideOnScroll
                >
                    <div className="beekeeper-entry-date">{format(entry.created_at, 'en_US')}</div>
                </Tooltip>
            </div>
        </div>
    );
};

BeekeepersEntry.propTypes = {
    entry: PropTypes.shape(),
    isHistory: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    inspectedFrame: PropTypes.shape(),
};

export default BeekeepersEntry;
