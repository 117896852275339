import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import { fetchPosts } from './utils';
import './BlogPanel.scss';

const BlogPanel = ({ onClose }) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    useEffect(() => {
        const fetchAndSetPosts = async () => {
            try {
                const fetchedPosts = await fetchPosts(currentBhome?.id);
                setPosts(fetchedPosts);
            } catch (error) {
                return error;
            } finally {
                setLoading(false);
            }
        };

        if (currentBhome?.id) {
            fetchAndSetPosts();
        }
    }, [currentBhome?.id]);

    const renderContent = () => {
        if (loading) {
            return <p className="right-panel-content-section-info">Loading blog posts...</p>;
        }

        if (!posts.length) {
            return <p className="right-panel-content-section-info">No posts about this Beehome yet</p>;
        }

        return (
            <ul className="right-panel-content-section-post-list">
                {posts.map(post => (
                    <li key={post.slug} className="right-panel-content-section-post-list-post">
                        {post.coverImage?.url && (
                            <img
                                src={post.coverImage.url}
                                alt={post.title}
                                className="right-panel-content-section-post-list-post-cover"
                            />
                        )}
                        <h1 className="right-panel-content-section-post-list-post-title">{post.title}</h1>
                        {post.subtitle !== 'Subtitle' && (
                            <p className="right-panel-content-section-post-list-post-subtitle">{post.subtitle}</p>
                        )}
                        <div className="right-panel-content-section-post-list-post-info">
                            <p className="right-panel-content-section-post-list-post-info-read-time">
                                <span>Read Time:</span> {post.readTimeInMinutes} min
                            </p>
                            <p className="right-panel-content-section-post-list-post-info-published-date">
                                <span>Published:</span>{' '}
                                {new Date(post.publishedAt).toLocaleString?.('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                })}
                            </p>
                        </div>
                        <p className="right-panel-content-section-post-list-post-author-info">
                            <span>By:</span> {post.author?.name}
                            <img
                                src={post.author?.profilePicture}
                                alt={post.title}
                                className="right-panel-content-section-post-list-post-author-info-author-img"
                            />
                        </p>
                        <div
                            className="right-panel-content-section-post-list-post-text"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content.html) }}
                        />
                        <div className="right-panel-content-section-post-list-post-tags">
                            {post.tags.map(tag => (
                                <span key={tag.name} className="right-panel-content-section-post-list-post-tags-tag">
                                    #{tag.name}
                                </span>
                            ))}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className="right-panel-content">
            <header className="right-panel-content-header">
                <FontAwesomeIcon icon={faXmark} className="right-panel-content-header-close-icon" onClick={onClose} />
                <h3 className="right-panel-content-header-title">Beehome Blog</h3>
            </header>
            <section className="right-panel-content-section">{renderContent()}</section>
        </div>
    );
};

BlogPanel.propTypes = {
    onClose: PropTypes.func,
};

export default BlogPanel;
