import React from 'react';
import PropTypes from 'prop-types';
import './BlockLabels.scss';

const BlockLabel = ({ name }) => <div className="block-label-item">{name}</div>;

BlockLabel.propTypes = {
    name: PropTypes.string.isRequired,
};

export default BlockLabel;
