import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getIsLoggedInAsSuperAdmin } from 'components/views/Admin/selectors';

const AdminRoute = ({ component: Component, ...rest }) => {
    const isLoggedInAsSuperAdmin = useSelector(getIsLoggedInAsSuperAdmin);

    return isLoggedInAsSuperAdmin ? <Component {...rest} /> : <Navigate to="/signin" />;
};

AdminRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default AdminRoute;
