import { createAsyncAction } from '@beewise/utils';

export const FETCH_COMPANY_LOCATIONS = createAsyncAction('FETCH_COMPANY_LOCATIONS');
export const FETCH_DASHBOARD_GRID_DATA = createAsyncAction('FETCH_DASHBOARD_GRID_DATA');
export const CREATE_COMPANY_LOCATION = createAsyncAction('CREATE_COMPANY_LOCATION');
export const UPDATE_COMPANY_LOCATION = createAsyncAction('UPDATE_COMPANY_LOCATION');
export const REMOVE_COMPANY_LOCATION = createAsyncAction('REMOVE_COMPANY_LOCATION');
export const FETCH_ALL_BHOMES_ONLINE = createAsyncAction('FETCH_ALL_BHOMES_ONLINE');
export const SAVE_CURRENT_MAP_STATE = 'SAVE_CURRENT_MAP_STATE';
export const CLEAR_CURRENT_MAP_STATE = 'CLEAR_CURRENT_MAP_STATE';
export const CLEAR_COMPANY_LOCATIONS = 'CLEAR_COMPANY_LOCATIONS';
export const UPDATE_BHOME_LOCATION = createAsyncAction('UPDATE_BHOME_LOCATION');

export const FETCH_FEED_BHOMES = createAsyncAction('FETCH_FEED_BHOMES');

export const FETCH_BHOMES_FEED_STATUSES = createAsyncAction('FETCH_BHOMES_FEED_STATUSES');
export const SET_BHOME_ARRIVED = 'SET_BHOME_ARRIVED';
export const FETCH_UPDATE_BHOME_FEED_SETTINGS = createAsyncAction('FETCH_UPDATE_BHOME_FEED_SETTINGS');
