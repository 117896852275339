import React from 'react';
import PropTypes from 'prop-types';
import ActionIcon from 'components/reusables/ActionIcon';
import './ActiveMode.scss';

const ActiveMode = ({ title, clickHandler, modeTitle, activeMode }) => (
    <div className="actions-active">
        <p className="actions-active-title">{title}</p>
        <ActionIcon name={`End ${modeTitle}`} icon={`end-${activeMode}`} onClick={clickHandler} />
    </div>
);

ActiveMode.propTypes = {
    title: PropTypes.string,
    clickHandler: PropTypes.func,
    activeMode: PropTypes.string,
    modeTitle: PropTypes.string,
};

export default ActiveMode;
