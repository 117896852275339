const getPolygonCenterCoordinates = flattenedPolygons => {
    const lat =
        (Math.max(...flattenedPolygons.map(({ lat }) => lat)) + Math.min(...flattenedPolygons.map(({ lat }) => lat))) /
        2;
    const lng =
        (Math.max(...flattenedPolygons.map(({ lng }) => lng)) + Math.min(...flattenedPolygons.map(({ lng }) => lng))) /
        2;

    return { lat, lng };
};

export default getPolygonCenterCoordinates;
