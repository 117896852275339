import {
    CLOSE_INVITATION_MODEL,
    FETCH_DELETE_USER,
    GET_COMPANY,
    INVITE_USERS,
    OPEN_INVITATION_MODEL,
    FETCH_USER_SETTINGS,
    FETCH_UPDATE_USER_ALERTS_SETTINGS,
    FETCH_PREFERENCES,
    FETCH_UPDATE_PREFERENCES,
    CHANGE_COMING_SOON_PREFERENCES,
    FETCH_REGIONS,
    FETCH_CREATE_REGION,
    FETCH_UPDATE_REGION,
    FETCH_DELETE_REGION,
    CREATE_NEW_REGION,
    DELETE_NEW_REGION,
} from '../actionTypes';

const initialState = {
    isInvitationModelOpen: false,
    company: {},
    invitationError: '',
    userSettings: {},
    userPreferences: {},
    userCountPreferences: {},
    totalBeehomes: 0,
    regions: [],
    newRegion: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY.success:
            return {
                ...state,
                company: action.data.company,
            };
        case FETCH_USER_SETTINGS.success:
            return {
                ...state,
                userSettings: action.data.settings,
            };
        case FETCH_UPDATE_USER_ALERTS_SETTINGS.success:
            return {
                ...state,
                userSettings: action.data.settings,
            };
        case FETCH_DELETE_USER.success:
            return {
                ...state,
                company: {
                    ...state.company,
                    users: state.company.users.filter(item => item.id !== action.data.id),
                },
            };
        case CREATE_NEW_REGION:
            return {
                ...state,
                newRegion: {
                    name: '',
                    yards: [],
                },
            };
        case DELETE_NEW_REGION:
            return {
                ...state,
                newRegion: null,
            };
        case FETCH_REGIONS.success:
            return {
                ...state,
                regions: action.data.regions,
            };
        case FETCH_CREATE_REGION.success:
            return {
                ...state,
                regions: [...state.regions, action.data.region],
                newRegion: null,
            };
        case FETCH_UPDATE_REGION.success:
            return {
                ...state,
                regions: state.regions.map(item => (item.id === action.data.region.id ? action.data.region : item)),
            };
        case FETCH_DELETE_REGION.success:
            return {
                ...state,
                regions: state.regions.filter(item => item.id !== action.data.region.id),
            };
        case FETCH_PREFERENCES.failed:
        case FETCH_UPDATE_PREFERENCES.failed:
            return {
                ...state,
                userPreferences: {},
                userCountPreferences: {},
            };
        case FETCH_PREFERENCES.success:
        case FETCH_UPDATE_PREFERENCES.success:
            return {
                ...state,
                userPreferences: action.data.preferences,
                userCountPreferences: action.data.enabledCounts,
                totalBeehomes: action.data.totalBeehomes,
            };
        case CHANGE_COMING_SOON_PREFERENCES:
            return {
                ...state,
                userPreferences: {
                    ...state.userPreferences,
                    [action.key]: !state.userPreferences[action.key],
                },
            };
        case OPEN_INVITATION_MODEL:
            return {
                ...state,
                isInvitationModelOpen: true,
            };
        case CLOSE_INVITATION_MODEL:
            return {
                ...state,
                isInvitationModelOpen: false,
                invitationError: '',
            };
        case INVITE_USERS.success:
            return {
                ...state,
                isInvitationModelOpen: false,
                invitationError: '',
            };
        case INVITE_USERS.failed:
            return {
                ...state,
                invitationError: action.error,
            };
        default:
            return state;
    }
};
