import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import { moveFramesCalculatorV4 } from '@beewise/utils';
import { getStations } from 'components/views/BeeHome/selectors';
import Frame from './Frame';
import { ReactComponent as StorageSpaceArrow } from './storage-space-arrow.svg';

const StepTwo = ({ selectedFrames, movedFrames, setMovedFrames, settings }) => {
    const stations = useSelector(getStations, shallowEqual);
    const partitionIndex = movedFrames.frames.findIndex(({ type }) => type === constants.FRAME_TYPES.PARTITION);

    const moveFrame = useCallback(
        (dragIndex, hoverIndex) => {
            setMovedFrames(prevFrames => {
                if (dragIndex === movedFrames.frames.length - 1 || hoverIndex === movedFrames.frames.length - 1) {
                    return prevFrames;
                }

                const result = structuredClone(prevFrames.frames).map((frame, i) => ({
                    ...frame,
                    initialFrameIndex: i,
                }));
                const [removed] = result.splice(dragIndex, 1);

                removed.isMoved = true;
                removed.finalPositionX = prevFrames.frames[hoverIndex].place.position.x;
                result.splice(hoverIndex, 0, removed);

                const { actions, frames } = moveFramesCalculatorV4({
                    movedFrames: result,
                    selectedFrames: prevFrames.frames,
                    stations,
                    settings,
                });

                const newActions = [...prevFrames.actions, ...actions];

                return {
                    actions: newActions,
                    frames,
                };
            });
        },
        [movedFrames.frames.length, setMovedFrames, settings, stations]
    );

    const showStorageSpace = partitionIndex === 0;

    return (
        <div className="hive-block-frames">
            {showStorageSpace ? (
                <div className="storage-space-container">
                    <div className="storage-space-content">
                        <span className="text">storage space</span>
                        <StorageSpaceArrow />
                    </div>
                </div>
            ) : (
                <span className="storage-space-placeholder" />
            )}
            {movedFrames.frames.map((frame, index) => (
                <Frame
                    key={`hb-${frame.slotId}-${frame.frameId}`}
                    frame={frame}
                    index={index}
                    moveFrame={moveFrame}
                    selectedFrames={selectedFrames}
                    partitionIndex={partitionIndex}
                />
            ))}
        </div>
    );
};

StepTwo.propTypes = {
    selectedFrames: PropTypes.arrayOf(
        PropTypes.shape({
            slotId: PropTypes.number.isRequired,
            frameId: PropTypes.string.isRequired,
        })
    ).isRequired,
    movedFrames: PropTypes.shape({
        frames: PropTypes.arrayOf(
            PropTypes.shape({
                slotId: PropTypes.number.isRequired,
                frameId: PropTypes.string.isRequired,
            })
        ).isRequired,
        actions: PropTypes.shape(),
    }),
    setMovedFrames: PropTypes.func.isRequired,
    settings: PropTypes.shape(),
};

export default StepTwo;
