import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/reusables/Icon';
import Tooltip from '@beewise/tooltip';
import './ActionIcon.scss';

const ActionIcon = ({
    name,
    onClick,
    icon,
    className,
    tooltipInfo,
    disabled,
    isActive,
    isShowTooltipOnDisabledState,
}) => {
    const shouldShowTooltip = isShowTooltipOnDisabledState ? disabled : tooltipInfo && !disabled;
    const Wrapper = shouldShowTooltip ? Tooltip : React.Fragment;
    const wrapperProps = tooltipInfo && shouldShowTooltip ? { content: tooltipInfo, position: 'top center' } : {};

    const handleClick = () => {
        if (disabled) return;
        onClick();
    };

    return (
        <Wrapper {...wrapperProps}>
            <div
                className={cn('action-icon', {
                    disabled,
                    active: isActive,
                })}
                role="presentation"
                onClick={handleClick}
            >
                <Icon icon={icon} className={className} />
                {name}
            </div>
        </Wrapper>
    );
};

ActionIcon.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    className: PropTypes.string,
    tooltipInfo: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    disabled: PropTypes.bool,
    isShowTooltipOnDisabledState: PropTypes.bool,
    isActive: PropTypes.bool,
};

export default ActionIcon;
