import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { loading } from '@beewise/react-utils';
import ProgressBar from '@beewise/progress-bar';
import constants from 'appConstants';
import { FETCH_DASHBOARD_GRID_DATA } from '../../actionTypes';

import './PoliciesWidget.scss';

const PoliciesWidget = ({ data }) => {
    const calculatedData = useMemo(() => {
        const calculations = data?.reduce(
            (acc, item) => {
                if (item.visualInspectionType?.toLowerCase?.() === constants.VISUAL_INSPECTION_TYPES.FULL) {
                    acc.scanOn += 1;
                }
                if (item.autoFeedEnabled) {
                    acc.feedOn += 1;
                }
                if (item.autoScanEnabled) {
                    acc.beeCountOn += 1;
                }
                return acc;
            },
            { scanOn: 0, beeCountOn: 0, feedOn: 0 }
        );
        const scanPercent = Math.floor((calculations.scanOn / (data?.length ?? 1)) * 100);
        const beeCountPercent = Math.floor((calculations.beeCountOn / (data?.length ?? 1)) * 100);
        const feedPercent = Math.floor((calculations.feedOn / (data?.length ?? 1)) * 100);

        return {
            ...calculations,
            scanPercent: Number.isNaN(scanPercent) ? 0 : scanPercent,
            beeCountPercent: Number.isNaN(beeCountPercent) ? 0 : beeCountPercent,
            feedPercent: Number.isNaN(feedPercent) ? 0 : feedPercent,
        };
    }, [data]);

    return (
        <div className="widget policies-widget">
            <div className="secondary-text">Beehomes policies</div>
            <div className="policies-widget-content">
                <div className="policies-widget-item">
                    <div className="policies-widget-item-text">Scanning</div>
                    <ProgressBar completed={calculatedData.scanPercent} />
                    <div className="policies-widget-item-numbers">
                        <div className="policies-widget-item-numbers-amount">{calculatedData.scanOn}</div>
                        <div className="policies-widget-item-numbers-percent secondary-text">
                            {calculatedData.scanPercent}%
                        </div>
                    </div>
                </div>
                <div className="policies-widget-item">
                    <div className="policies-widget-item-text">Bee count</div>
                    <ProgressBar completed={calculatedData.beeCountPercent} />
                    <div className="policies-widget-item-numbers">
                        <div className="policies-widget-item-numbers-amount">{calculatedData.beeCountOn}</div>
                        <div className="policies-widget-item-numbers-percent secondary-text">
                            {calculatedData.beeCountPercent}%
                        </div>
                    </div>
                </div>
                <div className="policies-widget-item">
                    <div className="policies-widget-item-text">Feeding</div>
                    <ProgressBar completed={calculatedData.feedPercent} />
                    <div className="policies-widget-item-numbers">
                        <div className="policies-widget-item-numbers-amount">{calculatedData.feedOn}</div>
                        <div className="policies-widget-item-numbers-percent secondary-text">
                            {calculatedData.feedPercent}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PoliciesWidget.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
};

export default loading(FETCH_DASHBOARD_GRID_DATA.default)(PoliciesWidget, 'policies-widget-wrapper');
