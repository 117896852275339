import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';
import PropTypes from 'prop-types';

const TooltipMulti = ({ componentHover, componentClick, trigger, position }) => {
    const [action, handleAction] = useState(null);
    const component = { hover: componentHover, click: componentClick };

    const handleHover = useCallback(() => {
        if (action !== 'click') {
            handleAction('hover');
        }
    }, [action]);

    const handleClick = useCallback(e => {
        e.stopPropagation();
        handleAction('click');
    }, []);

    const handleTooltipRemove = useCallback(() => {
        handleAction(null);
    }, []);

    const handleLeave = useCallback(() => {
        if (action === 'hover') {
            handleTooltipRemove();
        }
    }, [action, handleTooltipRemove]);

    useEffect(() => {
        if (action) {
            window.addEventListener('scroll', handleTooltipRemove, true);
        } else {
            window.removeEventListener('scroll', handleTooltipRemove, true);
        }
    }, [action, handleTooltipRemove]);

    const customComponent =
        component[action] && action === 'click'
            ? React.cloneElement(component[action], {
                  handleClose: handleTooltipRemove,
              })
            : component[action];

    return (
        <div
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
            onClick={handleClick}
            aria-hidden="true"
            className="tooltip-wrapper"
        >
            <Tooltip
                open={!!action}
                isHoverable
                position={position}
                className={cx('tooltip', 'tooltip-alert')}
                component={customComponent}
                on={action}
                inverted={action === 'hover'}
                style={action !== 'hover' ? { padding: 0 } : {}}
            >
                {trigger}
            </Tooltip>
        </div>
    );
};

TooltipMulti.propTypes = {
    componentClick: PropTypes.node.isRequired,
    componentHover: PropTypes.node.isRequired,
    trigger: PropTypes.node.isRequired,
    position: PropTypes.string,
};

export default TooltipMulti;
