const gridStyles = {
    height: '95%',
    width: '100%',
    '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '14px' },
    '& .MuiDataGrid-withBorderColor': { border: 0 },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: '12px',
        fontWeight: '500',
    },
    '& .MuiDataGrid-cellContent': { padding: '16px', fontWeight: '100' },
    '& .MuiCheckbox-root': {
        padding: 0,
    },
    '& .Mui-checked.MuiCheckbox-root': {
        color: '#feba12',
    },
    '@media print': {
        '.MuiDataGrid-main': {
            zoom: '0.5',
            width: 'fit-content',
            fontSize: '14px',
            height: 'fit-content',
            overflow: 'visible',
            '.MuiDataGrid-columnHeader': {
                minWidth: '100px',
                width: '100px',
                maxWidth: '100px',
            },
        },
    },
};

export default gridStyles;
