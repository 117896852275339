import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import constants from 'appConstants';
import { getBhomeDefinedHives, getCurrentBhome } from 'components/views/BeeHome/selectors';
import HistorySlider from 'components/reusables/HistorySlider';
import useMarkedHives from 'utils/useMarkedHives';
import SyrupTank from './SyrupTank';
import CheckboxItem from './CheckboxItem';
import Station from './Station';
import ContentLegend from './Legend';
import { FRAME_OPTIONS } from './constants';
import { getFrameShift } from './utils';

import './HivesV2.scss';

const DEFAULT_SELECT_OPTIONS = [{ label: FRAME_OPTIONS.FRAME_CONTENT }, { label: FRAME_OPTIONS.BEES }];

const getSelectOptions = isPublicView => {
    if (isPublicView) {
        return DEFAULT_SELECT_OPTIONS;
    }
    return [...DEFAULT_SELECT_OPTIONS, { label: FRAME_OPTIONS.THERMAL }];
};

const STATIONS = [constants.STATIONS.A, constants.STATIONS.B];

const Hives = ({ setIsHistoryView, historySliderRef, handleOpenActionPanel, isPublicView }) => {
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);

    const [selectedFrameOptions, setSelectedFrameOptions] = useState({
        [FRAME_OPTIONS.FRAME_CONTENT]: true,
        [FRAME_OPTIONS.THERMAL]: !isPublicView,
        [FRAME_OPTIONS.BEES]: true,
    });

    const [beeCountFrameShift, setBeeCountFrameShift] = useState(getFrameShift(window.innerWidth));

    useEffect(() => {
        if (!selectedFrameOptions[FRAME_OPTIONS.BEES]) {
            return;
        }

        const handleResize = () => {
            setBeeCountFrameShift(getFrameShift(window.innerWidth));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [selectedFrameOptions]);

    const markedHives = useMarkedHives();

    const selectOptions = getSelectOptions(isPublicView);

    return (
        <div className="hives-v2 hive-stations">
            <div className="hive-stations-header">
                {selectOptions.map(option => (
                    <CheckboxItem
                        key={option.label}
                        label={option.label}
                        isSelected={selectedFrameOptions[option.label]}
                        handleCheckboxClick={() =>
                            setSelectedFrameOptions(prevState => ({
                                ...prevState,
                                [option.label]: !prevState[option.label],
                            }))
                        }
                    />
                ))}
            </div>
            <div className="hive-stations-content">
                <div className="hive-station-items">
                    {STATIONS.map(station => (
                        <Station
                            key={station}
                            hives={hives[station]}
                            markedHives={markedHives}
                            selectedFrameOptions={selectedFrameOptions}
                            beeCountFrameShift={beeCountFrameShift}
                        />
                    ))}
                </div>
                <SyrupTank syrupLevel={currentBhome?.sensors?.syrupLevel} />
            </div>
            <ContentLegend isPublicView={isPublicView} />
            {!isPublicView && (
                <HistorySlider
                    ref={historySliderRef}
                    bhomeId={currentBhome?.id}
                    setIsHistoryView={setIsHistoryView}
                    handleOpenActionPanel={handleOpenActionPanel}
                />
            )}
        </div>
    );
};

Hives.propTypes = {
    setIsHistoryView: PropTypes.func.isRequired,
    historySliderRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
    handleOpenActionPanel: PropTypes.func,
    isPublicView: PropTypes.bool,
};

export default memo(Hives);
