import Tooltip from '@beewise/tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import Button from '@beewise/button';

const TooltipWrapper = ({ children, showTooltip, content }) => {
    const Wrapper = showTooltip ? Tooltip : React.Fragment;
    const wrapperProps = showTooltip ? { content, position: 'bottom right' } : {};

    return (
        <Wrapper {...wrapperProps}>
            <div>{children}</div>
        </Wrapper>
    );
};

TooltipWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    showTooltip: PropTypes.bool,
    content: PropTypes.node,
};

const FeedButton = props => (
    <TooltipWrapper content="Select Beehomes to start feeding" showTooltip={props.disabled}>
        <Button className="primary-orange" {...props}>
            FEED{' '}
            <TooltipWrapper
                showTooltip={!props.disabled}
                content={
                    <div className="feed-button-tooltip">
                        Feeding request will be executed by
                        <br /> <span>end of day tomorrow</span>
                    </div>
                }
                position="bottom right"
            >
                <FontAwesomeIcon icon={faCircleInfo} />
            </TooltipWrapper>
        </Button>
    </TooltipWrapper>
);

FeedButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default FeedButton;
