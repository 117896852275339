import React from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { isFeedingFrame, isScanningFrame } from 'components/views/BeeHome/selectors';
import { getIsFrameChecked, getIsFrameSelectable } from 'components/reusables/RightPanel/components/HiveBlock/utils';
import { getActionMode, getUnsavedActivities } from 'components/reusables/RightPanel/selectors';
import Checkbox from '@beewise/checkbox-field';
import {
    addUnsavedActivities,
    removeUnsavedActivities,
} from 'components/reusables/RightPanel/actions/activity.actions';
import { getTimestampInSeconds } from 'utils/date';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTriangleExclamation, faUtensils } from '@fortawesome/pro-solid-svg-icons';
import useGetFrameStationHiveAndFrameNumbers from 'utils/useGetFrameStationHiveAndFrameNumbers';

const StationFrameStatus = ({ frame, isInsideMarkedHive }) => {
    const dispatch = useDispatch();
    const actionMode = useSelector(getActionMode);
    const unsavedActivities = useSelector(getUnsavedActivities, arrayOfObjectsShallowEqual);
    const canBeSelected = getIsFrameSelectable(frame, actionMode, isInsideMarkedHive);
    const isScanningCurrentFrame = useSelector(isScanningFrame(frame), shallowEqual);
    const isFeedingCurrentFrame = useSelector(isFeedingFrame(frame), shallowEqual);
    const frameInfo = useGetFrameStationHiveAndFrameNumbers(frame);

    const showSwarmAlertIcon =
        frame.type === constants.FRAME_TYPES.COMB_FRAME && (frame?.sideA?.swarmData || frame?.sideB?.swarmData);

    const isChecked = getIsFrameChecked(frame, unsavedActivities, actionMode);

    const isCheckboxShown =
        canBeSelected &&
        ((actionMode !== constants.COMMANDS.SCAN && actionMode !== constants.COMMANDS.COUNT_BEES) ||
            !isScanningCurrentFrame) &&
        (actionMode !== constants.COMMANDS.FILL_FEEDER || !isFeedingCurrentFrame);

    const handleFrameCheck = e => {
        const {
            target: { value },
        } = e;
        e.stopPropagation();

        if (actionMode === constants.COMMANDS.SCAN || actionMode === constants.COMMANDS.COUNT_BEES) {
            const actionBody = [
                {
                    rfid: frame.rfid,
                    slotIndex: frame.slotId,
                    lastScanned: getTimestampInSeconds(frame.lastUpdated),
                    place: frame?.place,
                },
            ];
            if (value) {
                dispatch(addUnsavedActivities(actionBody));
            } else {
                dispatch(removeUnsavedActivities(actionBody));
            }
        } else if (actionMode === constants.COMMANDS.FILL_FEEDER) {
            const actionBody = [
                {
                    rfid: frame.rfid,
                    slotIndex: frame.slotId,
                    place: frame?.place,
                    frameNumber: frameInfo?.frameNumber,
                    hiveNumber: frameInfo?.hiveNumber,
                    station: frameInfo?.station,
                },
            ];
            if (value) {
                dispatch(addUnsavedActivities(actionBody));
            } else {
                dispatch(removeUnsavedActivities(actionBody));
            }
        }
    };

    return (
        <div className="station-frame-item-wrapper-status">
            {showSwarmAlertIcon && (
                <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className={cx('station-frame-status-icon alerted', {
                        'with-checkbox': isCheckboxShown,
                    })}
                />
            )}
            {isScanningCurrentFrame && (
                <FontAwesomeIcon
                    icon={faCamera}
                    className={cx('station-frame-status-icon', {
                        'with-checkbox': isCheckboxShown,
                    })}
                />
            )}
            {isFeedingCurrentFrame && (
                <FontAwesomeIcon
                    icon={faUtensils}
                    className={cx('station-frame-status-icon', {
                        'with-checkbox': isCheckboxShown,
                    })}
                />
            )}
            {isCheckboxShown && (
                <Checkbox className="station-frame-checkbox" onChange={handleFrameCheck} checked={!!isChecked} />
            )}
        </div>
    );
};

StationFrameStatus.propTypes = {
    frame: PropTypes.shape(),
    isInsideMarkedHive: PropTypes.bool,
};

export default StationFrameStatus;
