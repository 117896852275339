import { chain, capitalize } from 'lodash-es';

const groupBhomesByEntity = ({ idKey, nameKey, bhomes }) => {
    const filteredBhomes = bhomes.filter(bhome => bhome[idKey]);
    return chain(filteredBhomes)
        .groupBy(idKey)
        .map((value, key) => ({
            id: key,
            title: value?.[0][nameKey],
        }))
        .sortBy('title')
        .value();
};
export const OTHER_LOCATIONS = 'other locations';

export const ENTITY_LABELS = {
    RANCHES: 'ranch',
    YARDS: 'yard',
    UNASSIGNED: OTHER_LOCATIONS,
};

const mapItemsToVirtList = ({ virtualizedListItems, entityItems, groupName }) => {
    virtualizedListItems.push({
        id: groupName,
        title: capitalize(groupName),
        items: entityItems,
    });
};

export const groupVirtualizedListData = bhomes => {
    const unassignedBhomes = bhomes.some(bhome => !bhome.ranch_id && !bhome.yard_id);
    const ranches = groupBhomesByEntity({ idKey: 'ranch_id', nameKey: 'ranch_name', bhomes });
    const yards = groupBhomesByEntity({ idKey: 'yard_id', nameKey: 'yard_name', bhomes });
    const virtualizedListItems = [];
    if (ranches.length) {
        mapItemsToVirtList({
            virtualizedListItems,
            entityItems: ranches,
            groupName: ENTITY_LABELS.RANCHES,
        });
    }
    if (yards.length) {
        mapItemsToVirtList({
            virtualizedListItems,
            entityItems: yards,
            groupName: ENTITY_LABELS.YARDS,
        });
    }
    if (unassignedBhomes) {
        mapItemsToVirtList({
            virtualizedListItems,
            groupName: ENTITY_LABELS.UNASSIGNED,
        });
    }
    return virtualizedListItems;
};

export const generateSelectOptions = items =>
    items.map(item => ({
        value: item.id,
        label: item.title,
    }));
