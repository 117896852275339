import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getCurrentBhome } from '../../selectors';
import { clearYardWidgetData, fetchYardStatsWidgetData } from '../../actions';
import { getYards } from '../../../Dashboard/selectors';

const YardStats = () => {
    const dispatch = useDispatch();
    const currentBhome = useSelector(getCurrentBhome);
    const yards = useSelector(getYards, arrayOfObjectsShallowEqual);
    const yardWidgetData = useSelector(state => state.beehome.yardWidgetData, shallowEqual);

    const currentBhomeYard = useMemo(
        () => yards.find(yard => yard.bhomes.includes(currentBhome?.id)),
        [currentBhome?.id, yards]
    );

    useEffect(() => {
        if (currentBhomeYard?.id) {
            dispatch(fetchYardStatsWidgetData(currentBhomeYard.id));
        } else {
            dispatch(clearYardWidgetData());
        }
    }, [dispatch, currentBhomeYard?.id]);

    return (
        <div className="beehome-yard-status-widget">
            <h4>Yard Stats</h4>
            <div className="beehome-yard-status-widget-text">average Brood {yardWidgetData?.brood || '-'}</div>
            <div className="beehome-yard-status-widget-text">
                average Population {yardWidgetData?.population || '-'}
            </div>
        </div>
    );
};

export default YardStats;
