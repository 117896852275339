import React from 'react';
import PropTypes from 'prop-types';
import Hives from 'components/reusables/Hives';
import { ContentLegend, FrameLegend } from 'components/reusables/Hives/components/Legend';

const StepOne = ({ selectedHive, onHiveSelect }) => (
    <>
        <Hives isModalMode onHiveSelectModalMode={onHiveSelect} selectedHiveModalMode={selectedHive} />
        <div className="legend">
            <FrameLegend />
            <ContentLegend />
        </div>
    </>
);

StepOne.propTypes = {
    selectedHive: PropTypes.shape(),
    onHiveSelect: PropTypes.func.isRequired,
};

export default StepOne;
