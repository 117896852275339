import constants from 'appConstants';
import { inRange } from 'lodash-es';
import { UI_ENV } from '../config';

export const isProduction = () => UI_ENV === 'prod';

export const getFrameStation = slotIndex => (slotIndex >= constants.SLOTS_PER_BHOME_4_STATION ? 'B' : 'A');

export const isStaticOrDynamicPartition = frame =>
    frame?.type === constants.FRAME_TYPES.PARTITION || frame?.type === constants.FRAME_TYPES.STATIC_PARTITION;

export const getPlural = (amount, word) => (amount === 1 ? `1 ${word}` : `${amount} ${word}s`);

export const getCombFramesWithinHive = (frames, hive) =>
    frames.filter(
        frame =>
            frame?.place?.station === hive?.station &&
            inRange(frame?.place?.position?.x, hive?.from, hive?.to) &&
            frame?.type === constants.FRAME_TYPES.COMB_FRAME
    );

export const getHiveIndexForFrame = (hives, frame) =>
    hives.findIndex(
        hive => inRange(frame.place.position.x, hive.from, hive.to) && frame.place.station === hive.station
    );
