import dayjs from 'dayjs';
import { max } from 'lodash-es';
import constants from 'appConstants';
import { getHiveIndexForFrame } from 'utils';

export const FRAME_SIDES = ['sideA', 'sideB'];

export const SIDE = {
    A: 'A',
    B: 'B',
};

export const IMAGE_TYPES = {
    SCAN: 'scan',
    BEE_COUNT: 'beeCount',
};

export const formatTimestamp = timestamp => (timestamp ? dayjs(timestamp).format('D MMM') : 'N/A');

const getBeeCountTimestamp = frameSide => frameSide?.beeCount?.scanTimestamp;
const getScanTimestamp = frameSide => frameSide?.scanTimestamp;

export const getLatestFormattedTime = ({ frame, type }) => {
    const getTimestamp = type === IMAGE_TYPES.SCAN ? getScanTimestamp : getBeeCountTimestamp;
    const maxScanTimestamp = max(frame && FRAME_SIDES?.flatMap(side => getTimestamp(frame[side])));
    return formatTimestamp(maxScanTimestamp);
};

export const DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right',
};

export const getCurrentHiveFrames = (hives, inspectedFrame) => {
    const hiveEntries = Object.values(hives).flat();
    const hiveIndex = getHiveIndexForFrame(hiveEntries, inspectedFrame);
    const hiveWithFrames = hiveEntries[hiveIndex];

    return hiveWithFrames ? hiveWithFrames.frames.filter(({ type }) => type === constants.FRAME_TYPES.COMB_FRAME) : [];
};
