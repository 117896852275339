import React from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import './SyrupTank.scss';

const MIN_HEIGHT = 4.5;

const SyrupTank = ({ syrupLevel }) => (
    <div className="syrup-container" style={{ '--height': `${syrupLevel || MIN_HEIGHT}%` }}>
        <div
            className="syrup-level wave"
            style={{
                height: `${syrupLevel || MIN_HEIGHT}%`,
            }}
        />
        <span className="syrup-text">
            {syrupLevel ? Math.round((syrupLevel / 100) * constants.SYRUP_GALLONS) : 0}gal
        </span>
    </div>
);

SyrupTank.propTypes = {
    syrupLevel: PropTypes.number,
};

export default SyrupTank;
