export const getIsAlertPaneShown = state => state.app.isAlertPaneShown;
export const getIsActivityShown = state => state.ui.showActivity;
export const getIsMapShown = state => state.ui.showMap;

// TODO: remove after removing all usages
export const getIsInitialRender = state => state.ui.initialRender;
export const getIsRightPanelShown = state => state.ui.showRightPanel;
export const getIsBlogPanelShown = state => state.ui.showBlogPanel;
export const getAppSettings = state => state.app.appSettings;
export const getUser = state => state.app.user;
export const getIsLimitedViewCompany = state => state.settings.company?.data?.isLimitedView;
