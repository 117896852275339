import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faSync } from '@fortawesome/pro-light-svg-icons';
import { isEmpty } from 'lodash-es';
import constants from 'appConstants';
import { format } from 'timeago.js';
import { getActivityIcon, getActivityDescription, getActivityData } from '../utils';

import './Activities.scss';

const getCurrentActivityTitle = command => {
    switch (command) {
        case constants.COMMANDS.SCAN:
            return 'Scanning...';
        case constants.COMMANDS.COUNT_BEES:
            return 'Counting bees...';
        case constants.COMMANDS.FILL_FEEDER:
            return 'Feeding...';
        case constants.COMMANDS.MOVE_FRAMES:
            return 'Moving...';
        case constants.COMMANDS.CONTRACT_HIVE:
            return 'Hive contracting...';
        case constants.COMMANDS.EXPAND_HIVE:
            return 'Hive expanding...';
        case constants.COMMANDS.SHIFT_HIVE:
            return 'Hive shifting...';
        case constants.COMMANDS.BALANCE_HIVES:
            return 'Hives balancing...';
        case constants.COMMANDS.THERMAL_SCAN_BUNDLE:
            return 'Hives thermal scan processing...';
        default:
            return 'Executing unknown action...';
    }
};

const CurrentActivity = ({ currentActivity, frame, handleGoInsideHiveOrFrame }) => {
    const isTimeExceed =
        ((new Date() - new Date(currentActivity.sent_at)) / (60 * 1000)).toFixed(3) >
        getActivityData(currentActivity).time + 5;
    const activityDescription = useMemo(() => getActivityDescription(currentActivity), [currentActivity]);

    if (activityDescription && !isEmpty(frame) && frame.id !== activityDescription.frame) {
        return null;
    }

    return (
        <div className="current-activity">
            <div className="current-activity-header">
                <FontAwesomeIcon icon={getActivityIcon(currentActivity)} className="current-activity-header-icon" />
                <div className="current-activity-header-title">{getCurrentActivityTitle(currentActivity.command)}</div>
                <div className="current-activity-header-initiator">{currentActivity.user}</div>
                <FontAwesomeIcon icon={faSync} className="current-activity-header-spinner" />
            </div>
            {isTimeExceed && (
                <div className="current-activity-body">
                    It takes more than expected to complete the activity. Our technicians look into this issue.
                </div>
            )}
            <div
                role="presentation"
                className="current-activity-footer"
                onClick={handleGoInsideHiveOrFrame(activityDescription)}
            >
                <FontAwesomeIcon icon={faClock} className="current-activity-footer-icon" />
                <div className="current-activity-footer-source">{activityDescription?.text}</div> |
                <div className="current-activity-footer-time-ago">{format(currentActivity.sent_at, 'en-US')}</div>
            </div>
        </div>
    );
};

CurrentActivity.propTypes = {
    currentActivity: PropTypes.shape(),
    frame: PropTypes.shape(),
    handleGoInsideHiveOrFrame: PropTypes.func,
};

export default CurrentActivity;
