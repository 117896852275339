import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useToggle, shallowEqual, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Tooltip from '@beewise/tooltip';
import { getBeekeepersEntries } from 'components/reusables/Alerts/selectors';
import { fetchBhomeNotes } from 'components/views/BeeHome/actions';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import BeekeepersEntriesPane from './BeekeepersEntriesPane';
import AddBeekeeperEntryModal from './AddBeekeeperEntryModal';
import { EmptyPanePlaceholder } from '../utils';

const Log = ({ frame }) => {
    const dispatch = useDispatch();

    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const beekeepersEntries = useSelector(getBeekeepersEntries, arrayOfObjectsShallowEqual);

    const [isAddEntryModalShown, , showModal, closeModal] = useToggle(false);

    const [noteToEdit, setNoteToEdit] = useState({});

    const beekeepersEntriesToShow = useMemo(() => {
        if (!frame.frameId) {
            return beekeepersEntries;
        }
        return beekeepersEntries.filter(
            entry => entry.data?.frameId === frame.frameId || entry.frame_id === frame.frameId
        );
    }, [frame.frameId, beekeepersEntries]);

    const handleCloseModal = useCallback(() => {
        setNoteToEdit({});
        closeModal();
    }, [closeModal]);

    const handleEditNote = useCallback(
        note => () => {
            setNoteToEdit(note);
            showModal();
        },
        [showModal]
    );

    useEffect(() => {
        if (currentBhome?.id) {
            dispatch(fetchBhomeNotes(currentBhome.id));
        }
    }, [currentBhome.id, dispatch]);

    return (
        <div className="right-menu-block log">
            <div className="right-menu-block-header">
                <div className="right-menu-block-title">Beekeeper entries</div>
                <Tooltip text="Add entry" position="left center" mouseEnterDelay={0} mouseLeaveDelay={0} hideOnScroll>
                    <FontAwesomeIcon icon={faCirclePlus} className="add-beekeeper-entry" onClick={showModal} />
                </Tooltip>
            </div>
            <div className="right-menu-block-body">
                {beekeepersEntriesToShow.length ? (
                    <BeekeepersEntriesPane
                        beekeepersEntries={beekeepersEntriesToShow}
                        onEdit={handleEditNote}
                        inspectedFrame={frame}
                    />
                ) : (
                    <EmptyPanePlaceholder text="No log entries to display" />
                )}
            </div>
            {isAddEntryModalShown && (
                <AddBeekeeperEntryModal
                    isOpen={isAddEntryModalShown}
                    onClose={handleCloseModal}
                    inspectedFrame={frame}
                    noteToEdit={noteToEdit}
                    currentBhomeId={currentBhome.id}
                />
            )}
        </div>
    );
};

Log.propTypes = {
    frame: PropTypes.shape().isRequired,
};

export default Log;
