import React, { useState, memo, useMemo, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { isUndefined } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import usePrevious from '@beewise/react-utils/build/lib/previous';
import Tooltip from '@beewise/tooltip';
import { SelectField } from '@beewise/select-field';
import { getCurrentBhomes, getItemFilter, getLocationFilter } from 'components/views/BeeHome/selectors';
import PropTypes from 'prop-types';
import { fetchDashboardGridData } from 'components/views/Dashboard/actions';
import { SearchBox } from 'components/reusables/Map';
import { useNavigate, useParams } from 'react-router-dom';
import { setItemFilter, setLocationFilter } from 'components/views/BeeHome/actions';
import { toggleInitialRender, toggleMap } from 'actions';
import { getIsMapShown } from 'selectors';
import { generateSelectOptions, groupVirtualizedListData, OTHER_LOCATIONS } from './utils';
import BhomeItem from './components/BhomeItem';
import './LeftPanel.scss';
import { updateMapBounds } from '../Map/components/utils';

const tooltipText = (
    <div className="left-panel-header-tooltip-text">
        <p>Empty, bee, brood, and honey</p>
        <p>are Beehome frame averages</p>
    </div>
);

const LeftPanel = ({ currentBhome, map, updateYardAndCoordsInfo, setNewLocationType }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const bhomes = useSelector(getCurrentBhomes, arrayOfObjectsShallowEqual);
    const locationFilter = useSelector(getLocationFilter);
    const itemFilter = useSelector(getItemFilter);
    const previousItemFilter = usePrevious(itemFilter);
    const isMapShown = useSelector(getIsMapShown);
    const [currentBhomes, setCurrentBhomes] = useState([]);
    const leftPanelHeaderRef = useRef();

    const listItems = useMemo(() => groupVirtualizedListData(bhomes), [bhomes]);
    const locationOptions = useMemo(() => generateSelectOptions(listItems), [listItems]);
    const filteredItem = useMemo(() => listItems.find(item => item.id === locationFilter), [listItems, locationFilter]);
    const itemOptions = useMemo(() => {
        let items = [];
        if (filteredItem && filteredItem.id !== OTHER_LOCATIONS) {
            items = filteredItem.items;
        }
        return generateSelectOptions(items);
    }, [filteredItem]);

    useEffect(() => {
        dispatch(setItemFilter({ itemFilter: null }));
        dispatch(setLocationFilter({ locationFilter: null }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bhomes.length]);

    useEffect(() => {
        let currentBhomes;

        if (filteredItem?.id === OTHER_LOCATIONS) {
            currentBhomes = filteredItem?.items || [];
        } else {
            const currentItem = filteredItem?.items.find(item => item.id === itemFilter);
            currentBhomes = currentItem?.bhomes || [];
        }

        setCurrentBhomes(currentBhomes);

        if (previousItemFilter !== itemFilter && currentBhomes?.length) {
            if (!id) {
                navigate(`/beehome/${currentBhomes[0].id}`);
            } else {
                const bhome = currentBhomes.find(bhome => bhome.id === Number(id));
                if (bhome) {
                    navigate(`/beehome/${bhome.id}`);
                } else {
                    navigate(`/beehome/${currentBhomes[0].id}`);
                }
            }
        }
    }, [currentBhome, dispatch, filteredItem, id, itemFilter, navigate, previousItemFilter]);

    useEffect(() => {
        dispatch(fetchDashboardGridData({ offset: 0, limit: 0, type: '' }));
    }, [dispatch]);

    useEffect(() => {
        if (isMapShown) {
            updateMapBounds(
                map,
                bhomes?.map(item => ({
                    lat: item?.gps?.latitude ? Number(item?.gps?.latitude) : item.lat,
                    lng: item?.gps?.longitude ? Number(item?.gps?.longitude) : item.lng,
                }))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMapShown]);

    useEffect(() => {
        if (id) {
            const bhome = bhomes.find(bhome => bhome.id === Number(id));
            if (bhome) {
                const location = listItems.find(item =>
                    item.items?.some(item => {
                        if (item.bhomes) {
                            return item.bhomes?.some(b => b.id === Number(id));
                        } else {
                            return item.id === Number(id);
                        }
                    })
                );
                if (location) {
                    !locationFilter && dispatch(setLocationFilter({ locationFilter: location.id }));
                    const item = location?.items.find(item => item.bhomes?.some(b => b.id === Number(id)));
                    if (item) {
                        !itemFilter && dispatch(setItemFilter({ itemFilter: item.id }));
                    }
                }
            }
        }
    }, [bhomes, dispatch, id, itemFilter, listItems, locationFilter]);

    useEffect(() => {
        if (itemOptions?.length && !itemFilter) {
            dispatch(setItemFilter({ itemFilter: itemOptions[0]?.value }));
        }
    }, [itemOptions, itemFilter, dispatch, locationFilter]);

    const handlePinClick = useCallback(() => {
        dispatch(toggleMap(!isMapShown));
    }, [dispatch, isMapShown]);

    const handleOnPlacesChange = useCallback(
        e => {
            const lat = e?.[0]?.geometry?.location?.lat();
            const lng = e?.[0]?.geometry?.location?.lng();

            if (!isUndefined(lat) && !isUndefined(lng)) {
                map.setCenter({ lat, lng });
                map.setZoom(12);
            }
        },
        [map]
    );

    const handleItemClick = useCallback(
        bhome => {
            if (currentBhome?.id !== bhome.id) {
                navigate(`/beehome/${bhome.id}`);
            }
            dispatch(toggleInitialRender(false));
        },
        [currentBhome?.id, dispatch, navigate]
    );

    const handleSetLocationFilter = value => {
        dispatch(setLocationFilter({ locationFilter: value }));
        dispatch(setItemFilter({ itemFilter: null }));
        dispatch(toggleInitialRender(false));
    };

    const handleSetItemFilter = value => {
        dispatch(setItemFilter({ itemFilter: value }));
        dispatch(toggleInitialRender(false));
    };

    const renderRow = useCallback(
        ({ index, style }) => (
            <BhomeItem
                style={style}
                bhome={currentBhomes[index]}
                key={index}
                handleItemClick={handleItemClick}
                isActive={currentBhomes[index]?.id === currentBhome?.id}
                syrupLevel={currentBhomes[index]?.sensors?.syrupLevel}
            />
        ),
        [currentBhome?.id, currentBhomes, handleItemClick]
    );

    return (
        <aside className="left-panel">
            <div ref={leftPanelHeaderRef}>
                <div className="left-panel-header">
                    {/* TODO: remove after we move to new bhome view */}
                    {isMapShown && updateYardAndCoordsInfo && setNewLocationType ? (
                        <SearchBox
                            onPlacesChanged={handleOnPlacesChange}
                            updateYardAndCoordsInfo={updateYardAndCoordsInfo}
                            setNewLocationType={setNewLocationType}
                        />
                    ) : (
                        <div className="left-panel-header-block">
                            <p className="left-panel-header-block-title">Beehomes</p>
                            <Tooltip content={tooltipText} position="top center">
                                <FontAwesomeIcon icon={faCircleInfo} className="icon" />
                            </Tooltip>
                        </div>
                    )}
                    <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className={cx('left-panel-header-pin', {
                            'is-active': isMapShown,
                        })}
                        onClick={handlePinClick}
                    />
                </div>
                <div className="left-panel-header left-panel-filter">
                    <div className="left-panel-filter-wrapper">
                        <SelectField
                            className="left-panel-filter-block-dropdown"
                            options={locationOptions}
                            onChange={handleSetLocationFilter}
                            value={locationFilter}
                        />
                        {locationFilter !== OTHER_LOCATIONS && (
                            <SelectField
                                className="left-panel-filter-block-dropdown"
                                options={itemOptions}
                                onChange={handleSetItemFilter}
                                value={itemFilter}
                            />
                        )}
                    </div>
                </div>
            </div>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        className="left-panel-bhomes-list"
                        height={height - (leftPanelHeaderRef?.current?.offsetHeight || 0)}
                        itemCount={currentBhomes?.length}
                        itemSize={115}
                        width={width}
                    >
                        {renderRow}
                    </List>
                )}
            </AutoSizer>
        </aside>
    );
};

LeftPanel.propTypes = {
    currentBhome: PropTypes.shape().isRequired,
    map: PropTypes.shape(),
    updateYardAndCoordsInfo: PropTypes.func,
    setNewLocationType: PropTypes.func,
};

export default memo(LeftPanel);
