import sortAlerts from 'utils/sortAlerts';
import constants from 'appConstants';
import { ON_RECEIVE_NEW_ALERT } from 'actionTypes';
import { FETCH_COMPANY_ALERTS, UPDATE_COMPANY_ALERTS } from '../actionTypes';

const alerts = (state = [], action) => {
    switch (action.type) {
        case FETCH_COMPANY_ALERTS.success:
            return sortAlerts(action.data.alerts);
        case ON_RECEIVE_NEW_ALERT:
            return sortAlerts([...state, action.payload]);
        case UPDATE_COMPANY_ALERTS.success:
            const alertsMap = new Map(action.data.alerts.map(alert => [alert.id, alert]));

            const updatedState = state.reduce((acc, alert) => {
                const updatedAlert = alertsMap.get(alert.id) || alert;

                if (
                    updatedAlert.status !== constants.ALERT_STATUSES.REMOVED &&
                    updatedAlert.status !== constants.ALERT_STATUSES.READ
                ) {
                    acc.push(updatedAlert);
                }

                return acc;
            }, []);

            return sortAlerts(updatedState);
        default:
            return state;
    }
};

export default alerts;
