import React from 'react';
import { EmptyChart } from '@beewise/chart';

const HoneyChart = () => (
    <div className="card">
        <div className="card-title honey">
            <div className="card-title-round honey" />
            No honey
        </div>
        <div className="card-body">
            <EmptyChart />
        </div>
    </div>
);

export default HoneyChart;
