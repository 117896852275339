import { faCalendarCheck, faTruck } from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';

export const GAP = 12;

export const PANEL_WIDTH = 395;

export const CANCELED_TITLE = 'Canceled';

export const HEADERS = [
    { name: 'Queue', isCancelable: true, header: true },
    { name: 'History', header: true },
];

export const HEADER_HEIGHT = 14;
export const ACTION_ITEM_HEIGHT = 60;
export const ACTION_ITEM_HEIGHT_LARGE = 90;

export const FRAME_COMMANDS = [constants.COMMANDS.FILL_FEEDER, constants.COMMANDS.SCAN, constants.COMMANDS.COUNT_BEES];

export const ACTIONS = {
    [constants.COMMANDS.COUNT_BEES]: {
        label: 'Bee scan',
        icon: 'bordered-bee',
    },
    [constants.COMMANDS.SCAN]: {
        label: 'Scan',
        icon: 'bordered-box',
    },
    [constants.COMMANDS.FILL_FEEDER]: {
        label: 'Feed',
        icon: 'feed',
    },
    [constants.COMMANDS.MOVE_TO_VISIT_POSITION]: {
        label: 'Visit',
        icon: faCalendarCheck,
    },
    [constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION]: {
        label: 'Transport',
        icon: faTruck,
    },
};

export const ALL_ACTIONS = {
    value: null,
    label: 'All actions',
};

export const FILTER_OPTIONS = [ALL_ACTIONS, ...Object.entries(ACTIONS).map(([value, { label }]) => ({ value, label }))];

export const formatToISODate = datetime => {
    const date = new Date(datetime);
    return date.toISOString().slice(0, 10);
};

export const isHighlighted = (item, historyDate) =>
    item.sentAt && formatToISODate(item.sentAt) === historyDate && item.status !== constants.MESSAGE_STATUS.CANCELED;
