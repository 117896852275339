import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '@beewise/button';

const Menu = ({ handleTabChange, SETTINGS_TABS, settingTab, showTeamTab, isLimitedView }) => (
    <div className="settings-menu">
        <Button
            className={cx('settings-menu-button', {
                'settings-menu-button-active': settingTab === SETTINGS_TABS.PROFILE,
            })}
            onClick={handleTabChange(SETTINGS_TABS.PROFILE)}
        >
            Profile
        </Button>
        {!isLimitedView && (
            <>
                <Button
                    className={cx('settings-menu-button', {
                        'settings-menu-button-active': settingTab === SETTINGS_TABS.ALERTS,
                    })}
                    onClick={handleTabChange(SETTINGS_TABS.ALERTS)}
                >
                    Alerts
                </Button>
                <Button
                    className={cx('settings-menu-button', {
                        'settings-menu-button-active': settingTab === SETTINGS_TABS.BEEHOMES_SETTINGS,
                    })}
                    onClick={handleTabChange(SETTINGS_TABS.BEEHOMES_SETTINGS)}
                >
                    Preferences
                </Button>
            </>
        )}
        {showTeamTab && (
            <Button
                className={cx('settings-menu-button', {
                    'settings-menu-button-active': settingTab === SETTINGS_TABS.TEAM,
                })}
                onClick={handleTabChange(SETTINGS_TABS.TEAM)}
            >
                Team
            </Button>
        )}
        {!isLimitedView && (
            <Button
                className={cx('settings-menu-button', {
                    'settings-menu-button-active': settingTab === SETTINGS_TABS.REGIONS,
                })}
                onClick={handleTabChange(SETTINGS_TABS.REGIONS)}
            >
                Regions
            </Button>
        )}
    </div>
);

Menu.propTypes = {
    handleTabChange: PropTypes.func.isRequired,
    SETTINGS_TABS: PropTypes.shape().isRequired,
    settingTab: PropTypes.string.isRequired,
    showTeamTab: PropTypes.bool,
    isLimitedView: PropTypes.bool,
};

export default Menu;
