import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCamera } from '@fortawesome/pro-light-svg-icons';
import { getAppSettings } from 'selectors';
import LastActionStatus from 'components/reusables/LastActionStatus';
import { getInspectedHive } from 'components/views/Bhome/selectors';
import useLoadingEffect from 'components/views/Bhome/hooks/useLoadingEffect';
import PanelItem from '../components/PanelItem';
import { findHiveById, getTotalAmountOfFrame } from './utils';
import './HivePanel.scss';
import { getBhomeDefinedHives } from '../../../../../BeeHome/selectors';

const HivePanel = ({ onClose, isPublicView }) => {
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);
    const inspectedHive = useSelector(getInspectedHive);
    const appSettings = useSelector(getAppSettings, shallowEqual);
    const clickedHive = findHiveById(hives, inspectedHive);
    const isLoading = useLoadingEffect(inspectedHive);

    const hiveData =
        clickedHive?.hive &&
        getTotalAmountOfFrame({ hive: clickedHive?.hive, appSettings, calculateFullyScannedOnly: false });

    return (
        <div className="right-panel-content hive-panel">
            {!isLoading && (
                <>
                    <div className="hive-panel-header">
                        <div className="hive-panel-header-top">
                            <div className="hive-panel-header-top-left">
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className="hive-panel-header-top-left-icon"
                                    onClick={onClose}
                                />
                                <div className="hive-panel-header-top-left-hive">
                                    Hive {clickedHive?.stationName.toLocaleUpperCase()}
                                    {Number(clickedHive?.index) + 1}
                                </div>
                            </div>
                            <div className="hive-panel-header-top-right">
                                {!!hiveData?.totalAmountOfEmptyBroodHives && (
                                    <Icon type="LarvaeMissing" className="hive-panel-header-top-right-icon" />
                                )}
                            </div>
                        </div>
                        {!isPublicView && (
                            <div className="hive-panel-header-bottom">
                                <LastActionStatus
                                    icon="feed"
                                    name="Last feeding"
                                    date={clickedHive?.latestFeedTimestamp}
                                />
                                <LastActionStatus
                                    icon={faCamera}
                                    name="Last scan"
                                    date={clickedHive?.latestScanTimestamp}
                                />
                            </div>
                        )}
                    </div>
                    <div className="hive-panel-block">
                        <PanelItem iconType="Cell" label="Empty frames" data={hiveData?.totalEmpty} />
                        <PanelItem iconType="BeeSimple" label="Bee frames" data={hiveData?.totalPopulation} />
                        <PanelItem iconType="Brood" label="Brood frames" data={hiveData?.totalBrood} />
                        <PanelItem iconType="Honey" label="Honey frames" data={hiveData?.totalHoney} />
                    </div>
                </>
            )}
        </div>
    );
};

HivePanel.propTypes = {
    onClose: PropTypes.func,
    isPublicView: PropTypes.bool,
};

export default HivePanel;
