import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentBhomeId } from '../../selectors';
import { fetchBhomeStatsWidgetData } from '../../actions';

const BeehomeStats = () => {
    const dispatch = useDispatch();
    const currentBhomeId = useSelector(getCurrentBhomeId);
    const bhomeWidgetData = useSelector(state => state.beehome.bhomeWidgetData, shallowEqual);

    useEffect(() => {
        if (currentBhomeId) {
            dispatch(fetchBhomeStatsWidgetData(currentBhomeId));
        }
    }, [currentBhomeId, dispatch]);

    return (
        <div className="beehome-yard-status-widget">
            <h4>Beehome Stats</h4>
            <div className="beehome-yard-status-widget-text">
                average Brood {bhomeWidgetData?.averageBrood ? bhomeWidgetData?.averageBrood.toFixed(1) : '-'}
            </div>
            <div className="beehome-yard-status-widget-text">
                average Population{' '}
                {bhomeWidgetData?.averagePopulation ? bhomeWidgetData?.averagePopulation.toFixed(1) : '-'}
            </div>
        </div>
    );
};

export default BeehomeStats;
