import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import ImagePlaceholder from 'components/reusables/ImagePlaceholder';
import Video from 'components/reusables/Video';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toggleHallwayStream, setHallwayVideoUrl } from 'components/reusables/RightPanel/actions/activity.actions';
import Button from '@beewise/button';

const KinesisStream = ({ bhome, toggleModal, isOpen }) => {
    const dispatch = useDispatch();
    const stream = useSelector(state => state.activity.hallwayVideo, shallowEqual);

    const [showHallwayStream, toggleShowHallwayStream] = useState(false);

    useEffect(() => {
        toggleShowHallwayStream(!!stream?.url);
    }, [stream?.url]);

    const handleStreamClose = useCallback(() => {
        dispatch(toggleHallwayStream(bhome?.id, 'OFF'));
        toggleShowHallwayStream(false);
        toggleModal();
    }, [bhome?.id, dispatch, toggleModal]);

    const handleStreamOpen = useCallback(() => {
        toggleShowHallwayStream(true);
        dispatch(
            toggleHallwayStream(bhome?.id, 'ON', data => {
                dispatch(setHallwayVideoUrl(data));
            })
        );
    }, [dispatch, bhome?.id]);

    useEffect(() => {
        if (isOpen) {
            handleStreamOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const header = useMemo(
        () => (
            <div>
                <span>Hallway Video</span>
                {!showHallwayStream && (
                    <Button className="restart" onClick={handleStreamOpen}>
                        Restart
                    </Button>
                )}
            </div>
        ),
        [handleStreamOpen, showHallwayStream]
    );

    return (
        <Modal className="kinesis-video" isOpen={isOpen} header={header} onClose={handleStreamClose}>
            <div className="live-feed">
                {showHallwayStream && stream?.url ? (
                    <Video className="stream" url={stream.url} />
                ) : (
                    <ImagePlaceholder
                        text={
                            // eslint-disable-next-line no-nested-ternary
                            stream?.error
                                ? `Error loading stream: ${JSON.stringify(stream.error)}`
                                : showHallwayStream
                                  ? 'Loading the stream...'
                                  : 'Camera is off'
                        }
                        className="stream-placeholder"
                    />
                )}
            </div>
        </Modal>
    );
};

KinesisStream.propTypes = {
    bhome: PropTypes.shape({
        id: PropTypes.number,
        settings: PropTypes.shape(),
    }),
    toggleModal: PropTypes.func,
    isOpen: PropTypes.bool,
};

export default KinesisStream;
