import {
    FETCH_ALL_BHOMES,
    FETCH_ALL_COMPANIES,
    UPDATE_ADMIN_COMPANY,
    UPDATE_ADMIN_COMPANY_BY_BHOME_ID,
} from '../actionTypes';

export const fetchAllCompanies = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_COMPANIES.default,
        url: '/companies',
    },
});

export const fetchAllBhomes = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES.default,
        url: '/bhomes/get-all',
    },
});

export const fetchAdminCompanyUpdate = (companyId, resolver) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_ADMIN_COMPANY.default,
        method: 'POST',
        url: '/users/admin',
        body: {
            companyId,
        },
        resolver,
    },
});

export const fetchAdminCompanyUpdateByBhomeId = (bhomeId, resolver) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_ADMIN_COMPANY_BY_BHOME_ID.default,
        method: 'POST',
        url: '/users/admin/bhome',
        body: {
            bhomeId,
        },
        resolver,
    },
});
