import constants from 'appConstants';
import { chain, capitalize } from 'lodash-es';
import dayjs from 'dayjs';
import { getActivityData } from 'components/reusables/RightPanel/components/utils';

export const broodQualityAlerts = [
    constants.ALERT_TYPES.CHALK_BROOD,
    constants.ALERT_TYPES.FOUL_BROOD,
    constants.ALERT_TYPES.MULTIPLE_SPOTTY_BROOD,
    constants.ALERT_TYPES.SPOTTY_BROOD,
];
export const highPriorityAlerts = [
    constants.ALERT_TYPES.QUEEN_CELLS,
    constants.ALERT_TYPES.QUEEN_STATUS,
    constants.ALERT_PRIORITY.HIGH_PRIORITY,
    ...broodQualityAlerts.filter(alert => alert !== constants.ALERT_TYPES.SPOTTY_BROOD),
];
export const mediumPriorityAlerts = [constants.ALERT_PRIORITY.MEDIUM_PRIORITY, constants.ALERT_TYPES.SPOTTY_BROOD];
export const lowPriorityAlerts = [constants.ALERT_PRIORITY.LOW_PRIORITY];
export const userAlerts = [
    constants.ALERT_PRIORITY.HIGH_PRIORITY,
    constants.ALERT_PRIORITY.MEDIUM_PRIORITY,
    constants.ALERT_PRIORITY.LOW_PRIORITY,
];

export const getAlertTitle = (alert, isBeekeeperEntry) => {
    if (isBeekeeperEntry) {
        return 'Beekeeper note';
    }
    if (alert.data.title) {
        return alert.data.title;
    }
    switch (alert.type) {
        case constants.ALERT_TYPES.QUEEN_CELLS:
            return 'Queen cell detected';
        case constants.ALERT_TYPES.SYRUP_LEVEL:
            return 'Fill syrup container';
        case constants.ALERT_TYPES.QUEEN_STATUS:
            return 'Queen status';
        case constants.ALERT_TYPES.SPOTTY_BROOD:
        case constants.ALERT_TYPES.FOUL_BROOD:
        case constants.ALERT_TYPES.CHALK_BROOD:
        case constants.ALERT_TYPES.MULTIPLE_SPOTTY_BROOD:
            return 'Brood quality';
        case constants.ALERT_TYPES.HONEY_LEVEL:
            return 'Honey tank is full';
        case constants.ALERT_PRIORITY.LOW_PRIORITY:
        case constants.ALERT_PRIORITY.MEDIUM_PRIORITY:
        case constants.ALERT_PRIORITY.HIGH_PRIORITY:
            return `Note from ${alert.data.author || 'somebody'}`;
        case constants.ALERT_TYPES.ACTIVITY_FAILED:
            return `${getActivityData(alert?.data).name} failed`;
        default:
            if (alert.level) {
                return `${capitalize(alert.level)} level alert`;
            }
            return 'Unknown alert';
    }
};

export const getAlertDescription = alert => {
    if (alert.data.description) {
        return alert.data.description;
    }

    switch (alert.type) {
        case constants.ALERT_TYPES.QUEEN_CELLS:
            return 'The system detected a queen cell on the frame.';
        case constants.ALERT_TYPES.QUEEN_STATUS:
            return 'The system detected patterns of a queenless hive';
        case constants.ALERT_TYPES.SYRUP_LEVEL:
            return 'The system has detected a low level of syrup in the bhome';
        case constants.ALERT_TYPES.HONEY_LEVEL:
            return 'Harvesting activities will not be executed and remain in queue until emptying a tank';
        case constants.ALERT_TYPES.SPOTTY_BROOD:
        case constants.ALERT_TYPES.FOUL_BROOD:
        case constants.ALERT_TYPES.CHALK_BROOD:
        case constants.ALERT_TYPES.MULTIPLE_SPOTTY_BROOD:
            return capitalize(`${alert.type.replaceAll('_', ' ').toLowerCase()} pattern was detected`);
        case constants.ALERT_TYPES.ACTIVITY_FAILED:
            return `The ${
                getActivityData(alert?.data).name
            } activity couldn't complete. Please review and resend it if required.`;
        default:
            return 'The system detected a unknown problem on the frame.';
    }
};

export const getAlertSource = (alert, type) => {
    if (alert.data.source) {
        return alert.data.source;
    }
    if (!alert.data.bhomeId) {
        return 'Unknown source';
    }
    const frameText = alert.slotNumber ? `/ Frame ${alert.slotNumber}${alert.data.side || ''}` : '';
    const hiveText = alert.hiveNumber ? `/ Hive ${alert.hiveNumber}` : '';
    const totalText = `${hiveText} ${frameText}`;
    switch (type) {
        case constants.ALERT_COMPONENT_LEVEL.GENERAL:
            return `Beehome ${alert.data.bhomeId} ${totalText}`;
        case constants.ALERT_COMPONENT_LEVEL.BHOME:
            return totalText;
        default:
            return frameText;
    }
};

const groupAlertsByType = alerts => {
    const alertsByType = new Map();
    alerts.forEach(alert => {
        const type = constants.ALERT_TYPES.SPOTTY_BROOD === alert.type ? 'brood' : 'non_brood';
        const group = alertsByType.get(type) || [];
        group.push(alert);
        alertsByType.set(type, group);
    });
    return alertsByType;
};

const groupBroodAlerts = broodAlerts =>
    chain(broodAlerts)
        .groupBy(alert => `${alert.data.bhomeId}_${alert.hiveNumber}`)
        .map(alerts => ({
            ...alerts[0],
            data: {
                ...alerts[0].data,
                source: `Frames ${alerts.map(alert => `${alert.slotNumber}${alert.data.side}`)}`,
            },
            type: constants.ALERT_TYPES.MULTIPLE_SPOTTY_BROOD,
        }))
        .value();

export const groupMultipleBroodAlerts = (alerts = [], type) => {
    if (['general', 'bhome'].includes(type)) {
        const alertsByType = groupAlertsByType(alerts);

        const broodAlerts = alertsByType.get('brood') || [];
        const nonBroodAlerts = alertsByType.get('non_brood') || [];

        const groupedAlerts = groupBroodAlerts(broodAlerts);

        return [...nonBroodAlerts, ...groupedAlerts].sort((a, b) => dayjs(b.created_at) - dayjs(a.created_at));
    }

    return alerts;
};
