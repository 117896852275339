import { useCallback, useEffect, useMemo } from 'react';
import { isNaN } from 'lodash-es';
import { shallowEqual, useSelector } from 'react-redux';
import constants from 'appConstants';
import { getZoomLevel, getCurrentBhome } from 'components/views/BeeHome/selectors';

const useMapCentering = ({ mapRef, locations }) => {
    const zoomLevel = useSelector(getZoomLevel);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);

    const currentLocation = useMemo(
        () => locations.find(location => location.bhome_ids.includes(currentBhome.id)),
        [locations, currentBhome.id]
    );

    const handleCentering = useCallback(() => {
        if (!mapRef.current) {
            return;
        }

        const zoom = zoomLevel || constants.DEFAULT_ZOOM;

        if (currentLocation?.lat && currentLocation?.lng) {
            mapRef.current.panTo({ lat: currentLocation?.lat, lng: currentLocation?.lng });
            mapRef.current.setZoom(zoom);

            return;
        }

        const { latitude, longitude } = currentBhome?.gps || {};

        const lat = +latitude;
        const lng = +longitude;

        if (isNaN(lat) || isNaN(lng)) {
            return;
        }

        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(zoom);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBhome, mapRef.current, zoomLevel]);

    useEffect(() => {
        if (!currentBhome || !mapRef.current) {
            return;
        }

        handleCentering();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleCentering]);

    return {
        handleCentering,
    };
};

export default useMapCentering;
