import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getBhomeDefinedHives } from '../../components/views/BeeHome/selectors';

const useGetFrameStationHiveNumberAndFrameNumber = frame => {
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);

    const stationHives = hives?.[frame?.place?.station] || [];

    const hiveInfoIndex = stationHives.findIndex(
        hive => frame?.place?.position?.x >= hive?.from && frame?.place?.position?.x < hive?.to
    );

    const framesWithinHive = stationHives[hiveInfoIndex]?.frames;

    if (hiveInfoIndex < 0) {
        return null;
    }

    const { station } = stationHives[hiveInfoIndex];
    const hiveNumber = hiveInfoIndex + 1;
    const frameNumber = framesWithinHive?.length ? (framesWithinHive.findIndex(f => f.id === frame.id) ?? 0) + 1 : null;

    let text = <>Frame {frame.slotId || frame.id}</>;

    let title;
    let imageTitle;

    if (station && hiveNumber && frameNumber) {
        text = (
            <>
                Hive {hiveNumber}({station.toUpperCase()}) Frame {frameNumber}
            </>
        );
        title = (
            <>
                Hive {hiveNumber}
                {station.toUpperCase()} {'>'} <strong className="strong">Frame {frameNumber}</strong>
            </>
        );
        imageTitle = (
            <>
                Hive {hiveNumber}
                {station.toUpperCase()} | <strong className="strong">Frame {frameNumber}</strong>
            </>
        );
    }

    return {
        station,
        hiveNumber,
        frameNumber,
        text,
        title,
        imageTitle,
    };
};

export default useGetFrameStationHiveNumberAndFrameNumber;
