import React from 'react';
import PropTypes from 'prop-types';
import './NameBlock.scss';

const NameBlock = ({ name, title }) => (
    <div className="name-block">
        <div className="name-block-name">{name}</div>
        <div className="name-block-title">{title}</div>
    </div>
);

NameBlock.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
};

export default NameBlock;
