import { get } from 'lodash-es';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import constants from 'appConstants';
import { getIsLimitedViewCompany } from 'selectors';
import { OTHER_LOCATIONS } from '../../Bhome/hooks/useFilters/utils';
import { groupFramesByStationAndHives, mapHivesToMessages } from './utils';

const getInProgressActivitiesList = state => state.activity.activity?.inProgress;

export const getInProgressActivities = createSelector(getInProgressActivitiesList, (inProgressActivities = []) =>
    inProgressActivities.reduce((acc, item) => {
        if (item.status === constants.MESSAGE_STATUS.PROCESSING) {
            acc.unshift(item);
        } else {
            acc.push(item);
        }
        return acc;
    }, [])
);

export const getDoneActivities = state => get(state, 'activity.activity.done', []);

export const getIsBeehomeTrendsDataShown = state => state.beehome.isBeehomeTrendsDataShown;
export const getCurrentBeehomeId = state => get(state, 'beehome.currentBhome.id', null);

export const getCurrentBhomes = createSelector(
    state => state.beehome.bhomes,
    getIsLimitedViewCompany,
    (bhomes, isLimitedView) => (isLimitedView ? bhomes.filter(item => item.has_scan) : bhomes)
);

export const getCurrentBhome = state => state.beehome.currentBhome;
export const getIsCurrentBhomeOnline = state => state.beehome.currentBhomeOnline;
export const getCurrentBhomeId = state => state.beehome.currentBhome?.id;
export const getCurrentBhomeSensors = state => state.beehome.currentBhome?.sensors;
export const getCurrentBhomeFrames = state => state.beehome?.frames ?? [];
export const getIsHistoryLayoutSnapshotShown = state => state.beehome?.isHistoryLayoutSnapshotShown;
export const getAreSnapshotsFetched = state => state.beehome?.areSnapshotsFetched;
export const getSnapshots = state => state.beehome?.snapshots;
export const getStations = state => state.beehome.stations;
export const getInspectedFrame = state => state.beehome.frameToInspect;

export const getIsInspectedFrame = createSelector(
    [getInspectedFrame, (state, frameId) => frameId],
    (frame, frameId) => frame?.id === frameId
);

export const getInspectedHive = state => state.beehome.hiveToInspect;

export const getPinnedHive = state => state.beehome.pinnedHive;

export const getInspectedFrameData = state => state.beehome.inspectedFrameData;
export const getShowBeeMap = state => state.beehome.showBeeMap;
export const getShowFramesHistory = state => state.beehome.showFramesHistory;
export const getLocationFilter = state => state.beehome.locationFilter;
export const getItemFilter = state => state.beehome.itemFilter;
export const getIsHiveManagementModalShown = state => state.beehome.isHiveManagementModalShown;
export const getIsBalanceHiveModeShown = state => state.beehome.isBalanceHiveModeShown;
export const getSelectedFramesForBalance = state => state.beehome.selectedFramesForBalance;
export const getModeInProgress = state => state.activity.modeInProgress;
export const getPendingMessages = state => state.beehome.pendingMessages;
export const getZoomLevel = state => state.beehome.zoomLevel;

const getInProgressScanSlots = state =>
    getInProgressActivities(state)?.flatMap(activity => {
        if (activity.command === constants.COMMANDS.SCAN && activity?.slots?.length) {
            return activity.slots;
        } else if (
            (activity.command === constants.COMMANDS.SCAN || activity.command === constants.COMMANDS.COUNT_BEES) &&
            activity?.sequences?.length
        ) {
            return activity.sequences.reduce((acc, seq) => {
                if (
                    seq.command === constants.COMMANDS.SCAN_FRAME ||
                    seq.command === constants.COMMANDS.COUNT_BEES_FRAME
                ) {
                    acc.push(seq.params.frame);
                }
                return acc;
            }, []);
        }
        return [];
    });

const getInProgressFeedSlots = state =>
    getInProgressActivities(state)?.flatMap(activity => {
        if (activity.command === constants.COMMANDS.FILL_FEEDER && activity?.feeders?.length) {
            return activity.feeders;
        } else if (activity.command === constants.COMMANDS.FILL_FEEDER && activity?.sequences?.length) {
            return activity.sequences.reduce((acc, seq) => {
                if (seq.command === constants.COMMANDS.FILL_SYRUP) {
                    acc.push(seq.params.frame);
                }
                return acc;
            }, []);
        }
        return [];
    });

export const isScanningFrame = frame => state => {
    if (!frame?.rfid) {
        return false;
    }

    const slots = getInProgressScanSlots(state);

    return slots.some(slot => slot.rfid === frame.rfid || slot.id === frame.rfid);
};

export const isFeedingFrame = frame => state => {
    if (!frame?.rfid) {
        return false;
    }

    const slots = getInProgressFeedSlots(state);

    return slots.some(slot => slot.rfid === frame.rfid || slot.id === frame.rfid);
};

export const getFilteredBhomes = createSelector(
    [state => state.beehome.bhomes, state => state.beehome.filters],
    (bhomes, filters) => {
        if (filters.entity === OTHER_LOCATIONS) {
            return bhomes.filter(bhome => !bhome.ranch_id && !bhome.yard_id);
        }
        if (!filters.entityInstance) {
            return [];
        }
        return bhomes.filter(
            bhome => bhome.ranch_id === filters.entityInstance || bhome.yard_id === filters.entityInstance
        );
    }
);

export const getBhomeDefinedHives = createSelector([getCurrentBhomeFrames, getStations], groupFramesByStationAndHives);

export const getPendingMessagesLength = state => state.beehome.pendingMessages?.length;

export const getFilteredPendingMessages = createSelector(
    [getPendingMessages, (state, filter) => filter],
    (messages, filter) => (filter ? messages.filter(message => message.command === filter) : messages)
);

export const getFilteredDoneMessages = createSelector(
    [state => state.beehome.doneMessages, (state, filter) => filter],
    (messages, filter) =>
        filter
            ? messages.filter(({ command }) => {
                  if (filter === constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION) {
                      return [
                          constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION,
                          constants.COMMANDS.END_TRANSPORTATION_MODE,
                      ].includes(command);
                  }
                  return command === filter;
              })
            : messages
);

export const getPendingMessagesWithHives = createSelector(
    [getFilteredPendingMessages, getBhomeDefinedHives],
    mapHivesToMessages
);

export const getDoneMessagesWithHives = createSelector(
    [getFilteredDoneMessages, getBhomeDefinedHives],
    mapHivesToMessages
);

export const getMessagesDatesInRange = createSelector(
    [
        (state, from, to, filter) => getDoneMessagesWithHives(state, filter),
        (state, from) => from,
        (state, from, to) => to,
    ],
    (historyMessages, from, to) => {
        const fromDate = dayjs(from);
        const toDate = dayjs(to);

        if (!historyMessages?.length || !fromDate || !toDate) {
            return [];
        }

        const datesSet = new Set();

        historyMessages.forEach(message => {
            const sentAtDate = dayjs(message.sentAt);

            if (!sentAtDate.isBetween(fromDate, toDate, 'day', '[]')) {
                return;
            }

            const formattedDate = sentAtDate.format('YYYY-MM-DD');

            datesSet.add(formattedDate);
        });

        return Array.from(datesSet);
    }
);
