import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Button from '@beewise/button';
import { fetchBeekeeperRanches, fetchYards } from 'components/views/BeeHome/actions';
import { FETCH_RANCHES, FETCH_YARDS } from 'components/views/BeeHome/actionTypes';
import { createNewRegion, fetchRegions } from '../../actions';
import RegionsList from './components/RegionsList';
import RegionListItem from './components/RegionListItem';
import { FETCH_REGIONS } from '../../actionTypes';

import './Regions.scss';

const Regions = () => {
    const dispatch = useDispatch();
    const regions = useSelector(state => state.settings.regions, arrayOfObjectsShallowEqual);
    const yards = useSelector(state => state.beehome.yards, arrayOfObjectsShallowEqual);
    const newRegion = useSelector(state => state.settings.newRegion, shallowEqual);
    const ranches = useSelector(state => state.beehome.beekeeperRanches, arrayOfObjectsShallowEqual);

    useEffect(() => {
        dispatch(fetchRegions());
        dispatch(fetchYards());
        dispatch(fetchBeekeeperRanches());
    }, [dispatch]);

    const availableYards = useMemo(() => {
        const occupiedYards = regions.reduce((acc, region) => {
            acc.push(...(region?.yards || []));
            return acc;
        }, []);

        return yards.filter(yard => !occupiedYards.includes(yard.id));
    }, [regions, yards]);

    const availableRanches = useMemo(() => {
        const occupiedRanches = regions.reduce((acc, region) => {
            acc.push(...(region?.ranches || []));
            return acc;
        }, []);

        return ranches.filter(ranch => !occupiedRanches.includes(ranch.id));
    }, [ranches, regions]);

    const handleAddNewRegion = () => {
        dispatch(createNewRegion());
    };

    const nonDefaultRegions = useMemo(() => regions.filter(region => !region.is_default), [regions]);
    const defaultRegion = useMemo(() => regions.find(region => region.is_default), [regions]);

    return (
        <div className="regions">
            <div className="regions-header">
                <div className="regions-header-text">
                    <h2>Set Regions</h2>
                    <h3>Each region may hold several yards. Region has its own hive strength definition</h3>
                </div>
                {defaultRegion && <RegionListItem region={defaultRegion} isDefault />}
            </div>
            <RegionsList
                regions={nonDefaultRegions}
                yards={yards}
                availableYards={availableYards}
                newRegion={newRegion}
                ranches={ranches}
                availableRanches={availableRanches}
            />
            <Button className="regions-add btn-secondary" onClick={handleAddNewRegion}>
                + Add Region
            </Button>
        </div>
    );
};

export default loading([FETCH_REGIONS.default, FETCH_YARDS.default, FETCH_RANCHES.default])(Regions);
