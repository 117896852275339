import { createAsyncAction } from '@beewise/utils';

export const TOGGLE_MOVE_FRAMES = 'TOGGLE_MOVE_FRAMES';
export const MOVE_FRAMES = 'MOVE_FRAMES';
export const MOVE_FRAMES_UNDO = 'MOVE_FRAMES_UNDO';
export const MOVE_FRAMES_REDO = 'MOVE_FRAMES_REDO';
export const MOVE_FRAMES_CLEAR_HISTORY = 'MOVE_FRAMES_CLEAR_HISTORY';
export const APPROVE_MOVE_FRAMES = 'APPROVE_MOVE_FRAMES';
export const START_DRAGGING_FRAME = 'START_DRAGGING_FRAME';
export const FINISH_DRAGGING_FRAME = 'FINISH_DRAGGING_FRAME';
export const FINISH_MOVE_FRAMES = 'FINISH_MOVE_FRAMES';
export const CHANGE_HIVE_STATUSES = createAsyncAction('CHANGE_HIVE_STATUSES');
export const FETCH_MOVE_FRAMES_V4 = createAsyncAction('FETCH_MOVE_FRAMES_V4');
export const UPDATE_HIVE_ASSESSMENT = createAsyncAction('UPDATE_HIVE_ASSESSMENT');
