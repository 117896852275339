import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { DIRECTION } from '../../../../utils';
import './IconItem.scss';

const IconItem = ({ icon, title, handleFrameChange, hidden, side }) => (
    <div
        className={cx('full-screen-image-modal-bottom-block', {
            hidden,
        })}
        role="presentation"
        onClick={handleFrameChange}
    >
        {side === DIRECTION.RIGHT && title}
        <FontAwesomeIcon icon={icon} />
        {side === DIRECTION.LEFT && title}
    </div>
);

IconItem.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
    title: PropTypes.string,
    handleFrameChange: PropTypes.func,
    hidden: PropTypes.bool,
    side: PropTypes.string,
};

export default IconItem;
