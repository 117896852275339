import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';
import AsidePanel from 'components/reusables/AsidePanel';
import { useToggle } from '@beewise/react-utils';
import BhomeMap from 'components/views/Bhome/components/BhomeMap';

import './RightPanelMap.scss';

const RightPanelMap = () => {
    const [isPanelOpen, , setIsPanelOpen, setIsPanelClose] = useToggle();

    return (
        <div
            className={cx('right-panel-map-wrapper', { opened: isPanelOpen })}
            onMouseEnter={setIsPanelOpen}
            onMouseLeave={setIsPanelClose}
            role="presentation"
        >
            <FontAwesomeIcon className={cx('right-panel-map-icon', { opened: isPanelOpen })} icon={faMap} />
            <AsidePanel
                isPanelOpen={isPanelOpen}
                handleOpenPanel={setIsPanelOpen}
                onClose={setIsPanelClose}
                side="right"
            >
                <div className="right-panel-map">
                    <BhomeMap />
                </div>
            </AsidePanel>
        </div>
    );
};

export default RightPanelMap;
