import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { loading } from '@beewise/react-utils';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FETCH_DASHBOARD_GRID_DATA } from '../../actionTypes';

import './PerformanceWidget.scss';

const getPlural = (amount, word) => (amount === 1 ? `1 ${word}` : `${amount} ${word}s`);

const PerformanceWidgetItem = ({ name, value, children }) => (
    <div className="performance-widget-item">
        <div>{name}</div>
        {children}
        <div className="performance-widget-item-amount">{getPlural(value, 'hive')}</div>
    </div>
);

PerformanceWidgetItem.propTypes = {
    name: PropTypes.string,
    value: PropTypes.number,
    children: PropTypes.node,
};

const PerformanceWidget = ({ data }) => {
    const {
        totalAmountOfWeeklyScannedHives,
        totalAmountOfWeeklyCountedHives,
        totalAmountOfWeeklyFedHives,
        totalAmountOfWeeklyFeedCycles,
    } = useMemo(
        () =>
            data?.reduce(
                (acc, item) => {
                    acc.totalAmountOfWeeklyScannedHives += item.totalAmountOfWeeklyScannedHives ?? 0;
                    acc.totalAmountOfWeeklyFedHives += item.totalAmountOfWeeklyFedHives ?? 0;
                    acc.totalAmountOfWeeklyFeedCycles += item.totalAmountOfWeeklyFeedCycles ?? 0;
                    acc.totalAmountOfWeeklyCountedHives += item.totalAmountOfWeeklyCountedHives ?? 0;
                    return acc;
                },
                {
                    totalAmountOfWeeklyScannedHives: 0,
                    totalAmountOfWeeklyFedHives: 0,
                    totalAmountOfWeeklyFeedCycles: 0,
                    totalAmountOfWeeklyCountedHives: 0,
                }
            ),
        [data]
    );

    return (
        <div className="widget performance-widget">
            <div className="performance-widget-header">
                <span className="secondary-text">Beehomes performance</span>
                <span className="light-text">Last 7 days</span>
            </div>
            <div className="performance-widget-content">
                <PerformanceWidgetItem name="Scans" value={totalAmountOfWeeklyScannedHives} />
                <PerformanceWidgetItem name="Bee count" value={totalAmountOfWeeklyCountedHives} />
                <PerformanceWidgetItem name="Feeding" value={totalAmountOfWeeklyFedHives}>
                    <Tooltip
                        content={`${getPlural(totalAmountOfWeeklyFeedCycles, 'cycle')} of 0.45 gallons each`}
                        position="top"
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                </PerformanceWidgetItem>
            </div>
        </div>
    );
};

PerformanceWidget.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
};

export default loading(FETCH_DASHBOARD_GRID_DATA.default)(PerformanceWidget, 'performance-widget-wrapper');
