import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { loading } from '@beewise/react-utils';
import cx from 'classnames';
import { EmptyChart } from '@beewise/chart';
import { FETCH_HIVES } from 'components/views/BeeHome/actionTypes';
import ChartFramesPlot from './ChartFramesPlot';
import { FETCH_BHOMES_FRAMES_CONTENT_COUNT } from '../actionTypes';

const Chart = ({ graphsDisplayed, dataOptions, zoomLevel, showPlot, plotOptions }) => (
    <div className="card">
        {graphsDisplayed.map(
            ({ fill, chartName, show, color }) =>
                show && (
                    <div className="card-title" key={chartName}>
                        <div className="card-title-round" style={{ backgroundColor: color || fill }} />
                        {chartName}
                    </div>
                )
        )}
        <div
            className={cx('card-body brood-chart', {
                'max-zoom-level': zoomLevel === 'max',
            })}
        >
            {showPlot ? (
                <ChartFramesPlot
                    graphsDisplayed={graphsDisplayed}
                    zoomLevel={zoomLevel}
                    dataOptions={dataOptions}
                    xAxisOptions={plotOptions}
                />
            ) : (
                <EmptyChart />
            )}
        </div>
    </div>
);

Chart.propTypes = {
    dataOptions: PropTypes.arrayOf(PropTypes.shape()),
    graphsDisplayed: PropTypes.arrayOf(PropTypes.shape()),
    plotOptions: PropTypes.shape(),
    showPlot: PropTypes.bool,
    zoomLevel: PropTypes.string,
};

export default loading([FETCH_HIVES.default, FETCH_BHOMES_FRAMES_CONTENT_COUNT.default])(memo(Chart));
