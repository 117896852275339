import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { format } from 'timeago.js';
import Icon from '@beewise/icon';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faEnvelopeOpen,
    faOctagonExclamation,
    faTriangleExclamation,
    faCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';
import { showActivityBar } from 'actions';
import { fetchUpdateCompanyAlerts } from 'components/reusables/Alerts/actions';
import { setFrameToInspectByFrameId } from 'components/views/BeeHome/actions';
import { getAlertTitle, getAlertSource, getAlertDescription, highPriorityAlerts, mediumPriorityAlerts } from './utils';

import './Alerts.scss';

const AlertListItem = ({ alert, closeAlertList, type, style } = {}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAlertUnread = alert.status === constants.ALERT_STATUSES.UNREAD;

    const renderAlertIcon = useCallback(alertType => {
        if (highPriorityAlerts.includes(alertType)) {
            return <FontAwesomeIcon icon={faOctagonExclamation} className="title-alert-icon" />;
        } else if (mediumPriorityAlerts.includes(alertType)) {
            return <FontAwesomeIcon icon={faTriangleExclamation} className="title-warning-icon" />;
        }
        return <FontAwesomeIcon icon={faCircleInfo} className="title-info-icon" />;
    }, []);

    const handleStatusChange = useCallback(
        e => {
            e.stopPropagation();
            dispatch(
                fetchUpdateCompanyAlerts([alert.id], {
                    status: constants.ALERT_STATUSES.READ,
                })
            );
        },
        [alert.id, dispatch]
    );

    const handleItemClick = useCallback(
        e => {
            e.stopPropagation();

            if (alert.type === constants.ALERT_TYPES.SYRUP_LEVEL || alert.data.bhomeId) {
                handleStatusChange(e);
            }

            closeAlertList();

            if (alert.data.bhomeId) {
                navigate(`/bhome/${alert.data.bhomeId}`);
                dispatch(showActivityBar());
            }

            if (alert.data.frameId) {
                dispatch(setFrameToInspectByFrameId(alert.data.frameId));
            }
        },
        [alert, dispatch, closeAlertList, handleStatusChange, navigate]
    );

    return (
        <div
            className={cx('list-item', { 'list-item-unread': isAlertUnread })}
            onClick={handleItemClick}
            role="presentation"
            style={style}
        >
            <div className="list-item-text">
                <div className="title">
                    {renderAlertIcon(alert.type)} {getAlertTitle(alert)}
                </div>
                <div className="list-item-source">{getAlertSource(alert, type)}</div>
                <div className="list-item-description">{getAlertDescription(alert)}</div>
                <div className="list-item-time">
                    <Icon type="clock" />
                    <div className="list-item-time-label">{format(alert.created_at, 'en_US')}</div>
                </div>
            </div>
            <Tooltip text={isAlertUnread ? 'Mark read' : 'Mark unread'} position="top center" hideOnScroll>
                <FontAwesomeIcon
                    icon={isAlertUnread ? faEnvelope : faEnvelopeOpen}
                    onClick={handleStatusChange}
                    className="envelope"
                />
            </Tooltip>
        </div>
    );
};

AlertListItem.propTypes = {
    alert: PropTypes.shape(),
    style: PropTypes.shape(),
    closeAlertList: PropTypes.func,
    type: PropTypes.string,
};

export default AlertListItem;
