import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ModalManager = ({ Fallback, modalController }) => {
  const [modal, setModal] = useState(null);

  useEffect(() => {
    const unsubscribe = modalController.subscribe(setModal);
    return () => unsubscribe();
  }, []);

  if (!modal) {
    return null;
  }

  const onClose = () => modalController.set(null);

  const Modal = modalController.get(modal.name);

  return (
    <Suspense fallback={<Fallback>Loading...</Fallback>}>
      <Modal {...modal?.props} isOpen onClose={onClose} suppressCoverClose />
    </Suspense>
  );
};

ModalManager.propTypes = {
  Fallback: PropTypes.elementType.isRequired,
  modalController: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired
  }).isRequired
};

export default ModalManager;
