import React from 'react';
import PropTypes from 'prop-types';
import GoogleMaps from '@beewise/google-maps';

const defaultZoom = 1;
const defaultCenter = {
    lat: 60.45,
    lng: 40.52,
};
const bootstrapURLKeys = {
    key: 'AIzaSyD9CIM7V96Rpbmi1aWqJrAMP-wn5C_ntdY',
    libraries: 'places',
};

const mapOptions = {
    mapTypeId: 'hybrid',
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControl: false,
    minZoom: 2.5,
    // don't show grey part of map
    restriction: {
        latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180,
        },
    },
};

const GoogleMap = ({ onLoad, onChange, children }) => (
    <GoogleMaps
        containerProps={{ id: 'map', className: 'google-map' }}
        apiKey={bootstrapURLKeys.key}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={onLoad}
        onChange={onChange}
        options={mapOptions}
    >
        {children}
    </GoogleMaps>
);

GoogleMap.propTypes = {
    onLoad: PropTypes.func,
    onChange: PropTypes.func,
    children: PropTypes.node,
};

export default GoogleMap;
