import {
    FETCH_COMPANY_LOCATIONS,
    CREATE_COMPANY_LOCATION,
    UPDATE_COMPANY_LOCATION,
    REMOVE_COMPANY_LOCATION,
    SAVE_CURRENT_MAP_STATE,
    CLEAR_CURRENT_MAP_STATE,
    CLEAR_COMPANY_LOCATIONS,
    FETCH_ALL_BHOMES_ONLINE,
    UPDATE_BHOME_LOCATION,
    SET_BHOME_ARRIVED,
    FETCH_DASHBOARD_GRID_DATA,
    FETCH_FEED_BHOMES,
    FETCH_UPDATE_BHOME_FEED_SETTINGS,
    FETCH_BHOMES_FEED_STATUSES,
} from '../actionTypes';

export const fetchCompanyLocations = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_LOCATIONS.default,
        url: '/companies/locations',
    },
});

export const fetchDashboardGridData = ({ offset, limit, type }, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DASHBOARD_GRID_DATA.default,
        url: `/bhomes/v4/dashboard?type=${type}&offset=${offset}&limit=${limit}`,
        resolver,
    },
});

export const fetchBhomesFeedStatuses = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOMES_FEED_STATUSES.default,
        url: '/bhomes/v4/feed-statuses',
    },
});

export const fetchAllBhomesOnline = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_ALL_BHOMES_ONLINE.default,
        url: '/bhomes/get-all-bhomes-online',
    },
});

export const removeMarker = id => ({
    type: 'FETCH',
    params: {
        type: REMOVE_COMPANY_LOCATION.default,
        url: '/companies/locations/remove',
        method: 'DELETE',
        body: {
            id,
        },
    },
});

export const fetchCreateCompanyLocation = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: CREATE_COMPANY_LOCATION.default,
        method: 'POST',
        url: '/companies/locations/create',
        body,
        resolver,
    },
});

export const fetchUpdateCompanyLocation = (locationId, { locationName = null }) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_COMPANY_LOCATION.default,
        method: 'PUT',
        url: '/companies/locations/update',
        body: {
            locationId,
            locationName,
        },
    },
});

export const saveCurrentMapState = (center, zoom) => ({
    type: SAVE_CURRENT_MAP_STATE,
    center,
    zoom,
});

export const clearCurrentMapState = () => ({
    type: CLEAR_CURRENT_MAP_STATE,
});

export const clearCompanyLocations = () => ({
    type: CLEAR_COMPANY_LOCATIONS,
});

export const fetchUpdateBhomeLocation = (bhomeId, locationId) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_BHOME_LOCATION.default,
        url: `/bhomes/${bhomeId}/changeLocation`,
        method: 'PUT',
        body: {
            locationId,
        },
    },
});

export const setBhomeArrived = locationId => ({
    type: SET_BHOME_ARRIVED,
    locationId,
});

export const fetchFeedBhomes = (bhomeIds, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FEED_BHOMES.default,
        method: 'POST',
        url: '/bhomes/v4/feed',
        body: { bhomeIds },
        resolver,
        failureToastText: 'Feed bhomes error',
        toastText: 'Feeding request was sent successfully to the beehomes',
    },
});

export const fetchUpdateBhomeFeedSettings = ({ body, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME_FEED_SETTINGS.default,
        method: 'POST',
        url: '/bhomes/v4/feed/settings',
        body,
        resolver,
    },
});
