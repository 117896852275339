import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import constants from 'appConstants';
import { isNumber } from 'lodash-es';
import { loading } from '@beewise/react-utils';
import { getShowFramesHistory } from 'components/views/BeeHome/selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHexagon } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '@beewise/tooltip';
import FullScreenImageModal from 'components/reusables/RightPanel/components/HiveBlock/components/FullScreenImageModal';
import { FrameImage } from 'components/reusables/RightPanel/components/HiveBlock/components/components';
import { FETCH_INSPECTED_FRAME_IMAGES } from 'components/views/BeeHome/actionTypes';
import useTransformedImagesData from './useTransformedImagesData';
import { getCombFrameImages, calculateFrameMetrics } from './utils';

import './HiveBlock.scss';

const SIDE = {
    A: 'A',
    B: 'B',
};

const HiveBlock = ({ frame, data }) => {
    const [showAnnotatedPhotos, setShowAnnotatedPhotos] = useState(false);
    const showFramesHistory = useSelector(getShowFramesHistory);
    const [fullScreenImage, setFullScreenImage] = useState({
        image: null,
        side: null,
        rootImages: null,
    });

    const frameData = showFramesHistory ? data?.previousData : data?.latestData;
    const frameMetrics = calculateFrameMetrics({ sideA: frameData?.sideA, sideB: frameData?.sideB });
    const isInspectedFrameShown = isNumber(frame.id);
    const isCombFrame = frame.type === constants.FRAME_TYPES.COMB_FRAME;
    const { beeCountImages, frameImages } = useTransformedImagesData(frameData?.images);

    const getIsModalOpen = useCallback(
        () => !!(fullScreenImage.image && fullScreenImage.side && fullScreenImage.rootImages),
        [fullScreenImage]
    );

    const handleFullScreenImageClose = useCallback(
        () => setFullScreenImage({ image: null, side: null, rootImages: null }),
        []
    );

    const handleToggleShowAnnotatedPhotos = useCallback(() => {
        setShowAnnotatedPhotos(!showAnnotatedPhotos);

        if (!getIsModalOpen()) {
            return;
        }

        const image = getCombFrameImages(frame, fullScreenImage.rootImages, fullScreenImage.side, !showAnnotatedPhotos);

        if (image.url || image.resizedUrl) {
            setFullScreenImage(prevFullScreenImage => ({ ...prevFullScreenImage, image }));
        }
    }, [frame, fullScreenImage, getIsModalOpen, showAnnotatedPhotos]);

    const handleFullScreenImageShow = useCallback(
        (side, images) => () => {
            const image = getCombFrameImages(frame, images, side, showAnnotatedPhotos);

            if (image.url || image.resizedUrl) {
                setFullScreenImage({ image, side, rootImages: images });
            } else {
                handleFullScreenImageClose();
            }
        },
        [frame, handleFullScreenImageClose, showAnnotatedPhotos]
    );

    if (!isInspectedFrameShown) {
        return null;
    }

    return (
        <div className="hive-block" tabIndex="0" role="button">
            <div className="hive-block-inspected-frame hive-block-inspected-frame-bh4">
                <div className="hive-block-inspected-frame-header">
                    {isCombFrame && (
                        <div className="hive-block-inspected-frame-scan-details">
                            <h3 className="scan-details-header">Frame details:</h3>
                            <span className="scan-details-line">
                                <p>Frame content scan date:</p>
                                <p>{frameData?.scanDate ? new Date(frameData.scanDate).toLocaleDateString() : '-'}</p>
                            </span>
                            <span className="scan-details-line">
                                <p>Bee count scan date:</p>
                                <p>
                                    {frameData?.beeScanDate
                                        ? new Date(frameData.beeScanDate).toLocaleDateString()
                                        : '-'}
                                </p>
                            </span>
                            <span className="scan-details-line">
                                <p>Bee coverage:</p>
                                <p>{frameMetrics.beesPercent !== null ? `${frameMetrics.beesPercent}%` : '-'}</p>
                            </span>
                            {(!!frameMetrics.broodPercent ||
                                !!frameMetrics.honeyPercent ||
                                !!frameMetrics.pollenPercent) && (
                                <>
                                    <span className="scan-details-line">
                                        <p>Brood:</p>
                                        <p>{frameMetrics.broodPercent ? ` ${frameMetrics.broodPercent}%` : '-'}</p>
                                    </span>
                                    <span className="scan-details-line">
                                        <p>Honey:</p>
                                        <p>{frameMetrics.honeyPercent ? `${frameMetrics.honeyPercent}%` : '-'}</p>
                                    </span>
                                    <span className="scan-details-line">
                                        <p>Pollen:</p>
                                        <p>{frameMetrics.pollenPercent ? `${frameMetrics.pollenPercent}%` : '-'}</p>
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                    {isCombFrame && (
                        <Tooltip text="AI view" position="left center">
                            <FontAwesomeIcon
                                icon={faHexagon}
                                className={cx('show-annotation-icon', {
                                    'is-active': showAnnotatedPhotos,
                                })}
                                onClick={handleToggleShowAnnotatedPhotos}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className="hive-block-inspected-frame-scans">
                    {isCombFrame && (frameImages?.sideA?.fileName || frameImages?.sideB?.fileName) && (
                        <>
                            <h3 className="section-header">Frame content images</h3>
                            <div
                                className={cx('hive-block-inspected-frame-scan-a', {
                                    'full-screen': fullScreenImage.side === SIDE.A,
                                })}
                                role="presentation"
                                onClick={handleFullScreenImageShow(SIDE.A, frameImages)}
                            >
                                {frame.sideA && <div className="hive-block-inspected-frame-scan-side-label">A</div>}
                                <FrameImage
                                    frame={frame}
                                    frameImages={frameImages}
                                    showAnnotatedPhotos={showAnnotatedPhotos}
                                    side={SIDE.A}
                                />
                            </div>
                            <div
                                className={cx('hive-block-inspected-frame-scan-b', {
                                    'full-screen': fullScreenImage.side === SIDE.B,
                                })}
                                role="presentation"
                                onClick={handleFullScreenImageShow(SIDE.B, frameImages)}
                            >
                                {frame.sideB && <div className="hive-block-inspected-frame-scan-side-label">B</div>}
                                <FrameImage
                                    frame={frame}
                                    frameImages={frameImages}
                                    showAnnotatedPhotos={showAnnotatedPhotos}
                                    side={SIDE.B}
                                />
                            </div>
                        </>
                    )}
                    {isCombFrame && (beeCountImages?.sideA?.fileName || beeCountImages?.sideB?.fileName) && (
                        <>
                            <h3 className="section-header">Bee count images:</h3>
                            {isCombFrame && beeCountImages?.sideA && (
                                <div
                                    className={cx('hive-block-inspected-frame-scan-a', {
                                        'full-screen': fullScreenImage.side === SIDE.A,
                                    })}
                                    role="presentation"
                                    onClick={handleFullScreenImageShow(SIDE.A, beeCountImages)}
                                >
                                    {beeCountImages.sideA && (
                                        <div className="hive-block-inspected-frame-scan-side-label">A</div>
                                    )}
                                    <FrameImage
                                        frame={frame}
                                        frameImages={beeCountImages}
                                        showAnnotatedPhotos={showAnnotatedPhotos}
                                        side={SIDE.A}
                                    />
                                </div>
                            )}
                            {isCombFrame && beeCountImages?.sideB && (
                                <div
                                    className={cx('hive-block-inspected-frame-scan-b', {
                                        'full-screen': fullScreenImage.side === SIDE.B,
                                    })}
                                    role="presentation"
                                    onClick={handleFullScreenImageShow(SIDE.B, beeCountImages)}
                                >
                                    {frame.sideB && <div className="hive-block-inspected-frame-scan-side-label">B</div>}
                                    <FrameImage
                                        frame={frame}
                                        frameImages={beeCountImages}
                                        showAnnotatedPhotos={showAnnotatedPhotos}
                                        side={SIDE.B}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <FullScreenImageModal
                    isOpen={getIsModalOpen()}
                    onClose={handleFullScreenImageClose}
                    image={fullScreenImage.image}
                    side={fullScreenImage.side}
                />
            </div>
        </div>
    );
};

HiveBlock.propTypes = {
    data: PropTypes.shape(),
    frame: PropTypes.shape(),
};

export default loading(FETCH_INSPECTED_FRAME_IMAGES.default)(HiveBlock);
