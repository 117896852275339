import React from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { BeewiseToastContainer } from '@beewise/toast';

const BEEHOME = 'bhome';
const WORKSPACE = 'workspace';

const ToastWrapper = () => {
    const location = useLocation();

    const isBeehomeRoute = location.pathname.includes(BEEHOME);
    const isWorkspaceRoute = location.pathname.includes(WORKSPACE);

    return (
        <BeewiseToastContainer
            position="bottom-center"
            className={cn({
                'beehome-page-toast-container': isBeehomeRoute,
                'workspace-page-toast-container': isWorkspaceRoute,
            })}
        />
    );
};

export default ToastWrapper;
