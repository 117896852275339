import { noop } from 'lodash-es';
import queryString from 'query-string';
import constants from 'appConstants';
import {
    CLEAR_BHOMES_VIEW,
    FETCH_HIVES,
    TOGGLE_FRAMES_HISTORY,
    FETCH_FILL_FEEDERS,
    SET_FRAME_TO_INSPECT,
    SET_HIVE_TO_INSPECT,
    FETCH_UPDATE_BHOME_LAST_VISITED,
    FETCH_SEND_SCAN_COMMAND,
    FETCH_CURRENT_BHOME,
    FETCH_FRAMES_LAYOUT,
    SET_CURRENT_BHOME,
    FETCH_BHOME_NOTES,
    CREATE_BHOME_NOTE,
    UPDATE_BHOME_NOTE,
    SET_FRAME_TO_INSPECT_BY_FRAME_ID,
    SET_FRAME_TO_INSPECT_BY_RFID,
    FETCH_INSPECTED_FRAME_IMAGES,
    FETCH_RANCHES,
    FETCH_CLIMATE_INFO,
    FETCH_CHANGE_MARKED_HIVES,
    FETCH_YARDS,
    FETCH_CREATE_YARD,
    FETCH_DELETE_YARD,
    FETCH_UPDATE_YARD,
    FETCH_YARD_STATS_WIDGET_DATA,
    FETCH_BEEKEEPER_RANCHES,
    TOGGLE_HIVE_MANAGMENT_MODAL,
    FETCH_SEND_COUNT_BEES,
    FETCH_BHOME_STATS_WIDGET_DATA,
    FETCH_MODIFY_MODE,
    FETCH_MODIFY_BULK_MODE,
    CLEAR_YARD_WIDGET_DATA,
    FETCH_UPDATE_BHOME,
    SET_LOCATION_FILTER,
    SET_ITEM_FILTER,
    TOGGLE_BALANCE_HIVE_MODE,
    SET_SELECTED_FRAMES_FOR_BALANCE,
    TOGGLE_SELECTED_HIVE,
    FETCH_LAYOUT_SNAPSHOT,
    SET_SNAPSHOT_TO_SHOW,
    FETCH_PENDING_MESSAGES,
    CHANGE_FILTERS,
    FETCH_CANCEL_ACTIONS,
    FETCH_DONE_MESSAGES,
    SET_ZOOM_LEVEL,
} from '../actionTypes';

// TODO check if need all actions after old page is removed
export const fetchHives = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_HIVES.default,
        url: '/bhomes?type=4',
        resolver,
    },
});

export const fetchRanches = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_RANCHES.default,
        url: '/ranches?country=all',
        resolver,
    },
});

export const fetchBeekeeperRanches = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_BEEKEEPER_RANCHES.default,
        url: '/ranches/beekeeper',
        resolver,
    },
});

export const fetchCreateYard = (data, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_YARD.default,
        method: 'POST',
        url: '/yards',
        body: data,
        resolver,
    },
});

export const fetchUpdateYard = (id, data, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_YARD.default,
        method: 'PUT',
        url: `/yards/${id}`,
        body: data,
        resolver,
    },
});

export const fetchDeleteYard = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_YARD.default,
        method: 'DELETE',
        url: `/yards/${id}`,
        body: {
            id,
        },
        resolver,
    },
});

export const fetchYards = resolver => ({
    type: 'FETCH',
    params: {
        type: FETCH_YARDS.default,
        url: '/yards',
        resolver,
    },
});

export const fetchLayoutSnapshot = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_LAYOUT_SNAPSHOT.default,
        url: `/bhomes/v4/${id}/layout-snapshot`,
        resolver,
    },
});

export const fetchUpdateLastVisited = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME_LAST_VISITED.default,
        url: `/bhomes/${id}/last-visited`,
        method: 'PUT',
    },
});

export const fetchFillFeeders = ({ slots, bhomeId, options, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FILL_FEEDERS.default,
        url: `/bhomes/${bhomeId}/feed`,
        method: 'POST',
        body: { feeders: slots },
        payloadType: null,
        ...options,
        resolver,
    },
});

export const fetchClimateInfo = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_CLIMATE_INFO.default,
        url: `/bhomes/${bhomeId}/get-climate-info`,
    },
});

export const fetchScanBeehomeBySlotRange = ({ bhomeId, slots, options = {}, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SEND_SCAN_COMMAND.default,
        url: `/bhomes/${bhomeId}/scan`,
        method: 'POST',
        payloadType: null,
        body: {
            slots,
        },
        ...options,
        resolver,
    },
});

export const fetchSendCountBees = ({ bhomeId, slots, options, resolver }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_SEND_COUNT_BEES.default,
        url: `/bhomes/v4/${bhomeId}/count-bees`,
        method: 'POST',
        payloadType: null,
        body: {
            slots,
        },
        ...options,
        resolver,
    },
});

export const fetchCurrentBhome = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_CURRENT_BHOME.default,
        url: `/bhomes/${id}`,
    },
});

export const fetchFrames = (id, resolver = noop) => ({
    type: 'FETCH',
    params: {
        type: FETCH_FRAMES_LAYOUT.default,
        url: `/bhomes/${id}/frames`,
        resolver,
    },
});

export const fetchBhomeNotes = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_NOTES.default,
        url: `/bhomes/${id}/bhome-notes`,
    },
});

export const createBhomeNote = (bhomeId, hiveId, frameId, note) => ({
    type: 'FETCH',
    params: {
        type: CREATE_BHOME_NOTE.default,
        url: '/bhomes/bhome-notes',
        method: 'POST',
        body: {
            bhomeId,
            hiveId,
            frameId,
            note,
        },
    },
});

export const updateBhomeNote = (id, note, status) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_BHOME_NOTE.default,
        url: '/bhomes/bhome-notes',
        method: 'PUT',
        body: {
            id,
            note,
            status,
        },
    },
});

export const setCurrentBhome = (bhome, avoidSocketCreation = false) => ({
    type: SET_CURRENT_BHOME,
    bhome,
    avoidSocketCreation,
});

export const setFrameToInspect = frame => ({
    type: SET_FRAME_TO_INSPECT,
    frame,
});

export const setFrameToInspectByFrameId = frameId => ({
    type: SET_FRAME_TO_INSPECT_BY_FRAME_ID,
    frameId,
});

export const setFrameToInspectByRfid = rfid => ({
    type: SET_FRAME_TO_INSPECT_BY_RFID,
    rfid,
});

export const setHiveToInspect = hiveId => ({
    type: SET_HIVE_TO_INSPECT,
    hiveId,
});

export const toggleFramesHistory = () => ({
    type: TOGGLE_FRAMES_HISTORY,
});

export const clearYardWidgetData = () => ({
    type: CLEAR_YARD_WIDGET_DATA,
});

export const clearBhomesView = () => ({
    type: CLEAR_BHOMES_VIEW,
});

export const toggleHiveManagementModal = () => ({
    type: TOGGLE_HIVE_MANAGMENT_MODAL,
});

export const setBalanceHiveMode = isBalanceHiveModeShown => ({
    type: TOGGLE_BALANCE_HIVE_MODE,
    isBalanceHiveModeShown,
});

export const setSelectedFramesForBalance = selectedFramesForBalance => ({
    type: SET_SELECTED_FRAMES_FOR_BALANCE,
    selectedFramesForBalance,
});

export const fetchInspectedFrameData = ({ frameId }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_INSPECTED_FRAME_IMAGES.default,
        url: `/bhomes/frame/${frameId}`,
        method: 'GET',
    },
});

export const fetchChangeMarkedHives = (bhomeId, body) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_CHANGE_MARKED_HIVES.default,
        url: `/bhomes/${bhomeId}/mark-hives`,
        body,
    },
});

export const fetchYardStatsWidgetData = yardId => ({
    type: 'FETCH',
    params: {
        type: FETCH_YARD_STATS_WIDGET_DATA.default,
        url: `/yards/${yardId}/stats-widget-data`,
    },
});

export const fetchBhomeStatsWidgetData = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_STATS_WIDGET_DATA.default,
        url: `/bhomes/v4/${bhomeId}/beehome-widget-data`,
    },
});

export const modifyMode = ({ bhomeId, mode, action, resolver, toastText }) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_MODIFY_MODE.default,
        url: `/bhomes/v4/${bhomeId}/modes/${mode}/${action}`,
        toastText,
        resolver,
    },
});

export const modifyBulkMode = ({ body, resolver }) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_MODIFY_BULK_MODE.default,
        url: '/bhomes/v4/modes/bulk',
        resolver,
        body,
    },
});

export const fetchUpdateBhome = (id, updateBody) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME.default,
        url: `/bhomes/${id}/update`,
        method: 'PUT',
        body: {
            updateBody,
        },
    },
});

export const setLocationFilter = ({ locationFilter }) => ({
    type: SET_LOCATION_FILTER,
    locationFilter,
});

export const setItemFilter = ({ itemFilter }) => ({
    type: SET_ITEM_FILTER,
    itemFilter,
});

export const toggleSelectedHive = hiveId => ({
    type: TOGGLE_SELECTED_HIVE,
    hiveId,
});

export const setSnapshotToShow = snapshot => ({
    type: SET_SNAPSHOT_TO_SHOW,
    snapshot,
});

export const fetchPendingMessages = ({ bhomeId }) => {
    const qs = queryString.stringify(
        {
            commands: [constants.COMMANDS.FILL_FEEDER, constants.COMMANDS.SCAN, constants.COMMANDS.COUNT_BEES],
        },
        { arrayFormat: 'bracket' }
    );
    return {
        type: 'FETCH',
        params: {
            type: FETCH_PENDING_MESSAGES.default,
            url: `/messages/bhomes/${bhomeId}/pending?${qs}`,
        },
    };
};

export const changeFilters = filters => ({
    type: CHANGE_FILTERS,
    filters,
});

export const cancelActions = ({ bhomeId, messageIds, ...rest }) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_CANCEL_ACTIONS.default,
        url: `/messages/cancel/${bhomeId}`,
        body: {
            messageIds,
        },
        payloadType: null,
        ...rest,
    },
});

export const fetchHistoryMessages = bhomeId => ({
    type: 'FETCH',
    params: {
        type: FETCH_DONE_MESSAGES.default,
        url: `/messages/v2/${bhomeId}`,
    },
});

export const setZoomLevel = ({ zoomLevel }) => ({
    type: SET_ZOOM_LEVEL,
    zoomLevel,
});
