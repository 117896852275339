import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import cx from 'classnames';
import './BhomeIconItem.scss';

const BhomeIconItem = ({ icon, title, data, isLowData }) => (
    <div
        className={cx('bhome-item-block-item', {
            'without-title': !title,
            'low-data': isLowData,
        })}
    >
        <div className="bhome-item-block-item-header">
            <Icon type={icon} strokeWidth={0.3} />
            {title}
        </div>
        <div className="bhome-item-block-item-data">{data}</div>
    </div>
);

BhomeIconItem.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isLowData: PropTypes.bool,
};

export default BhomeIconItem;
