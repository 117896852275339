import useSupercluster from 'use-supercluster';
import { useEffect } from 'react';
import { isEqual } from 'lodash-es';

const superClusterParams = {
    minZoom: 0,
    maxZoom: 16,
    radius: 60,
};

export default ({
    bhomeMarkersForClusters,
    emptyMarkersForClusters,
    mapOptions,
    beehomeClusters,
    setBeehomeClusters,
    locationClusters,
    setLocationClusters,
}) => {
    const { clusters: bhomeClusters, supercluster: bhomeSupercluster } = useSupercluster({
        points: bhomeMarkersForClusters,
        bounds: mapOptions.boundsCoords,
        zoom: mapOptions.zoom,
        options: superClusterParams,
    });

    const { clusters: emptyClusters, supercluster: locationSupercluster } = useSupercluster({
        points: emptyMarkersForClusters,
        bounds: mapOptions.boundsCoords,
        zoom: mapOptions.zoom,
        options: superClusterParams,
    });

    useEffect(() => {
        if (mapOptions.bounds) {
            if (!isEqual(beehomeClusters, bhomeClusters)) {
                setBeehomeClusters(bhomeClusters);
            }

            if (!isEqual(locationClusters, emptyClusters)) {
                setLocationClusters(emptyClusters);
            }
        } else {
            if (beehomeClusters.length) {
                setBeehomeClusters([]);
            }

            if (locationClusters.length) {
                setLocationClusters([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapOptions, bhomeClusters, emptyClusters]);

    return {
        bhomeSupercluster,
        locationSupercluster,
    };
};
