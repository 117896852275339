import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { getBalanceHiveCommand } from '@beewise/utils';
import { useToggle, arrayOfObjectsShallowEqual, shallowEqual, usePermission } from '@beewise/react-utils';
import constants from 'appConstants';
import {
    getShowFramesHistory,
    getCurrentBhomeSensors,
    getCurrentBhome,
    getModeInProgress,
    getIsBalanceHiveModeShown,
    getSelectedFramesForBalance,
    getStations,
    getIsHistoryLayoutSnapshotShown,
} from 'components/views/BeeHome/selectors';
import { getActionMode, getUnsavedActivities } from 'components/reusables/RightPanel/selectors';
import { useFeatureFlag } from 'components/reusables/FeatureFlags';
import ProgressBlock from 'components/reusables/ProgressBlock';
import {
    fetchCurrentBhome,
    setFrameToInspect,
    modifyMode,
    toggleFramesHistory,
    toggleHiveManagementModal,
    setBalanceHiveMode,
    setSelectedFramesForBalance,
} from 'components/views/BeeHome/actions';
import { getBhomeFrames } from 'components/reusables/Alerts/selectors';
import {
    setActionMode,
    clearUnsavedActivities,
    setEntrancesToOperate,
    fetchModeInProgress,
    addUnsavedActivities,
} from '../../actions/activity.actions';
import { renderShowHistoryIconWithTooltip, renderActionIconWithTooltip, actionsToShow } from './utils';
import { DiscardConfirmationModal } from '../UnsavedActivities/UnsavedActivityModals';

import './BhomeActions.scss';
import { ACTION_END, ACTION_START } from '../../../../views/Workspace/utils';

const actionTextToRender = actionMode => {
    switch (actionMode) {
        case constants.COMMANDS.SCAN:
            return 'Select the frames you wish to scan';
        case constants.COMMANDS.COUNT_BEES:
            return 'Select the frames you wish to bee scan';
        case constants.COMMANDS.FILL_FEEDER:
            return 'Select the feeders you wish to fill';
        case constants.COMMANDS.MARK_HIVES:
            return 'Click on the blue bar to mark a populated hive';
        default:
            return '';
    }
};

const WAITING_TIME = 15;

const modeToCommandMapping = {
    [constants.MODE.VISIT]: constants.COMMANDS.MOVE_TO_VISIT_POSITION,
    [constants.MODE.TRANSPORTATION]: constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION,
};

const BhomeActions = () => {
    const dispatch = useDispatch();

    const allowedActions = usePermission('beekeeper');
    const [discardModalShown, , , hideDiscardModal] = useToggle(false);
    const [isSpecialModeActive, setIsSpecialModeActive] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [activeMode, setActiveMode] = useState('');
    const [hasActiveMode, setHasActiveMode] = useState('');
    const actionMode = useSelector(getActionMode);
    const selectedFramesForBalance = useSelector(getSelectedFramesForBalance, shallowEqual);
    const sensors = useSelector(getCurrentBhomeSensors, shallowEqual);
    const unsavedActivities = useSelector(getUnsavedActivities, arrayOfObjectsShallowEqual);
    const flags = useFeatureFlag();
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const modeInProgress = useSelector(getModeInProgress, arrayOfObjectsShallowEqual);
    const showFramesHistory = useSelector(getShowFramesHistory);
    const isBalanceHiveModeShown = useSelector(getIsBalanceHiveModeShown);
    const stations = useSelector(getStations, shallowEqual);
    const frames = useSelector(getBhomeFrames, arrayOfObjectsShallowEqual);
    const isHistoryLayoutSnapshotShown = useSelector(getIsHistoryLayoutSnapshotShown);

    useEffect(() => {
        if (!currentBhome?.id) {
            return;
        }

        dispatch(fetchModeInProgress(currentBhome?.id));
        dispatch(setSelectedFramesForBalance({}));
        dispatch(setBalanceHiveMode(false));
    }, [currentBhome?.id, dispatch]);

    useEffect(() => {
        const selectedFramesForBalanceKeysLength = Object.keys(selectedFramesForBalance).length;

        if (selectedFramesForBalanceKeysLength === 2) {
            dispatch(setActionMode(constants.COMMANDS.BALANCE_HIVES));
            dispatch(
                addUnsavedActivities([
                    getBalanceHiveCommand(...Object.values(selectedFramesForBalance), {
                        stations,
                        frames,
                        settings: currentBhome.settings,
                    }),
                ])
            );
        } else if (selectedFramesForBalanceKeysLength === 1) {
            dispatch(setActionMode(null));
        }
    }, [currentBhome.settings, dispatch, frames, selectedFramesForBalance, stations]);

    useEffect(() => {
        const modeState = Object.entries(currentBhome?.mode || {}).reduce(
            (acc, [key, value]) => {
                if (value) {
                    return {
                        activeMode: key,
                        hasActionMode: true,
                        isSpecialModeActive: true,
                    };
                }
                return acc;
            },
            { activeMode: '', hasActionMode: false, isSpecialModeActive: false }
        );

        setActiveMode(modeState.activeMode);
        setHasActiveMode(modeState.hasActionMode);
        setIsSpecialModeActive(modeState.isSpecialModeActive);
    }, [currentBhome?.mode]);

    useEffect(() => {
        const command = modeToCommandMapping[activeMode];

        if (command) {
            dispatch(setActionMode(command));
        }
    }, [activeMode, dispatch]);

    const updateProgressMode = useCallback(currentBhomeProgressMode => {
        const activeMode = () => {
            if (currentBhomeProgressMode.command === constants.COMMANDS.MOVE_TO_VISIT_POSITION) {
                return currentBhomeProgressMode?.payload?.position_to_move_to === ACTION_END
                    ? constants.MODE.VISIT
                    : constants.MODE.SUPER;
            } else {
                return constants.MODE.TRANSPORTATION;
            }
        };
        const startTime = new Date(currentBhomeProgressMode.sent_at).getTime();
        const endTime = new Date(startTime + WAITING_TIME * 60000).getTime();
        const currentTime = Date.now();
        const duration = endTime - startTime;
        const elapsed = currentTime - startTime;
        const newCompleted = Math.min(100, (elapsed / duration) * 100);

        if (currentTime >= endTime) {
            setCompleted(100);
        } else {
            setIsSpecialModeActive(true);
            setCompleted(newCompleted);
            setActiveMode(activeMode);
        }
    }, []);

    useEffect(() => {
        const currentBhomeProgressMode = modeInProgress[modeInProgress.length - 1];

        if (!currentBhomeProgressMode) {
            return;
        }

        if (
            currentBhomeProgressMode?.command === constants.COMMANDS.END_TRANSPORTATION_MODE ||
            currentBhomeProgressMode?.command === constants.COMMANDS.END_VISIT_MODE
        ) {
            setIsSpecialModeActive(false);
            setActiveMode('');
            return;
        }

        const intervalId = setInterval(() => updateProgressMode(currentBhomeProgressMode), 1000);

        return () => clearInterval(intervalId);
    }, [modeInProgress, updateProgressMode]);

    const handleSpecialMode = useCallback(() => {
        setIsSpecialModeActive(false);
        setActiveMode('');
        dispatch(
            modifyMode({
                bhomeId: currentBhome?.id,
                mode: activeMode,
                action: ACTION_END,
                resolver: () => {
                    dispatch(fetchCurrentBhome(currentBhome?.id));
                    dispatch(fetchModeInProgress(currentBhome?.id));
                },
            })
        );
    }, [activeMode, currentBhome?.id, dispatch]);

    useEffect(() => {
        if (completed < 100) {
            return;
        }

        setIsSpecialModeActive(false);
        dispatch(fetchCurrentBhome(currentBhome?.id));
    }, [completed, currentBhome?.id, dispatch]);

    const handleDiscardActivities = useCallback(() => {
        dispatch(clearUnsavedActivities());
        dispatch(setActionMode(null));
        dispatch(setEntrancesToOperate([]));
        dispatch(setFrameToInspect({}));
        hideDiscardModal();
    }, [dispatch, hideDiscardModal]);

    const handleNoActionMode = useCallback(
        command => {
            dispatch(setActionMode(command));
        },
        [dispatch]
    );

    const handleExistingActionMode = useCallback(
        command => {
            if (actionMode === command) {
                dispatch(setActionMode(null));
                dispatch(setEntrancesToOperate([]));
                dispatch(clearUnsavedActivities());
            } else {
                dispatch(setActionMode(command));
            }
        },
        [actionMode, dispatch]
    );

    const handleActionClick = useCallback(
        (command, isFlagEnabled) => () => {
            const entityType = 'bhome';
            const isSpecialModeCommand =
                command === constants.COMMANDS.MOVE_TO_VISIT_POSITION ||
                command === constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION;

            if (isSpecialModeCommand) {
                const mode =
                    command === constants.COMMANDS.MOVE_TO_VISIT_POSITION
                        ? constants.MODE.VISIT
                        : constants.MODE.TRANSPORTATION;

                dispatch(
                    modifyMode({
                        bhomeId: currentBhome?.id,
                        mode,
                        action: ACTION_START,
                        resolver: () => {
                            dispatch(fetchModeInProgress(currentBhome?.id));
                        },
                    })
                );
            }

            if (!isFlagEnabled) {
                return;
            }

            if (!actionMode) {
                handleNoActionMode(command, entityType);
            } else if (!unsavedActivities.length) {
                handleExistingActionMode(command, entityType);
            }
        },
        [actionMode, currentBhome?.id, dispatch, handleExistingActionMode, handleNoActionMode, unsavedActivities.length]
    );

    const handleToggleFramesHistory = useCallback(() => dispatch(toggleFramesHistory()), [dispatch]);
    const handleToggleHiveManagementModal = useCallback(() => {
        isBalanceHiveModeShown && dispatch(setBalanceHiveMode(false));
        dispatch(toggleHiveManagementModal());
    }, [dispatch, isBalanceHiveModeShown]);
    const handleToggleBalanceHiveMode = useCallback(
        () => dispatch(setBalanceHiveMode(!isBalanceHiveModeShown)),
        [dispatch, isBalanceHiveModeShown]
    );

    const hiveManagementMenuItems = useMemo(
        () => [
            {
                label: 'Arrange Hive',
                description: "Select a hive and relocate frames within the colony or to & from the hive's storage area",
                onClick: handleToggleHiveManagementModal,
            },
            {
                label: 'Balance Hive',
                description: 'Select two frames and swap between them',
                onClick: handleToggleBalanceHiveMode,
            },
        ],
        [handleToggleBalanceHiveMode, handleToggleHiveManagementModal]
    );

    const actionsToRender = useMemo(
        () =>
            actionsToShow.map(command =>
                renderActionIconWithTooltip({
                    command,
                    actionMode,
                    hasUnsavedActivities: !!unsavedActivities.length,
                    onClick: handleActionClick(command, flags[command]),
                    isFlagEnabled: flags[command],
                    allowedActions,
                    sensors,
                    hiveManagementMenuItems,
                    flags,
                    isSpecialModeActive,
                    isHistoryLayoutSnapshotShown,
                })
            ),
        [
            actionMode,
            allowedActions,
            flags,
            handleActionClick,
            hiveManagementMenuItems,
            isSpecialModeActive,
            sensors,
            unsavedActivities.length,
            isHistoryLayoutSnapshotShown,
        ]
    );

    const progressBarContent = useMemo(() => {
        if (!isSpecialModeActive) {
            return;
        }

        const isInProgress = completed < 100 && !hasActiveMode;
        const visitModeText =
            activeMode === constants.MODE.VISIT
                ? `Beehome will automatically end ${activeMode} by the end of the day tomorrow.`
                : '';
        let title;

        if (isInProgress) {
            title = `This Beehome is preparing for a ${activeMode}. Actions cannot be done while the Beehome is in ${activeMode} mode.`;
        } else if (activeMode === constants.MODE.SUPER) {
            title = `The Beehome is ready for ${activeMode}s. It is not performing any activities while the ${activeMode}s are in place.`;
        } else {
            title = `This Beehome is ready for a ${activeMode}. Beehome is not performing any activities while in ${activeMode} mode. ${visitModeText}`;
        }
        const buttonText = isInProgress ? `Cancel ${activeMode}` : `End ${activeMode} now`;

        return (
            <ProgressBlock
                title={title}
                buttonText={buttonText}
                progressBar={isInProgress}
                handleSpecialMode={handleSpecialMode}
                completed={completed}
            />
        );
    }, [isSpecialModeActive, completed, hasActiveMode, activeMode, handleSpecialMode]);

    return (
        <>
            <div
                className={cx('bhome-actions', 'is-bh4', {
                    'is-action-mode': !!actionMode,
                })}
            >
                {actionsToRender}
                <div className="bhome-actions-text">{actionTextToRender(actionMode)}</div>
                {renderShowHistoryIconWithTooltip(showFramesHistory, handleToggleFramesHistory)}
                {discardModalShown && (
                    <DiscardConfirmationModal
                        onCancel={hideDiscardModal}
                        isOpen={discardModalShown}
                        onDiscard={handleDiscardActivities}
                    />
                )}
            </div>
            {progressBarContent}
        </>
    );
};

export default BhomeActions;
