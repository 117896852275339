import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import TextField from '@beewise/text-field';

import { useDispatch } from 'react-redux';
import { fetchUpdateCompanyLocation } from 'components/views/Dashboard/actions';
import './EditLocationForm.scss';

const MAX_LOCATION_NAME_LENGTH = 30;

const EditLocationForm = ({ name, id, onClose }) => {
    const dispatch = useDispatch();
    const locationNameRef = useRef(null);

    const [locationNewName, setLocationNewName] = useState(name || '');
    const handleLocationSave = () => {
        if (locationNewName.trim() !== name) {
            dispatch(fetchUpdateCompanyLocation(id, { locationName: locationNewName }));
            onClose();
        }
    };

    const handleNameKeyDown = e => {
        if (e.keyCode === 13) {
            handleLocationSave();
        }
    };

    useEffect(() => {
        locationNameRef.current?.focus?.();
    }, []);

    return (
        <>
            <TextField
                size="small"
                onClick={locationNameRef.current?.focus}
                onChange={setLocationNewName}
                onKeyDown={handleNameKeyDown}
                placeholder={locationNewName}
                ref={locationNameRef}
                maxLength={MAX_LOCATION_NAME_LENGTH}
            />
            <div className="edit-location-card-actions">
                <Button className="btn primary-blue shadow" onClick={handleLocationSave} disabled={!locationNewName}>
                    Save
                </Button>
                <Button className="btn secondary-blue shadow" onClick={onClose}>
                    Cancel
                </Button>
            </div>
        </>
    );
};

EditLocationForm.propTypes = {
    name: PropTypes.string,
    onClose: PropTypes.func,
    id: PropTypes.string,
};

export default EditLocationForm;
