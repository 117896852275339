import React, { useMemo } from 'react';
import { isNil, capitalize } from 'lodash-es';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { framesUtils } from '@beewise/utils';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import Menu from '@beewise/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowDownRight, faCircleArrowUpRight, faMinus } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';
import useGetHiveBroodBeeAmounts from 'utils/useGetHiveBroodBeeAmounts';
import { getCombFramesWithinHive } from 'utils';
import { getCurrentBhomeFrames } from 'components/views/BeeHome/selectors';
import { getIsLimitedViewCompany } from 'selectors';
import constants from 'appConstants';

const StationAssessment = ({ hive, index, menuOptions }) => {
    const frames = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);
    const framesInCurrentHive = useMemo(() => getCombFramesWithinHive(frames, hive), [frames, hive]);
    const isLimitedView = useSelector(getIsLimitedViewCompany);

    const {
        broodFramesAmount,
        historyBroodFramesAmount,
        beeFramesAmount,
        historyBeeFramesAmount,
        lastScanDate,
        lastBeeScanDate,
        framesAmount,
    } = useGetHiveBroodBeeAmounts(hive);

    const hasEggs = useMemo(
        () =>
            framesInCurrentHive.some(
                frame =>
                    (framesUtils?.getElementSum(frame, 'larva_0_to_1') || 0) +
                        (framesUtils?.getElementSum(frame, 'larva_2_to_3') || 0) >
                    0
            ),
        [framesInCurrentHive]
    );

    const getBroodIcon = () => {
        if (broodFramesAmount === historyBroodFramesAmount) {
            return faMinus;
        }
        return broodFramesAmount >= historyBroodFramesAmount ? faCircleArrowUpRight : faCircleArrowDownRight;
    };

    const getPopulationIcon = () => {
        if ((beeFramesAmount ?? 0) === (historyBeeFramesAmount ?? 0)) {
            return faMinus;
        }
        return beeFramesAmount >= historyBeeFramesAmount ? faCircleArrowUpRight : faCircleArrowDownRight;
    };

    const dateToShow = Math.max(lastScanDate, lastBeeScanDate);
    const strengthRowText =
        hive?.assessment === constants.HIVE_ASSESSMENT.REPOPULATED
            ? capitalize(hive?.assessment)
            : `Strength: ${hive?.assessment || '-'}`;

    return (
        <div className="station-assessment-tooltip">
            <div className="station-assessment-tooltip-title">
                Hive {index + 1} ({hive?.station?.toUpperCase()})
                <Menu options={menuOptions} />
            </div>
            {!isLimitedView && <div>{strengthRowText}</div>}
            <div>Scan date: {dateToShow ? new Date(dateToShow).toLocaleDateString() : '-'}</div>
            {!isLimitedView && (
                <>
                    <div>
                        {framesAmount} frame{framesAmount ? '' : 's'}
                    </div>
                    <div>
                        <FontAwesomeIcon
                            className={cx('station-assessment-tooltip-icon', {
                                'station-assessment-tooltip-icon-success': hasEggs,
                                'station-assessment-tooltip-icon-failed': !hasEggs,
                            })}
                            icon={hasEggs ? faCircleCheck : faCircleXmark}
                        />{' '}
                        Eggs
                    </div>
                    <div>
                        <FontAwesomeIcon
                            className={cx('station-assessment-tooltip-icon', {
                                'station-assessment-tooltip-icon-success': broodFramesAmount > historyBroodFramesAmount,
                                'station-assessment-tooltip-icon-failed': broodFramesAmount < historyBroodFramesAmount,
                            })}
                            icon={getBroodIcon()}
                        />{' '}
                        Brood {broodFramesAmount}
                    </div>
                </>
            )}
            <div>
                <FontAwesomeIcon
                    className={cx('station-assessment-tooltip-icon', {
                        'station-assessment-tooltip-icon-success': beeFramesAmount > historyBeeFramesAmount,
                        'station-assessment-tooltip-icon-failed': beeFramesAmount < historyBeeFramesAmount,
                    })}
                    icon={getPopulationIcon()}
                />{' '}
                Population {isNil(beeFramesAmount) ? '-' : beeFramesAmount}
            </div>
        </div>
    );
};

StationAssessment.propTypes = {
    hive: PropTypes.shape({
        from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        station: PropTypes.string,
        assessment: PropTypes.string,
    }),
    index: PropTypes.number,
    menuOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default StationAssessment;
