import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { noop } from 'lodash-es';
import './Toggle.scss';

const ToggleSwitch = ({
  id,
  name,
  value,
  onChange = noop,
  disabled,
  className,
  label,
  ...props
}) => {
  const handleKeyPress = (e) => {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(e.target.checked);
  };

  const handleSetIsChecked = (e) => {
    onChange(e.target.checked, e);
  };

  return (
    <label className={cn('beewise-toggle', className)}>
      <input
        id={id}
        type="checkbox"
        className="checkbox"
        name={name}
        checked={value}
        onChange={handleSetIsChecked}
        onKeyDown={handleKeyPress}
        disabled={disabled}
        {...props}
      />
      <span htmlFor={name} className="switch" />
      {label && <span className="label">{label}</span>}
    </label>
  );
};

ToggleSwitch.defaultProps = {
  disabled: false
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool
};

export default ToggleSwitch;
