import React from 'react';
import getPolygonCenterCoordinates from 'utils/getPolygonCenterCoordinates';
import getRanchTopRightCornerCoordinates from 'utils/getRanchTopRightCornerCoordinates';
import BlockLabel from '../BlockLabel';
import LoadingZoneIcon from '../LoadingZoneIcon';

const isMarkerInBounds = (bounds, lat, lng) => {
    if (!bounds) {
        return false;
    }

    return bounds.contains({ lat, lng });
};

export const renderBlockLabels = ({ blocks, bounds }) =>
    blocks?.map(({ polygon, name, id }) => {
        const centerCoordinates = getPolygonCenterCoordinates(polygon);

        if (!isMarkerInBounds(bounds, centerCoordinates.lat, centerCoordinates.lng)) {
            return;
        }

        return <BlockLabel {...centerCoordinates} key={id} name={name} />;
    });

export const renderRanchNameLabels = ({ name, blocks, bounds }) => {
    const centerCoordinates = getRanchTopRightCornerCoordinates(blocks);

    if (!isMarkerInBounds(bounds, centerCoordinates.lat, centerCoordinates.lng)) {
        return;
    }

    return <BlockLabel {...centerCoordinates} key={JSON.stringify(centerCoordinates)} name={name} />;
};

const renderAdditionalMapMarkers = ({ ranches, bounds }) =>
    ranches?.reduce((acc, { blocks, loadingZoneCoordinates, name }) => {
        const labelMarkers = renderBlockLabels({ blocks, bounds });
        const ranchesNames = renderRanchNameLabels({ name, blocks, bounds });

        if (loadingZoneCoordinates) {
            const coords = { lat: loadingZoneCoordinates.lat, lng: loadingZoneCoordinates.lng };

            if (isMarkerInBounds(bounds, coords.lat, coords.lng)) {
                acc.push(<LoadingZoneIcon {...coords} key={JSON.stringify(coords)} />);
            }
        }

        return [...acc, ...labelMarkers, ranchesNames];
    }, []);

export default renderAdditionalMapMarkers;
