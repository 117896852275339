import React, { memo } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getFilteredBhomes } from 'components/views/BeeHome/selectors';
import './Footer.scss';

const DIRECTIONS = {
    PREVIOUS: 'previous',
    NEXT: 'next',
};

const Footer = () => {
    const navigate = useNavigate();
    const params = useParams();

    const filteredBhomes = useSelector(getFilteredBhomes, arrayOfObjectsShallowEqual);

    const currentIndex = filteredBhomes.findIndex(bhome => bhome.id === +params.id);

    const isFirstBhome = currentIndex === 0;
    const isLastBhome = currentIndex === filteredBhomes.length - 1;

    const navigateToBhome = direction => () => {
        let newIndex;
        if (direction === DIRECTIONS.PREVIOUS && !isFirstBhome) {
            newIndex = currentIndex - 1;
        } else if (direction === DIRECTIONS.NEXT && !isLastBhome) {
            newIndex = currentIndex + 1;
        }
        if (newIndex !== undefined) {
            const bhomeToNavigate = filteredBhomes[newIndex];
            navigate(`/bhome/${bhomeToNavigate.id}`);
        }
    };

    if (currentIndex === -1) {
        return null;
    }

    return (
        <div className="footer">
            <div
                className={cn('footer-side', { hidden: isFirstBhome })}
                role="presentation"
                onClick={navigateToBhome(DIRECTIONS.PREVIOUS)}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div className="footer-main">
                {currentIndex + 1} / {filteredBhomes.length}
            </div>
            <div
                className={cn('footer-side', { hidden: isLastBhome })}
                role="presentation"
                onClick={navigateToBhome(DIRECTIONS.NEXT)}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </div>
    );
};

export default memo(Footer);
