import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import './ThermalGraph.scss';

const calculateMinMax = data => {
    const xValues = data.map(d => d.x);
    const yValues = data.map(d => parseFloat(d.normalizedTemp));

    return {
        xMin: Math.min(...xValues),
        xMax: Math.max(...xValues),
        yMin: Math.min(...yValues),
        yMax: Math.max(...yValues),
    };
};

const drawGraph = (context, data, minMax, dimensions, isHistoryData) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    const { width, height, padding } = dimensions;
    const { xMin, xMax, yMin, yMax } = minMax;

    context.beginPath();

    let prevX = null;
    let prevY = null;
    let hasLine = false;

    data.forEach(point => {
        const x = ((point.x - xMin) / (xMax - xMin)) * (width - 2 * padding) + padding;
        const y =
            height - padding - ((parseFloat(point.normalizedTemp) - yMin) / (yMax - yMin)) * (height - 2 * padding);

        if (Number.isNaN(x) || Number.isNaN(y)) {
            return;
        }

        if (prevX !== null && prevY !== null) {
            if (Math.abs(x - prevX) > 0.1 || Math.abs(y - prevY) > 0.1) {
                context.lineTo(x, y);
                hasLine = true;
            } else {
                context.moveTo(x, y);
            }
        } else {
            context.moveTo(x, y);
        }

        prevX = x;
        prevY = y;
    });

    // graph line settings
    context.strokeStyle = isHistoryData ? '#941D4B' : '#F84D4D';
    context.lineWidth = 1.5;
    context.stroke();

    // dashed centered line settings
    context.beginPath();
    const centerY = height / 2;
    context.setLineDash([6, 8]);
    context.moveTo(padding, centerY);
    context.lineTo(width - padding, centerY);
    context.strokeStyle = 'rgba(0, 0, 0, 0.4)';
    context.lineWidth = 0.8;
    hasLine && context.stroke();

    // reset line dash settings so other drawings are not affected
    context.setLineDash([]);
};

const ThermalGraph = ({ data, historyData }) => {
    const canvasRef = useRef(null);
    const historyCanvasRef = useRef(null);

    const handleResize = useCallback(() => {
        const canvas = canvasRef.current;
        const parent = canvas.parentElement;

        const width = parent.offsetWidth;
        const height = parent.offsetHeight;
        const padding = 10;

        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');
        const minMax = calculateMinMax(data);

        drawGraph(context, data, minMax, { width, height, padding });

        const historyCanvas = historyCanvasRef.current;
        historyCanvas.width = width;
        historyCanvas.height = height;
        const historyContext = historyCanvas.getContext('2d');
        if (historyData.length) {
            const historyMinMax = calculateMinMax(historyData);
            drawGraph(historyContext, historyData, historyMinMax, { width, height, padding }, true);
        } else {
            historyContext.clearRect(0, 0, width, height);
        }
    }, [data, historyData]);

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 100);
        window.addEventListener('resize', debouncedHandleResize);

        handleResize();

        return () => window.removeEventListener('resize', debouncedHandleResize);
    }, [handleResize]);

    return (
        <div className="thermal-graph">
            <canvas ref={canvasRef} />
            <canvas ref={historyCanvasRef} />
        </div>
    );
};

ThermalGraph.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            station: PropTypes.string,
            x: PropTypes.number,
            normalizedTemp: PropTypes.number,
        })
    ).isRequired,
    historyData: PropTypes.arrayOf(
        PropTypes.shape({
            station: PropTypes.string,
            x: PropTypes.number,
            normalizedTemp: PropTypes.number,
        })
    ),
};

export default ThermalGraph;
