export const SIZES = {
  NORMAL: 'normal',
  SMALL: 'small',
  XSMALL: 'xsmall'
};

export const SIZE_VALUES = {
  [SIZES.NORMAL]: 54,
  [SIZES.SMALL]: 40,
  [SIZES.XSMALL]: 30
};

export const TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  PERCENTAGE: 'percentage',
  FILE: 'file'
};

export const POSITIONS = {
  START: 'start',
  END: 'end'
};
