import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { updateBhomeNote } from 'components/views/BeeHome/actions';
import { fetchUpdateCompanyAlerts } from 'components/reusables/Alerts/actions';
import BeekeepersEntry from './BeekeepersEntry';

import './Panes.scss';

const ITEMS_PER_PAGE = 2;

const BeekeepersEntriesPane = ({ beekeepersEntries, onEdit, inspectedFrame }) => {
    const dispatch = useDispatch();

    const [paginationCounter, setPaginationCounter] = useState(1);

    const handleShowMoreClick = useCallback(() => {
        setPaginationCounter(prevValue => prevValue + 1);
    }, []);

    const dataToShow = useMemo(
        () => beekeepersEntries.slice(0, paginationCounter * ITEMS_PER_PAGE),
        [beekeepersEntries, paginationCounter]
    );

    const handleMakeEntryRead = useCallback(
        entry => () => {
            if (!entry.type) {
                dispatch(updateBhomeNote(entry.id, entry.note, constants.ALERT_STATUSES.READ));
            } else {
                dispatch(
                    fetchUpdateCompanyAlerts([entry.id], {
                        status: constants.ALERT_STATUSES.READ,
                    })
                );
            }
        },
        [dispatch]
    );

    return (
        <div className="beekeepers-entries-pane">
            {dataToShow.map(entry => (
                <BeekeepersEntry
                    key={entry.id}
                    entry={entry}
                    onEdit={onEdit}
                    onDelete={handleMakeEntryRead}
                    inspectedFrame={inspectedFrame}
                />
            ))}
            {beekeepersEntries.length > paginationCounter * ITEMS_PER_PAGE && (
                <div className="pagination-button" role="presentation" onClick={handleShowMoreClick}>
                    <FontAwesomeIcon icon={faAngleDown} className="pagination-button-arrow" /> Show more
                </div>
            )}
        </div>
    );
};

BeekeepersEntriesPane.propTypes = {
    beekeepersEntries: PropTypes.arrayOf(PropTypes.shape()),
    onEdit: PropTypes.func,
    inspectedFrame: PropTypes.shape(),
};

export default BeekeepersEntriesPane;
