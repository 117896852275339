import { COMMANDS } from '@beewise/constants';
import {
    faCamera,
    faArrowFromLeft,
    faWeight,
    faBug,
    faRectangleLandscape,
    faDroplet,
    faScaleBalanced,
} from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';

const actionData = {
    [COMMANDS.SCAN]: {
        name: 'Scan frames',
        icon: faCamera,
        time: 8.4,
        tooltips: {
            info: {
                id: 'scan-frames-info',
                header: 'Scan frames',
                title: 'Select the frames you wish to scan',
                content: 'You can also select other hives, then their frames.',
            },
        },
        buttonText: 'Scan',
    },
    [COMMANDS.COUNT_BEES]: {
        name: 'Bee scan',
        icon: faCamera,
        time: 5,
        tooltips: {
            info: {
                id: 'scan-frames-info',
                header: 'Scan frames',
                title: 'Select the frames you wish to bee scan',
                content: 'You can also select other hives, then their frames.',
            },
        },
        buttonText: 'Bee scan',
    },
    [COMMANDS.FILL_FEEDER]: {
        name: 'Feed',
        icon: faDroplet,
        time: 8.5,
        tooltips: {
            info: {
                id: 'feed-info',
                header: 'Feed',
                title: 'Select the feeders to fill',
                content: 'You can also select other hives, then their feeders.',
            },
        },
        buttonText: 'Feed',
    },
    [COMMANDS.MOVE_FRAMES]: {
        name: 'Move frames',
        icon: faArrowFromLeft,
        time: 9.4,
        tooltips: {
            info: {
                id: 'move-frames-info',
                header: 'Move frames',
                title: 'Drag the frame you wish to move and drop it onto the target slot',
                content: "Use a hive's pin button to open it beside this pane and select its frames",
            },
        },
        buttonText: 'Move Frames',
    },
    pestTreatment: {
        name: 'Pest Treatment',
        icon: faBug,
        time: 1,
        notEnabled: true,
        buttonText: 'Pest Treatment',
    },
    weight: {
        name: 'Weight',
        icon: faWeight,
        time: 1,
        notEnabled: true,
        buttonText: 'Weight',
    },
    [constants.COMMANDS.MARK_HIVES]: {
        name: 'Mark hives',
        icon: faRectangleLandscape,
        time: null,
        buttonText: 'Mark hives',
    },
    [constants.COMMANDS.HIVE_MANAGEMENT]: {
        name: 'Hive management',
        buttonText: 'Hive management',
    },
    [constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION]: {
        name: 'Transportation',
        buttonText: 'Transportation',
    },
    [constants.COMMANDS.MOVE_TO_VISIT_POSITION]: {
        name: 'Visit',
        buttonText: 'Visit',
    },
    [constants.COMMANDS.BALANCE_HIVES]: {
        name: 'Balance Hive',
        icon: faScaleBalanced,
        buttonText: 'Balance Hive',
        time: 18,
    },
};

export const getActionData = actionMode => actionData[actionMode];
