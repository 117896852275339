import { SET_THERMAL_SCAN_DATA, SET_CURRENT_SNAPSHOT_DATE, SET_HISTORY_THERMAL_SCAN_DATA } from '../actionTypes';

export const setThermalScanData = ({ thermalScanData }) => ({
    type: SET_THERMAL_SCAN_DATA,
    data: thermalScanData,
});

export const setHistoryThermalScanData = ({ historyThermalScanData }) => ({
    type: SET_HISTORY_THERMAL_SCAN_DATA,
    data: historyThermalScanData,
});

export const setCurrentSnapshotDate = ({ date }) => ({
    type: SET_CURRENT_SNAPSHOT_DATE,
    data: date,
});
