import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import PermissionsBlock from '../PermissionsBlock';

const Team = ({ toggleInviteModal, getUserPermissions, members, togglePermission }) => (
    <div className="settings-item">
        <div className="settings-item-header">
            <h2>Team</h2>
            <Button className="invite-button" iconType="user-plus" onClick={toggleInviteModal}>
                Invite User
            </Button>
        </div>
        <PermissionsBlock
            getUserPermissions={getUserPermissions}
            members={members}
            togglePermission={togglePermission}
        />
    </div>
);

Team.propTypes = {
    toggleInviteModal: PropTypes.func.isRequired,
    getUserPermissions: PropTypes.func.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape()),
    togglePermission: PropTypes.func.isRequired,
};

export default Team;
