import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import cx from 'classnames';
import { isUndefined } from 'lodash-es';
import { getCurrentBhomeGridData } from 'components/views/Dashboard/selectors';
import Badge, { BADGE_TYPE, BADGE_FORM, SIZE } from 'components/reusables/Badge';
import BhomeIconItem from '../BhomeIconItem';
import './BhomeItem.scss';

const getGridValue = (grid, key) => (grid?.[key] ? `${grid?.[key].toFixed(1)}` : '-');

const getDefaultBadgeType = value => (value ? BADGE_TYPE.ERROR : BADGE_TYPE.DEFAULT);

const BADGE_PROPS = {
    form: BADGE_FORM.SQUARE,
    size: SIZE.SMALL,
};

const BhomeItem = ({ bhome, handleItemClick, isActive, syrupLevel, style }) => {
    const currentBhomeGridData = useSelector(state => getCurrentBhomeGridData(state, bhome?.id), shallowEqual);

    const handleClick = () => {
        handleItemClick(bhome);
    };

    return (
        <div className={cx('bhome-item', { active: isActive })} onClick={handleClick} role="presentation" style={style}>
            <div className="bhome-item-header">
                <div className="bhome-item-header-title">#{bhome?.id}</div>
                <div className="bhome-item-header-icons">
                    {!!currentBhomeGridData?.totalAmountOfEmptyBroodHives && (
                        <Badge
                            {...BADGE_PROPS}
                            icon="larvae-missing"
                            type={getDefaultBadgeType(currentBhomeGridData?.totalAmountOfEmptyBroodHives)}
                        >
                            {currentBhomeGridData?.totalAmountOfEmptyBroodHives} %
                        </Badge>
                    )}
                    {!isUndefined(currentBhomeGridData?.syrupLevel) && (
                        <Badge {...BADGE_PROPS} icon="syrup" type={getDefaultBadgeType(syrupLevel <= 20)}>
                            {syrupLevel}%
                        </Badge>
                    )}
                </div>
            </div>
            <div className="bhome-item-wrapper">
                <div className="bhome-item-block">
                    <BhomeIconItem
                        icon="cell"
                        title="Empty"
                        data={getGridValue(currentBhomeGridData, 'averageEmpty')}
                    />
                    <BhomeIconItem
                        icon="bee-simple"
                        title="Bee"
                        data={getGridValue(currentBhomeGridData, 'averagePopulation')}
                    />
                </div>
                <div className="bhome-item-block">
                    <BhomeIconItem
                        icon="brood"
                        title="Brood"
                        data={getGridValue(currentBhomeGridData, 'averageBrood')}
                    />
                    <BhomeIconItem
                        icon="honey"
                        title="Honey"
                        data={getGridValue(currentBhomeGridData, 'averageHoney')}
                    />
                </div>
            </div>
        </div>
    );
};

BhomeItem.propTypes = {
    bhome: PropTypes.shape(),
    style: PropTypes.shape(),
    handleItemClick: PropTypes.func,
    isActive: PropTypes.bool,
    syrupLevel: PropTypes.number,
};

export default memo(BhomeItem);
