import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import cx from 'classnames';
import { PREFERENCE_NAMES } from '../../utils';

const PreferencesListItem = ({ option }) => {
    const isBeeCount = option.name === PREFERENCE_NAMES.BEE_COUNT_SCAN_POLICY;
    return (
        <div className="preferences-list-item">
            <div className="item-content">
                <div className="item-header">
                    <div className="item-name">{option.name}</div>
                    {!!option.willComingSoon && <div className="item-label">Coming soon</div>}
                </div>
                <div className="item-description">{option.description}</div>
                <div className="item-extra-options">{option.extraOptions}</div>
            </div>
            <div className="filler" />
            <div className="item-controls">
                <Button
                    className={cx('item-button', {
                        'button-active': option.value === true,
                        'button-loading': typeof option.value === 'undefined',
                    })}
                    onClick={option.onChange(true)}
                >
                    {isBeeCount ? 'On' : 'Yes'}
                </Button>
                <Button
                    className={cx('item-button', {
                        'button-active': option.value === false,
                        'button-loading': typeof option.value === 'undefined',
                    })}
                    onClick={option.onChange(false)}
                >
                    {isBeeCount ? 'Off' : 'No'}
                </Button>
            </div>
        </div>
    );
};

PreferencesListItem.propTypes = {
    option: PropTypes.shape(),
};

const PreferencesList = ({ options }) => (
    <div className="preferences-list">
        {options.map((option, index) => (
            <PreferencesListItem key={`${option.name}_${index}`} option={option} />
        ))}
    </div>
);

PreferencesList.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape()),
};

export default PreferencesList;
