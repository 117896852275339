import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes as DomRoutes, Route } from 'react-router-dom';
import { AnalProvider, PermissionsProvider, SuspenseLoading } from '@beewise/react-utils';
import PrivateRoute from 'components/reusables/PrivateRoute';
import PublicRoute from 'components/reusables/PublicRoute';
import AdminRoute from 'components/reusables/AdminRoute';
import AppHeader from 'components/reusables/AppHeader';
import AdminProvider from 'components/reusables/AdminProvider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend as Backend } from 'react-dnd-html5-backend';
import { UI_ENV, TRACKING_CODE } from 'config';
import Dashboard from 'components/views/Dashboard';
import BeeHome from 'components/views/BeeHome';
import Bhome from 'components/views/Bhome';
import Settings from 'components/views/Settings';
import Reports from 'components/views/Reports';
import ToastWrapper from './components/reusables/ToastWrapper';

const SignIn = lazy(() => import('components/views/SignIn'));
const Admin = lazy(() => import('components/views/Admin'));
const NotFound = lazy(() => import('components/views/NotFound'));
const Landing = lazy(() => import('components/views/Landing'));
const ChangePassword = lazy(() => import('components/views/PasswordSetup'));
const Workspace = lazy(() => import('components/views/Workspace'));
const BhomePublic = lazy(() => import('components/views/BhomePublic'));

const Routes = () => (
    <Router>
        <ToastWrapper />
        <PermissionsProvider app="beekeeper" refreshUserToken showChildrenBeforeRefresh={false}>
            <AnalProvider UI_ENV={UI_ENV} trackingCode={TRACKING_CODE}>
                <AdminProvider>
                    <AppHeader />
                    <section id="view">
                        <DndProvider backend={Backend}>
                            <Suspense fallback={<SuspenseLoading />}>
                                <DomRoutes>
                                    <Route path="/admin" element={<AdminRoute component={Admin} />} />
                                    <Route path="/signin" element={<PublicRoute component={SignIn} />} />
                                    <Route
                                        path="/setup-password"
                                        element={<PublicRoute component={ChangePassword} redirectToHome={false} />}
                                    />
                                    <Route path="/" element={<PrivateRoute component={Dashboard} />} />
                                    <Route path="/workspace" element={<PrivateRoute component={Workspace} />} />
                                    <Route path="/beehome" element={<PrivateRoute component={BeeHome} />} />
                                    <Route path="/beehome/:id" element={<PrivateRoute component={BeeHome} />} />
                                    <Route path="/bhome" element={<PrivateRoute component={Bhome} />} />
                                    <Route path="/bhome/:id" element={<PrivateRoute component={Bhome} />} />
                                    <Route path="/settings" element={<PrivateRoute component={Settings} />} />
                                    <Route path="/reports" element={<PrivateRoute component={Reports} />} />
                                    <Route path="*" element={<PrivateRoute component={NotFound} />} />
                                    <Route path="/lp1" element={<Landing />} />
                                    <Route path="/public/:id" element={<BhomePublic />} />
                                </DomRoutes>
                            </Suspense>
                        </DndProvider>
                    </section>
                </AdminProvider>
            </AnalProvider>
        </PermissionsProvider>
    </Router>
);

export default Routes;
