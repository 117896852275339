import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils/build/lib/comparison';
import cx from 'classnames';
import { isUndefined } from 'lodash-es';
import { getGridData } from 'components/views/Dashboard/selectors';
import BhomeIconItem from '../BhomeIconItem';
import './BhomeItem.scss';

const BhomeItem = ({ bhome, handleItemClick, isActive, syrupLevel, style }) => {
    const bhomeGridData = useSelector(getGridData, arrayOfObjectsShallowEqual);
    const [currentBhomeGridData, setCurrentBhomeGridData] = useState(null);

    useEffect(() => {
        if (!bhome?.id) {
            return;
        }

        const currentBhome = bhomeGridData.find(item => item?.id === bhome?.id);
        setCurrentBhomeGridData(currentBhome);
    }, [bhome.id, bhomeGridData]);

    const handleClick = () => {
        handleItemClick(bhome);
    };

    return (
        <div className={cx('bhome-item', { active: isActive })} onClick={handleClick} role="presentation" style={style}>
            <div className="bhome-item-header">
                <div className="bhome-item-header-title">#{bhome?.id}</div>
                <div className="bhome-item-header-icons">
                    {!!currentBhomeGridData?.totalAmountOfEmptyBroodHives && (
                        <BhomeIconItem
                            icon="LarvaeMissing"
                            data={currentBhomeGridData?.totalAmountOfEmptyBroodHives}
                            isLowData={!!currentBhomeGridData?.totalAmountOfEmptyBroodHives}
                        />
                    )}
                    {!isUndefined(currentBhomeGridData?.syrupLevel) && (
                        <BhomeIconItem icon="Syrup" data={`${syrupLevel}%`} isLowData={syrupLevel <= 20} />
                    )}
                </div>
            </div>
            <div className="bhome-item-wrapper">
                <div className="bhome-item-block">
                    <BhomeIconItem
                        icon="Cell"
                        title="Empty"
                        data={
                            currentBhomeGridData?.averageEmpty
                                ? `${currentBhomeGridData?.averageEmpty.toFixed(1)}`
                                : '-'
                        }
                    />
                    <BhomeIconItem
                        icon="BeeSimple"
                        title="Bee"
                        data={
                            currentBhomeGridData?.averagePopulation
                                ? `${currentBhomeGridData?.averagePopulation.toFixed(1)}`
                                : '-'
                        }
                    />
                </div>
                <div className="bhome-item-block">
                    <BhomeIconItem
                        icon="Brood"
                        title="Brood"
                        data={
                            currentBhomeGridData?.averageBrood
                                ? `${currentBhomeGridData?.averageBrood.toFixed(1)}`
                                : '-'
                        }
                    />
                    <BhomeIconItem
                        icon="Honey"
                        title="Honey"
                        data={
                            currentBhomeGridData?.averageHoney
                                ? `${currentBhomeGridData?.averageHoney.toFixed(1)}`
                                : '-'
                        }
                    />
                </div>
            </div>
        </div>
    );
};

BhomeItem.propTypes = {
    bhome: PropTypes.shape(),
    style: PropTypes.shape(),
    handleItemClick: PropTypes.func,
    isActive: PropTypes.bool,
    syrupLevel: PropTypes.number,
};

export default BhomeItem;
