import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faXmark } from '@fortawesome/pro-light-svg-icons';

import './ActivityFailurePopover.scss';

const ActivityFailurePopover = ({ onClose }) => (
    <div className="activity-failure-popover">
        <div className="activity-failure-popover-header">
            <div className="activity-failure-popover-title">Activity failed</div>
            <FontAwesomeIcon icon={faXmark} className="activity-failure-popover-xmark" onClick={onClose} />
        </div>
        <div className="activity-failure-popover-body">
            <FontAwesomeIcon icon={faTriangleExclamation} className="activity-failure-popover-alert-triangle" />
            <div className="activity-failure-popover-content">
                Some activities couldn&apos;t complete. Please review and resend the required ones. <br />
                <br />
                Our technicians look into these issues.
            </div>
        </div>
    </div>
);

ActivityFailurePopover.propTypes = {
    onClose: PropTypes.func,
};

export default ActivityFailurePopover;
