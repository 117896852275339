import './wdyr';

import 'es6-symbol/implement';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import { ModalManager } from '@beewise/modal-manager';
import { modalController } from 'components/reusables/ModalManager';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isBetween from 'dayjs/plugin/isBetween';
import store from 'store';
import { initFullStory } from 'utils/fullStory';
import initSentry from './sentry';
import Routes from './routes';

import './App.scss';

dayjs.extend(utc).extend(isToday).extend(isYesterday).extend(timezone).extend(isBetween);

const Fallback = () => <div />;

initSentry();

const App = () => (
    <Provider store={store}>
        <div id="app">
            <Routes />
            <ModalManager modalController={modalController} Fallback={Fallback} />
        </div>
    </Provider>
);

initFullStory();

const root = createRoot(document.getElementById('root'));
root.render(<App />);
