import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { SelectFormField, InputFormField, VALIDATIONS } from '@beewise/hook-form';
import { yearsOptions } from '../utils';

const HistoryItem = ({ isEditMode, control, historyIndex, watch, setValue, remove }) => {
    const year = watch(`history.${historyIndex}.year`);
    const strongValue = watch(`history.${historyIndex}.strong`);
    const mediumValue = watch(`history.${historyIndex}.medium`);
    const weakValue = watch(`history.${historyIndex}.weak`);
    const lossValue = watch(`history.${historyIndex}.loss`);
    const total = watch(`history.${historyIndex}.total`);

    useEffect(() => {
        if (strongValue || mediumValue || weakValue || total) {
            const sum = (Number(strongValue) ?? 0) + (Number(mediumValue) ?? 0) + (Number(weakValue) ?? 0);
            const total = sum - lossValue;
            setValue(`history.${historyIndex}.total`, total);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strongValue, mediumValue, weakValue, lossValue]);

    const handleDelete = () => {
        remove(historyIndex);
    };

    return (
        <div className="yard-year">
            <div className="yard-header">
                {isEditMode && year ? `Historical Information - ${year}` : 'Add yard historical information'}:
            </div>
            <Button className="secondary-blue remove-year" onClick={handleDelete}>
                Delete
            </Button>
            <div className="yard-section">
                <div className="yard-column">
                    <SelectFormField
                        name={`history.${historyIndex}.year`}
                        control={control}
                        label="Year"
                        options={yearsOptions()}
                        maxMenuHeight={200}
                    />
                    <div className="yard-row">
                        <InputFormField
                            name={`history.${historyIndex}.weak`}
                            control={control}
                            label="#Weak"
                            pattern={VALIDATIONS.ZERO_NUMBER}
                        />
                        <InputFormField
                            name={`history.${historyIndex}.medium`}
                            control={control}
                            label="#Medium"
                            pattern={VALIDATIONS.ZERO_NUMBER}
                        />
                    </div>
                    <InputFormField
                        name={`history.${historyIndex}.total`}
                        control={control}
                        label="Total Hives"
                        disabled
                        pattern={VALIDATIONS.INTEGER_NUMBER}
                        className="history-disabled-item"
                    />
                </div>
                <div className="yard-column">
                    <InputFormField
                        name={`history.${historyIndex}.yield`}
                        control={control}
                        label="Yield (Lbs)"
                        pattern={VALIDATIONS.NUMBER}
                    />
                    <div className="yard-row">
                        <InputFormField
                            name={`history.${historyIndex}.strong`}
                            control={control}
                            label="#Strong"
                            pattern={VALIDATIONS.ZERO_NUMBER}
                        />
                        <InputFormField
                            name={`history.${historyIndex}.loss`}
                            control={control}
                            label="#Loss"
                            pattern={VALIDATIONS.ZERO_NUMBER}
                        />
                    </div>
                    <InputFormField
                        name={`history.${historyIndex}.rate`}
                        control={control}
                        pattern={VALIDATIONS.NUMBER}
                        label="Split success rate (%)"
                    />
                </div>
            </div>
        </div>
    );
};

HistoryItem.propTypes = {
    isEditMode: PropTypes.bool,
    control: PropTypes.shape(),
    watch: PropTypes.func,
    remove: PropTypes.func,
    historyIndex: PropTypes.number,
    setValue: PropTypes.func,
};

export default HistoryItem;
