import React, { memo } from 'react';
import PropTypes from 'prop-types';
import GroupMarkerWrapper from '../GroupMarkerWrapper';
import './YardGroupMarker.scss';

const YardGroupMarker = ({ cluster, onClusterClick, supercluster, map }) => (
    <GroupMarkerWrapper
        onClusterClick={onClusterClick}
        supercluster={supercluster}
        cluster={cluster}
        map={map}
        className="yard-group-marker"
    >
        <span>{cluster?.properties?.point_count ?? 1}</span>
    </GroupMarkerWrapper>
);

YardGroupMarker.propTypes = {
    cluster: PropTypes.shape(),
    onClusterClick: PropTypes.func,
    supercluster: PropTypes.shape(),
    map: PropTypes.shape(),
};

export default memo(YardGroupMarker);
