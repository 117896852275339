import PropTypes from 'prop-types';
import { useFeatureFlag } from '../hooks';

const FeatureFlagWrapper = ({ featureName, children }) => (useFeatureFlag(featureName) ? children : null);

FeatureFlagWrapper.propTypes = {
    featureName: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default FeatureFlagWrapper;
