import React from 'react';

import './Legend.scss';

const contentLegendData = [
    { label: 'Open honey', class: 'opened-honey' },
    { label: 'Pollen', class: 'pollen' },
    { label: 'Eggs', class: 'brood-egg' },
    { label: 'Capped honey', class: 'capped-honey' },
    { label: 'Capped brood', class: 'brood-capped' },
    { label: 'Open brood', class: 'brood-open' },
];

const framesLegendData = [
    { label: 'Static Partition', class: 'static-partition' },
    { label: 'Feeder', class: 'feeder' },
    { label: 'Dynamic partition', class: 'partition' },
    { label: 'Foundation', class: 'foundation' },
    // commented for future usage
    // { label: 'Queen excluder', class: 'queen-excluder' },
];

export const ContentLegend = () => (
    <div className="legend legend-v4">
        <div className="legend-content">
            {contentLegendData.map(item => (
                <div className="legend-content-item" key={`cl-${item.class}`}>
                    <div className={`legend-octagon ${item.class}`} />
                    <div className="legend-content-item-label">{item.label}</div>
                </div>
            ))}
        </div>
    </div>
);

export const ContentLegendSmall = () => (
    <div className="legend-small">
        {contentLegendData.map(item => (
            <div className="legend-content-item" key={`cl-${item.class}`}>
                <div className={`legend-octagon ${item.class}`} />
                <div className="legend-content-item-label">{item.label}</div>
            </div>
        ))}
    </div>
);

export const FrameLegend = () => (
    <div className="legend legend-v4">
        <div className="legend-content frame-legend">
            {framesLegendData.map(item => (
                <div className="legend-content-item" key={`fl-${item.class}`}>
                    <div className={`legend-rect ${item.class}`} />
                    <div className="legend-content-item-label">{item.label}</div>
                </div>
            ))}
        </div>
    </div>
);

export const ExtraLegendData = () => (
    <div className="legend-content-item not-scanned">
        <div className="legend-content-item-center">
            <div className="legend-octagon" />
            <div className="legend-content-item-label">Not scanned</div>
        </div>
    </div>
);
