import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import Activity from './Activity';
import { getGroupedDataToShow } from '../utils';

import './Panes.scss';

const HistoryPane = ({ groupedHistoryData, handleGoInsideHiveOrFrame }) => {
    const [paginationCounter, setPaginationCounter] = useState(1);

    const handleShowMoreClick = useCallback(() => {
        setPaginationCounter(prevValue => prevValue + 1);
    }, []);

    const dataToShow = useMemo(
        () => getGroupedDataToShow(groupedHistoryData, paginationCounter),
        [groupedHistoryData, paginationCounter]
    );

    return (
        <div className="history-pane activities-block">
            <div className="history-pane-list">
                {Object.keys(dataToShow).map(
                    key =>
                        !!dataToShow[key].length && (
                            <React.Fragment key={`history-pane-date-label-${key}`}>
                                <div className="right-panel-pane-date-label">{key}</div>
                                {dataToShow[key].map(activity => (
                                    <Activity
                                        key={activity.renderId || activity.id}
                                        activity={activity}
                                        handleGoInsideHiveOrFrame={handleGoInsideHiveOrFrame}
                                    />
                                ))}
                            </React.Fragment>
                        )
                )}
            </div>
            {Object.values(dataToShow).flat().length > paginationCounter * 5 && (
                <div className="pagination-button" role="presentation" onClick={handleShowMoreClick}>
                    <FontAwesomeIcon icon={faAngleDown} className="pagination-button-arrow" /> Show more
                </div>
            )}
            <div className="populated-areas-warning">
                <FontAwesomeIcon icon={faCircleInfo} className="circle-info" />
                <div className="populated-areas-warning-text">
                    Frame extraction activities that involve populated areas will be carried out during the daytime
                    only. The queue remains for the following day.
                </div>
            </div>
        </div>
    );
};

HistoryPane.propTypes = {
    groupedHistoryData: PropTypes.shape(),
    handleGoInsideHiveOrFrame: PropTypes.func,
};

export default HistoryPane;
