const getRanchTopRightCornerCoordinates = ranchBlocks => {
    const allPolygons = ranchBlocks.flatMap(block => block.polygon);

    if (allPolygons.length === 0) {
        return null;
    }

    const lat = Math.max(...allPolygons.map(({ lat }) => lat));
    const lng = Math.max(...allPolygons.map(({ lng }) => lng));

    return { lat, lng };
};

export default getRanchTopRightCornerCoordinates;
