import constants from 'appConstants';
import { faOctagonExclamation, faTriangleExclamation, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import {
    highPriorityAlerts,
    mediumPriorityAlerts,
    lowPriorityAlerts,
    broodQualityAlerts,
    userAlerts,
} from './components/AlertsList/utils';

export const getAlertsTooltipText = alerts => {
    if (alerts.every(alert => alert.type === constants.ALERT_TYPES.QUEEN_CELLS)) {
        return 'Queen cell';
    }

    if (alerts.every(alert => broodQualityAlerts.includes(alert))) {
        return 'Brood quality';
    }

    if (alerts.length === 1 && userAlerts.includes(alerts[0]?.type) && alerts[0]?.data?.description) {
        return alerts[0].data.description;
    }

    return `${alerts.length} Notification${alerts.length > 1 ? 's' : ''}`;
};

export const getAlertsTriggerIcon = alerts => {
    if (alerts.some(alert => highPriorityAlerts.includes(alert.type))) {
        return faOctagonExclamation;
    }

    if (alerts.some(alert => mediumPriorityAlerts.includes(alert.type))) {
        return faTriangleExclamation;
    }

    if (alerts.some(alert => lowPriorityAlerts.includes(alert.type))) {
        return faCircleInfo;
    }

    return faOctagonExclamation;
};
