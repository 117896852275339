import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import './FramePanelItem.scss';

const FramePanelItem = ({ iconType, label, data }) => (
    <div className="frame-panel-item">
        <div className="frame-panel-item-info">
            <Icon type={iconType} />
            {label}
        </div>
        <div className="frame-panel-item-data">{data}</div>
    </div>
);

FramePanelItem.propTypes = {
    iconType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FramePanelItem;
