import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';

const BhomeIconItem = ({ icon, title, data }) => (
    <div className="bhome-item-block-item">
        <div className="bhome-item-block-item-header">
            <Icon type={icon} strokeWidth={0.3} />
            {title}
        </div>
        <div className="bhome-item-block-item-data">{data}</div>
    </div>
);

BhomeIconItem.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BhomeIconItem;
