import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/pro-regular-svg-icons';
import { useImageWithFallback } from 'utils/useImageWithFallback';
import Magnifier from './magnifier';

import 'components/reusables/RightPanel/components/HiveBlock/FullScreenImageModal.scss';

const standardMagnifierSize = 128;

const FullScreenImageModal = ({ isOpen, onClose, image, side }) => {
    const [magnifierSize, setMagnifierSize] = useState(standardMagnifierSize);
    const { currentImage, isLoading } = useImageWithFallback({
        primaryImageUrl: image?.url,
        fallbackImageUrl: image?.resizedUrl,
    });

    const increaseMagnifierSize = useCallback(() => {
        setMagnifierSize(prevState =>
            prevState !== standardMagnifierSize * 2 ? prevState + standardMagnifierSize / 2 : prevState
        );
    }, []);

    const decreaseMagnifierSize = useCallback(
        () =>
            setMagnifierSize(prevState =>
                prevState !== standardMagnifierSize ? prevState - standardMagnifierSize / 2 : prevState
            ),
        []
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} className="full-screen-image-modal">
            {isLoading && <p>Loading...</p>}
            {!isLoading && (
                <>
                    <Magnifier
                        src={currentImage}
                        mgShape="square"
                        mgWidth={magnifierSize}
                        mgHeight={magnifierSize}
                        zoomFactor={2}
                    />
                    <div className="full-screen-image-modal-label">{side}</div>
                    <div role="presentation" className="full-screen-image-modal-close-wrapper" onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} className="full-screen-image-modal-close" />
                    </div>
                    <div className="increase-button-container">
                        <FontAwesomeIcon
                            role="presentation"
                            icon={faMagnifyingGlassPlus}
                            className="increase-button"
                            onClick={increaseMagnifierSize}
                        />
                        <FontAwesomeIcon
                            role="presentation"
                            icon={faMagnifyingGlassMinus}
                            className="increase-button"
                            onClick={decreaseMagnifierSize}
                        />
                    </div>
                </>
            )}
        </Modal>
    );
};

FullScreenImageModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    image: PropTypes.shape({ url: PropTypes.string, resizedUrl: PropTypes.string }),
    side: PropTypes.string,
};

export default FullScreenImageModal;
