import { createSelector } from 'reselect';
import { keyBy } from 'lodash-es';

export const getGridData = state => state.dashboard.gridData;

export const getCurrentBhomeGridData = createSelector(
    getGridData,
    (state, bhomeId) => bhomeId,
    (gridData, bhomeId) => (bhomeId ? gridData.find(item => item?.id === bhomeId) : {})
);
export const getIsGridFetched = state => state.dashboard.isGridFetched;
export const getLocations = state => state.dashboard.locations;
export const getBhomes = state => state.beehome.bhomes;
export const getRanches = state => state.beehome.ranches;

export const getBeekeeperRanches = createSelector(getRanches, getBhomes, (ranches, bhomes) => {
    const bhomesByRanch = keyBy(bhomes, 'ranch_id');
    return ranches.filter(ranch => bhomesByRanch[ranch.id]);
});
export const getYards = state => state.beehome.yards;

export const getYardById = createSelector(
    getYards,
    (state, yardId) => yardId,
    (yards, yardId) => yards.find(yard => yard.id === yardId)
);
export const getYardsFetched = state => state.beehome.areYardsFetched;
export const getCurrentMapState = state => state.ui.dashboardMapState;
export const getBhomesOnline = state => state.dashboard.online;
