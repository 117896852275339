import { useDrag } from 'react-dnd';
import { FRAME_MOVE_TYPE } from 'config';
import constants from 'appConstants';

const useDraggable = ({ frame, index, moveFrame, selectedFrames, partitionIndex }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: FRAME_MOVE_TYPE,
        item: { type: 'frame', id: frame.frameId, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag:
            frame?.type === constants.FRAME_TYPES.COMB_FRAME &&
            !!frame.frameId &&
            index !== selectedFrames.length - 1 &&
            partitionIndex - 2 < index,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (item && dropResult) {
                moveFrame(item.index, dropResult.index);
            }
        },
    });

    return { isDragging, dragRef };
};

export default useDraggable;
