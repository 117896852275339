import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';
import { getYardData } from 'components/views/Workspace/utils';

import './YardTooltip.scss';
import { getIcon } from '../../../Row/FrameDetails';
import YardTooltipBadge from '../YardTooltipBadge/YardTooltipBadge';

const YardTooltip = ({ yard }) => {
    const yardData = getYardData(yard);

    return (
        <div className="yard-tooltip">
            <div className="yard-tooltip-header">
                <div className="yard-tooltip-header-title">{yard.name} </div>
                <div className="yard-tooltip-header-info">
                    <div className="yard-tooltip-header-info-block">
                        <div className="yard-tooltip-header-info-badge">
                            <Icon type="Larvae" className="yard-tooltip-header-info-badge-icon" />
                            {yardData?.larvaePercentage} %
                        </div>
                        <div className="yard-tooltip-header-info-badge">
                            <Icon type="Syrup" className="yard-tooltip-header-info-badge-icon" />
                            {yardData?.averageSyrup} %
                        </div>
                        {!!yard.totalThermalAssessmentDeadHives && (
                            <div className="yard-tooltip-header-info-badge-warning">
                                <Icon
                                    type="HiveWorkspaceEmpty"
                                    width="16"
                                    height="13"
                                    className="yard-tooltip-header-info-badge-icon"
                                />
                                {yard.totalThermalAssessmentDeadHives}
                            </div>
                        )}
                    </div>
                    <div className="yard-tooltip-header-info-block">
                        <div className="yard-tooltip-header-info-data">
                            <Icon type="Feed" className="yard-tooltip-header-info-data-icon" />
                            {yardData?.lastFeedTime}
                        </div>
                        <div className="yard-tooltip-header-info-data">
                            <FontAwesomeIcon icon={faCalendarCheck} className="yard-tooltip-header-info-data-icon" />
                            {yardData?.lastVisitTimeFormatted}
                        </div>
                    </div>
                </div>
            </div>
            <div className="yard-tooltip-line">
                <div className="yard-tooltip-line-block">
                    <YardTooltipBadge
                        iconType="BeeSimple"
                        name="Bee"
                        text={
                            <>
                                {yardData?.population} {getIcon(yardData?.population, yardData?.yesterdayPopulation)}
                            </>
                        }
                    />
                    <YardTooltipBadge
                        iconType="Brood"
                        name="Brood"
                        text={
                            <>
                                {yardData?.brood} {getIcon(yardData?.brood, yardData?.yesterdayBrood)}
                            </>
                        }
                    />
                </div>
                <div className="yard-tooltip-line-block">
                    <YardTooltipBadge
                        iconType="Honey"
                        name="Honey"
                        text={
                            <>
                                {yardData?.honey} {getIcon(yardData?.honey, yardData?.yesterdayHoney)}
                            </>
                        }
                    />
                    <YardTooltipBadge
                        iconType="Cell"
                        name="Empty"
                        text={
                            <>
                                {yardData?.empty} {getIcon(yardData?.empty, yardData?.yesterdayEmpty)}
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

YardTooltip.propTypes = {
    yard: PropTypes.shape().isRequired,
};

export default YardTooltip;
