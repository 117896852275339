import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { updateMapBounds } from '../utils';

const GroupMarkerWrapper = ({ cluster, supercluster, className, map, children }) => {
    const handleClusterClick = e => {
        e.preventDefault();
        e.stopPropagation();

        if (!map) {
            return;
        }

        if (!cluster?.id) {
            updateMapBounds(map, [cluster?.marker]);
            return;
        }

        try {
            const clusterChildren = supercluster?.getChildren(cluster?.id);

            if (!clusterChildren?.length) {
                return;
            }

            const pointsToPass = clusterChildren?.map(child => ({
                lat: child.geometry.coordinates[1],
                lng: child.geometry.coordinates[0],
            }));
            updateMapBounds(map, pointsToPass);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };
    return (
        <div className={cn('marker-wrapper', className)} onClick={handleClusterClick} role="presentation">
            {children}
        </div>
    );
};

GroupMarkerWrapper.propTypes = {
    cluster: PropTypes.shape(),
    supercluster: PropTypes.shape(),
    className: PropTypes.string,
    map: PropTypes.shape(),
    children: PropTypes.node,
};

export default GroupMarkerWrapper;
