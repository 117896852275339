import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '@beewise/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPlus, faLocationCrosshairs } from '@fortawesome/pro-light-svg-icons';
import constants from 'appConstants';
import './MapControls.scss';

const MapControls = ({ map, handleAddLocation, isAddingLocation, handleCentering }) => {
    const handleZoomChange = amount => () => {
        map.setZoom(map.getZoom() + amount);
    };

    return (
        <div className="map-controls">
            <FontAwesomeIcon
                className={cx('map-controls-btn', 'add-location', { 'add-location-inverted': isAddingLocation })}
                icon={faLocationPlus}
                onClick={handleAddLocation(constants.LOCATION_TYPES.IDLE)}
            />
            {handleCentering && (
                <FontAwesomeIcon className="map-controls-btn" icon={faLocationCrosshairs} onClick={handleCentering} />
            )}
            <Button className="zoom-in" onClick={handleZoomChange(1)}>
                +
            </Button>
            <Button className="zoom-out" onClick={handleZoomChange(-1)}>
                &#8211;
            </Button>
        </div>
    );
};

MapControls.propTypes = {
    map: PropTypes.shape(),
    handleAddLocation: PropTypes.func,
    isAddingLocation: PropTypes.bool,
    handleCentering: PropTypes.func,
};

export default memo(MapControls);
