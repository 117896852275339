import { createSelector } from 'reselect';
import { getRoles } from 'components/views/Admin/selectors';
import { get } from 'lodash-es';

const getCompanyUsers = state => get(state.settings, 'company.users', []);

export const getOverallPreferences = state => get(state.settings, 'userPreferences', {});
export const getCountPreferences = state => state.settings.userCountPreferences;
export const getTotalBeehomes = state => state.settings.totalBeehomes;
export const getUserSettings = state => get(state, 'settings.userSettings', {});

export const getCompanyUsersWithPermissions = createSelector(getCompanyUsers, getRoles, (users, roles) =>
    users.map(user => {
        const permissions = {
            canOperate: false,
        };
        if (user.role === roles.WRITE || user.role === roles.ADMIN) {
            permissions.canOperate = true;
        }
        return {
            ...user,
            permissions,
        };
    })
);

export const getIsInvitationModelOpen = state => state.settings.isInvitationModelOpen;
