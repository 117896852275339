import { createAsyncAction } from '@beewise/utils';

export const FETCH_WORKSPACE_DATA = createAsyncAction('FETCH_WORKSPACE_DATA');
export const FETCH_CREATE_YARD_ACTION = createAsyncAction('FETCH_CREATE_YARD_ACTION');
export const FETCH_UPDATE_YARD_ACTION = createAsyncAction('FETCH_UPDATE_YARD_ACTION');
export const FETCH_GET_YARD_ACTION = createAsyncAction('FETCH_GET_YARD_ACTION');
export const FETCH_GET_YARD_EVENTS_BY_BHOME_IDS = createAsyncAction('FETCH_GET_YARD_EVENTS_BY_BHOME_IDS');
export const CHANGE_YARD_FILTER = 'CHANGE_YARD_FILTER';
export const CLEAR_YARD_FILTER = 'CLEAR_YARD_FILTER';
export const CHANGE_YARD_SORT = 'CHANGE_YARD_SORT';
export const SET_YARD_TO_INSPECT = 'SET_YARD_TO_INSPECT';
