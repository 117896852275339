import React, { useState, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis, ResponsiveContainer } from '@beewise/chart';
import { tickFormatter } from '../utils';

const CustomTooltip = ({ payload }) => {
    const tooltipItems = Object.keys(payload?.displayedValues ?? {}).map(item => ({
        value: payload.displayedValues[item],
        key: item,
        unitValue: payload.unitValue,
    }));

    return (
        <div className="custom-tooltip">
            <div className="content">
                {tooltipItems.reverse().map(item => {
                    const row = payload.unitValue
                        ? `${item.value} ${payload.unitValue}, ${item.key}`
                        : `${item.value} ${item.key}`;
                    return <span key={item.key}>{row} / </span>;
                })}
                {dayjs(payload.date).utc().format('MMM DD')} <br />
            </div>
        </div>
    );
};

CustomTooltip.propTypes = {
    payload: PropTypes.shape(),
};

const ChartFramesPlot = ({ graphsDisplayed, zoomLevel, dataOptions, xAxisOptions }) => {
    const area = useRef(null);
    const tooltip = useRef({ style: {}, childNodes: [] });
    const [tooltipData, setTooltipData] = useState({});

    const updateTooltip = useCallback(
        point => {
            if (point) {
                const x = Math.round(point.x);
                const y = Math.round(point.y);
                const keysMappedToNamed = graphsDisplayed.reduce((acc, graph) => {
                    acc[graph.key] = graph.name;
                    return acc;
                }, {});
                const keysToReplace = graphsDisplayed.map(graph => graph.key);
                const newPayload = keysToReplace.reduce(
                    (acc, key) => {
                        acc.displayedValues[keysMappedToNamed[key]] = point.payload[key];
                        return acc;
                    },
                    { ...point.payload, displayedValues: {} }
                );
                setTooltipData({
                    ...newPayload,
                    unitValue: graphsDisplayed[0].unitValue,
                });
                tooltip.current.style.opacity = 1;
                tooltip.current.style.transform = `translate(${x}px, ${y}px)`;
            } else {
                tooltip.current.style.opacity = '0';
            }
        },
        [graphsDisplayed]
    );

    const onChartMouseMove = useCallback(
        chart => {
            if (chart.isTooltipActive) {
                const graphValues = chart.activePayload.map(it => it.value);
                const newPoint = area.current?.props.points?.[chart.activeTooltipIndex];
                const currentValue = chart.activePayload[0].value;
                const largestValue = Math.max(...graphValues);

                const pointsPerVal = newPoint.y / currentValue;

                const newYVal = largestValue !== currentValue ? 520 - largestValue * pointsPerVal : newPoint.y;

                updateTooltip({ ...newPoint, y: newYVal });
            }
        },
        [updateTooltip]
    );

    const onChartMouseLeave = useCallback(() => {
        updateTooltip();
    }, [updateTooltip]);

    const pattern = useMemo(
        () =>
            graphsDisplayed.map(item =>
                item.withPattern ? (
                    <linearGradient id={item.key} key={item.key} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={item.color} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={item.color} stopOpacity={0} />
                    </linearGradient>
                ) : null
            ),
        [graphsDisplayed]
    );

    return (
        <div style={{ height: 459, position: 'relative' }}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={dataOptions} onMouseMove={onChartMouseMove} onMouseLeave={onChartMouseLeave}>
                    <defs>{pattern}</defs>
                    <CartesianGrid />
                    <XAxis
                        dataKey="date"
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={tickFormatter(zoomLevel)}
                        minTickGap={-999}
                        allowDataOverflow
                        {...xAxisOptions}
                    />
                    <YAxis
                        type="number"
                        domain={[0, 'auto']}
                        axisLine={false}
                        tickLine={false}
                        allowDecimals={false}
                        allowDataOverflow={false}
                    />
                    <Tooltip cursor={false} wrapperStyle={{ display: 'none' }} />
                    {graphsDisplayed.map(
                        ({ fill, show, color, withPattern, key }, i) =>
                            show && (
                                <Area
                                    ref={ref => {
                                        !i ? (area.current = ref) : null;
                                    }}
                                    key={key}
                                    name="fl"
                                    fillOpacity={0.5}
                                    dataKey={key}
                                    stroke={color || '#4f2d21'}
                                    strokeWidth={2}
                                    type="monotone"
                                    fill={!withPattern ? fill : `url(#${key})`}
                                />
                            )
                    )}
                </AreaChart>
            </ResponsiveContainer>
            <div
                className="ui-chart-tooltip"
                ref={ref => {
                    tooltip.current = ref;
                }}
            >
                <CustomTooltip payload={tooltipData} />
            </div>
        </div>
    );
};

ChartFramesPlot.propTypes = {
    graphsDisplayed: PropTypes.arrayOf(PropTypes.shape()),
    zoomLevel: PropTypes.string,
    dataOptions: PropTypes.arrayOf(PropTypes.shape()),
    xAxisOptions: PropTypes.shape(),
};

export default ChartFramesPlot;
