import { get, isEmpty } from 'lodash-es';
import { createSelector } from 'reselect';
import sortAlerts from 'utils/sortAlerts';
import constants from 'appConstants';
import { getCurrentBeehomeId } from 'components/views/BeeHome/selectors';

export const getUserAlerts = state => sortAlerts(get(state, 'alerts', []));
export const getBhomeFrames = state => state.beehome.frames;
export const isBhomeFramesFetched = state => state.beehome.isFetched;
export const getCurrentBhomeNotes = state => state.beehome.currentBhome?.notes;
export const getNotifications = state => sortAlerts(get(state, 'alerts', []));

export const getBeekeepersEntries = createSelector(
    getCurrentBeehomeId,
    getBhomeFrames,
    isBhomeFramesFetched,
    getCurrentBhomeNotes,
    (bhomeId, frames, isFramesFetched, notes = []) => {
        if (!isFramesFetched) {
            return [];
        }
        return sortAlerts([
            ...notes.reduce((acc, note) => {
                if (note.bhome_id === bhomeId && note.status === constants.ALERT_STATUSES.UNREAD) {
                    acc.push(note);
                }
                return acc;
            }, []),
        ]);
    }
);

export const getUnreadAlertsIds = createSelector(getUserAlerts, alerts =>
    alerts.filter(alert => alert.status === constants.ALERT_STATUSES.UNREAD).map(alert => alert.id)
);

export const isFrameQueenCellAlerted = frame =>
    (frame.sideA && +frame.sideA[constants.FRAME_METRICS.QUEEN_CELLS] && !isEmpty(frame.sideA.swarmData)) ||
    (frame.sideB && +frame.sideB[constants.FRAME_METRICS.QUEEN_CELLS] && !isEmpty(frame.sideB.swarmData));

export const getUnreadAlerts = alerts =>
    alerts ? alerts.filter(alert => alert.status === constants.ALERT_STATUSES.UNREAD) : [];

export const getActivityFailedAlerts = createSelector(getUserAlerts, getCurrentBeehomeId, (alerts, bhomeId) =>
    alerts.filter(
        alert =>
            alert.status === constants.ALERT_STATUSES.UNREAD &&
            alert.type === constants.ALERT_TYPES.ACTIVITY_FAILED &&
            alert?.data?.bhomeId === bhomeId
    )
);

export const getBhomeUnreadAlerts = createSelector(
    [state => state.alerts, (state, bhomeId) => bhomeId],
    (alerts, bhomeId) =>
        alerts?.filter(
            alert => alert.status === constants.ALERT_STATUSES.UNREAD && alert.data && alert.data.bhomeId === bhomeId
        ) ?? []
);

export const getFramesAlerts = frames =>
    frames.reduce((acc, frame) => {
        if (isFrameQueenCellAlerted(frame)) {
            acc.push({
                id: frame.id,
                frameId: frame.frameId,
                type: constants.ALERT_TYPES.QUEEN_CELLS,
                slotId: frame.slotId,
            });
        }

        return acc;
    }, []);
