import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MarkerIcon from '../MarkerIcon';
import GroupMarkerWrapper from '../GroupMarkerWrapper';

const sumChildrenNodes = (supercluster, item) =>
    supercluster?.getChildren(item.id)?.reduce((acc, it) => {
        const { marker = {} } = it;
        if (it.id) {
            acc += sumChildrenNodes(supercluster, it);
        } else {
            acc += marker.bhome_ids?.length ?? 0;
        }
        return acc;
    }, 0);

const getClusterName = (supercluster, item) => {
    // TODO find better solution for this (on company change)
    try {
        const name = sumChildrenNodes(supercluster, item);
        return name;
    } catch (err) {
        return 0;
    }
};

const getMarkerContent = ({ icon, supercluster, item, zoomLevel }) => {
    if (icon === 'blured-marker') {
        return {
            icon,
            name: getClusterName(supercluster, item),
            className: cn('bhome-unassigned', {
                'marker-large': zoomLevel < 8,
            }),
        };
    }
    return { icon, className: 'empty-location' };
};

const GroupMarker = ({ cluster, supercluster, type, map }) => {
    const zoomLevel = map ? map.getZoom() : 0;
    const { name, icon, className } = getMarkerContent({ icon: type, supercluster, item: cluster, zoomLevel });
    return (
        <GroupMarkerWrapper cluster={cluster} supercluster={supercluster} map={map}>
            <MarkerIcon icon={icon} count={name || cluster?.properties?.point_count || 1} className={className} />
        </GroupMarkerWrapper>
    );
};

GroupMarker.propTypes = {
    cluster: PropTypes.shape(),
    supercluster: PropTypes.shape(),
    type: PropTypes.string,
    map: PropTypes.shape(),
};

export default GroupMarker;
