import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import TooltipMulti from './components/TooltipMulti';
import TooltipAlertsList from './components/AlertsList/TooltipAlertsList';
import { getAlertsTooltipText, getAlertsTriggerIcon } from './utils';

import './AlertIcon.scss';

const AlertIcon = ({ alerts, className, type, onLoad }) => {
    const tooltipText = getAlertsTooltipText(alerts);
    const triggerIcon = getAlertsTriggerIcon(alerts);

    return (
        <TooltipMulti
            position="top center"
            trigger={<FontAwesomeIcon icon={triggerIcon} className={cx('alert-icon', { [className]: !!className })} />}
            componentHover={tooltipText}
            componentClick={
                <TooltipAlertsList maxHeightClass="list-content-medium" alerts={alerts} type={type} onLoad={onLoad} />
            }
        />
    );
};

AlertIcon.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    onLoad: PropTypes.func,
};

export default AlertIcon;
