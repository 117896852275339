import React from 'react';
import cx from 'classnames';
import { isUndefined } from 'lodash-es';
import constants from 'appConstants';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/pro-light-svg-icons';
import Button from '@beewise/button';
import { getActionData } from './actionsData';

export const renderActionIconWithTooltip = ({
    command,
    actionMode,
    onClick,
    isFlagEnabled,
    hasEntrancesToRevert,
    allowedActions,
    sensors,
    hiveManagementMenuItems,
    flags,
    isSpecialModeActive,
    isHistoryLayoutSnapshotShown,
}) => {
    const actionData = getActionData(command);

    const isDisabled =
        isSpecialModeActive ||
        !isFlagEnabled ||
        hasEntrancesToRevert ||
        isHistoryLayoutSnapshotShown ||
        (command === constants.COMMANDS.FILL_FEEDER && sensors?.syrupLevel <= 0);

    if (command === constants.COMMANDS.HIVE_MANAGEMENT) {
        return (
            <Button
                onClick={onClick}
                className={cx('primary-blue', {
                    'is-active': actionMode === command,
                })}
                key={`aiwt-et-${command}`}
                menuItems={hiveManagementMenuItems}
                hasDropdown
                shadow
                disabled={!flags.moveFrames || isHistoryLayoutSnapshotShown}
            >
                {actionData.buttonText}
            </Button>
        );
    }

    return (
        <Button
            disabled={
                isDisabled ||
                actionData.notEnabled ||
                (!isUndefined(allowedActions[command]) && !allowedActions[command])
            }
            onClick={onClick}
            className={cx('primary-blue', {
                'is-active': actionMode === command,
                'is-disabled': isDisabled && actionMode !== command,
            })}
            key={`aiwt-et-${command}`}
            shadow
        >
            {actionData.buttonText}
        </Button>
    );
};

export const renderShowHistoryIconWithTooltip = (showFramesHistory, onClick) => (
    <Tooltip text="Show previous scan" position="left center" mouseEnterDelay={0} mouseLeaveDelay={0} hideOnScroll>
        <FontAwesomeIcon
            onClick={onClick}
            key="aiwt-shi"
            icon={faClockRotateLeft}
            className={cx('bhome-actions-icon show-history', {
                'is-active': showFramesHistory,
            })}
        />
    </Tooltip>
);

export const actionsToShow = [
    constants.COMMANDS.HIVE_MANAGEMENT,
    constants.COMMANDS.FILL_FEEDER,
    constants.COMMANDS.SCAN,
    constants.COMMANDS.COUNT_BEES,
    constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION,
    constants.COMMANDS.MOVE_TO_VISIT_POSITION,
];
