import { keyBy, omit } from 'lodash-es';
import constants from '@beewise/constants';
import { updateBhomeLocation } from './helpers';
import {
    CREATE_COMPANY_LOCATION,
    FETCH_COMPANY_LOCATIONS,
    REMOVE_COMPANY_LOCATION,
    UPDATE_COMPANY_LOCATION,
    CLEAR_COMPANY_LOCATIONS,
    FETCH_ALL_BHOMES_ONLINE,
    SET_BHOME_ARRIVED,
    UPDATE_BHOME_LOCATION,
    FETCH_DASHBOARD_GRID_DATA,
    FETCH_FEED_BHOMES,
    FETCH_BHOMES_FEED_STATUSES,
} from '../actionTypes';

const initialState = {
    locations: [],
    highlightedBhome: null,
    isFetched: false,
    online: [],
    gridData: [],
    isGridFetched: false,
};

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPANY_LOCATIONS.success:
            return {
                ...state,
                locations: action.data.locations,
                isFetched: true,
            };
        case CREATE_COMPANY_LOCATION.success:
            return {
                ...state,
                locations: [...state.locations, action.data.location],
            };
        case FETCH_DASHBOARD_GRID_DATA.success:
            return {
                ...state,
                gridData: action.data.result,
                isGridFetched: true,
            };
        case FETCH_BHOMES_FEED_STATUSES.success:
            const statusDataByIds = keyBy(action.data, 'id');
            return {
                ...state,
                gridData: state.gridData.map(bhome => {
                    const statusData = statusDataByIds[bhome.id] ?? {};
                    return {
                        ...bhome,
                        ...statusData,
                    };
                }),
            };
        case REMOVE_COMPANY_LOCATION.success:
            return {
                ...state,
                locations: state.locations.filter(location => location.id !== action.data.id),
            };
        case UPDATE_COMPANY_LOCATION.success:
            return {
                ...state,
                locations: state.locations.map(location =>
                    location.id === action.data.location.id ? { ...location, ...action.data.location } : location
                ),
            };
        case UPDATE_COMPANY_LOCATION.failed:
            return {
                ...state,
                locations: [...state.locations],
            };
        case FETCH_ALL_BHOMES_ONLINE.success:
            return {
                ...state,
                online: action.data.isOnline,
            };
        case CLEAR_COMPANY_LOCATIONS:
            return {
                ...state,
                ...initialState,
            };
        case UPDATE_BHOME_LOCATION.success:
            return {
                ...state,
                locations: updateBhomeLocation({ locations: state.locations, data: action.data }),
            };
        case SET_BHOME_ARRIVED:
            return {
                ...state,
                locations: state.locations.map(item =>
                    item.id === action.locationId ? omit(item, ['movingToLocation']) : item
                ),
            };
        case FETCH_FEED_BHOMES.success:
            return {
                ...state,
                gridData: state.gridData.map(bhome => {
                    if (action.data.bhomes?.includes(bhome.id)) {
                        return {
                            ...bhome,
                            status: constants.MESSAGE_STATUS.PENDING,
                            statusDate: new Date(),
                        };
                    }
                    return bhome;
                }),
            };
        default:
            return state;
    }
};

export default dashboard;
