import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FrameImageV2 } from 'components/reusables/RightPanel/components/HiveBlock/components/components';
import { useImageWithFallback } from 'utils/useImageWithFallback';
import useCombFrameImages from 'components/reusables/RightPanel/components/HiveBlock/useCombFrameImages';
import { SIDE, getLatestFormattedTime } from '../../utils';

import './FrameImageBlock.scss';

const FrameImageBlock = ({
    title,
    fullScreenImage,
    images,
    handleFullScreenImageShow,
    inspectedFrame,
    showAnnotatedPhotos,
    type,
    startImageIndex,
}) => {
    const latestActionTime = getLatestFormattedTime({ frame: inspectedFrame, type });

    const [{ url: urlSideA, resizedUrl: resizedUrlSideA }, { url: urlSideB, resizedUrl: resizedUrlSideB }] = [
        useCombFrameImages(inspectedFrame, images, SIDE.A, showAnnotatedPhotos),
        useCombFrameImages(inspectedFrame, images, SIDE.B, showAnnotatedPhotos),
    ];

    const [
        { currentImage: loadedUrlSideA, isLoading: isLoadingSideA, error: errorSideA },
        { currentImage: loadedUrlSideB, isLoading: isLoadingSideB, error: errorSideB },
    ] = [
        useImageWithFallback({
            primaryImageUrl: resizedUrlSideA,
            fallbackImageUrl: urlSideA,
        }),
        useImageWithFallback({
            primaryImageUrl: resizedUrlSideB,
            fallbackImageUrl: urlSideB,
        }),
    ];

    return (
        <div className="frame-panel-main-block">
            <div className="frame-panel-main-block-top">
                <div className="frame-panel-main-block-top-title">{title}</div>
                <div className="frame-panel-main-block-top-date">
                    <FontAwesomeIcon icon={faCamera} className="hive-panel-header-bottom-block-icon" />
                    <p className="frame-panel-main-block-top-date-info">
                        <b>{latestActionTime}</b>
                    </p>
                </div>
            </div>
            <div className="frame-panel-main-block-images">
                <div
                    className={cx('hive-block-inspected-frame-scan-a', {
                        'full-screen': fullScreenImage.side === SIDE.A && fullScreenImage.type === type,
                    })}
                    role="presentation"
                    onClick={handleFullScreenImageShow(SIDE.A, images, startImageIndex, type)}
                >
                    <FrameImageV2
                        frameType={inspectedFrame.type}
                        url={loadedUrlSideA}
                        isLoading={isLoadingSideA}
                        error={errorSideA}
                    />
                </div>
                <div
                    className={cx('hive-block-inspected-frame-scan-b', {
                        'full-screen': fullScreenImage.side === SIDE.B && fullScreenImage.type === type,
                    })}
                    role="presentation"
                    onClick={handleFullScreenImageShow(SIDE.B, images, startImageIndex + 1, type)}
                >
                    <FrameImageV2
                        frameType={inspectedFrame.type}
                        url={loadedUrlSideB}
                        isLoading={isLoadingSideB}
                        error={errorSideB}
                    />
                </div>
            </div>
        </div>
    );
};

FrameImageBlock.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    fullScreenImage: PropTypes.shape(),
    images: PropTypes.shape(),
    handleFullScreenImageShow: PropTypes.func,
    inspectedFrame: PropTypes.shape(),
    showAnnotatedPhotos: PropTypes.bool,
    startImageIndex: PropTypes.number,
};

export default FrameImageBlock;
