import { HASHNODE_AUTH_TOKEN } from 'config';

// documentation: https://apidocs.hashnode.com/
const HASHNODE_GQL_URL = 'https://gql.hashnode.com';

export const fetchPosts = async bhomeId => {
    const query = `
        query Publication {
            publication(host: "beewise.hashnode.dev/${Date.now()}") {
                isTeam
                title
                posts(first: 50, filter: { tagSlugs: ["beehome-${bhomeId}"] }) {
                    edges {
                        node {
                            title
                            subtitle
                            content {
                                html
                            }
                            coverImage {
                                url
                            }
                            readTimeInMinutes
                            publishedAt
                            author {
                                name
                                profilePicture
                            }
                            tags {
                                name
                            }
                        }
                    }
                }
            }
        }
    `;

    const response = await fetch(HASHNODE_GQL_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: HASHNODE_AUTH_TOKEN,
        },
        body: JSON.stringify({ query }),
    });

    const result = await response.json();
    return result.data.publication.posts.edges.map(edge => edge.node);
};
