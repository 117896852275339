import React, { memo } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import cn from 'classnames';
import { getCapacity, getIsFoundationFrame } from 'components/reusables/Hive/utils';
import Comb from 'components/reusables/Hive/components/Frame/components/Comb';
import FullFrame from 'components/reusables/Hive/components/Frame/components/FullFrame';
import { noop } from 'lodash-es';
import useDraggable from '../hooks/useDraggable';
import useDroppable from '../hooks/useDroppable';

const Frame = ({ frame, index, moveFrame, selectedFrames, partitionIndex }) => {
    const { isDragging, dragRef } = useDraggable({ frame, index, moveFrame, selectedFrames, partitionIndex });
    const dropRef = useDroppable({ index, moveFrame });

    const isCombFrame = frame?.type === constants.FRAME_TYPES.COMB_FRAME;
    const isDisabled = !isCombFrame || partitionIndex - 1 > index;

    return (
        <div className="hive-block-frame-item-wrapper" role="presentation" onClick={noop} ref={dropRef}>
            <div
                className={cn('hive-block-frame-item', {
                    'before-partition': index < partitionIndex,
                    isDisabled,
                    isDragging,
                    isMoved: frame.isMoved && index !== frame.initialLayoutFrameIndex,
                })}
                ref={dragRef}
            >
                {isCombFrame ? (
                    <Comb frame={frame} capacity={getCapacity(frame)} showFramesHistory={false} />
                ) : (
                    <FullFrame
                        type={frame.type}
                        isFoundationFrame={getIsFoundationFrame(frame)}
                        isEmptySlot={!frame.frameId}
                    />
                )}
            </div>
        </div>
    );
};

Frame.propTypes = {
    frame: PropTypes.shape({
        type: PropTypes.string,
        frameId: PropTypes.string,
        isMoved: PropTypes.bool,
        initialLayoutFrameIndex: PropTypes.number,
    }).isRequired,
    index: PropTypes.number.isRequired,
    moveFrame: PropTypes.func.isRequired,
    partitionIndex: PropTypes.number.isRequired,
    selectedFrames: PropTypes.arrayOf(
        PropTypes.shape({
            slotId: PropTypes.number.isRequired,
            frameId: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default memo(Frame);
