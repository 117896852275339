import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getIsBlogPanelShown, getIsRightPanelShown } from 'selectors';
import { toggleBlogPanel, toggleRightPanel } from 'actions';
import AsidePanel from 'components/reusables/AsidePanel';
import { setFrameToInspect, setHiveToInspect } from 'components/views/BeeHome/actions';
import { getInspectedFrame, getInspectedHive } from '../../selectors';
import FramePanel from './components/FramePanel';
import HivePanel from './components/HivePanel';
import ActionPanel from './components/ActionPanel';
import BlogPanel from './components/BlogPanel';
import './RightPanel.scss';

const RightPanel = ({ isPublicView, code }) => {
    const dispatch = useDispatch();

    const inspectedFrame = useSelector(getInspectedFrame, shallowEqual);
    const inspectedHive = useSelector(getInspectedHive);
    const isActionPanelOpen = useSelector(getIsRightPanelShown);
    const isBlogPanelOpen = useSelector(getIsBlogPanelShown);

    const isPanelOpen = !!inspectedHive || !isEmpty(inspectedFrame) || isActionPanelOpen || isBlogPanelOpen;

    const handleClosePanel = () => {
        dispatch(setFrameToInspect({}));
        dispatch(setHiveToInspect(null));
        dispatch(toggleRightPanel(false));
        dispatch(toggleBlogPanel(false));
    };

    // eslint-disable-next-line
    useEffect(() => handleClosePanel, []);

    let Component = Fragment;
    let wrapperProps = {};

    if (inspectedFrame?.id) {
        Component = FramePanel;
        wrapperProps = { onClose: handleClosePanel, isPublicView, code };
    } else if (inspectedHive) {
        Component = HivePanel;
        wrapperProps = { onClose: handleClosePanel, isPublicView };
    } else if (isActionPanelOpen) {
        Component = ActionPanel;
        wrapperProps = { onClose: handleClosePanel };
    } else if (isBlogPanelOpen) {
        Component = BlogPanel;
        wrapperProps = { onClose: handleClosePanel };
    }

    return (
        <AsidePanel isPanelOpen={isPanelOpen} side="right" onClose={handleClosePanel} className="right-panel">
            <Component {...wrapperProps} />
        </AsidePanel>
    );
};

RightPanel.propTypes = {
    isPublicView: PropTypes.bool,
    code: PropTypes.string,
};

export default RightPanel;
