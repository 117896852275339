import {
    FETCH_BHOMES_FRAMES_CONTENT_COUNT,
    FETCH_AGGREGATED_MESSAGES,
    FETCH_AGGREGATED_TEMPERATURE,
} from '../actionTypes';

export const fetchBhomesBroodFrameAmount = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOMES_FRAMES_CONTENT_COUNT.default,
        url: '/bhomes/get-aggregated-bhomes-content?attr[]=broodFramesPerHive&attr[]=honeyCellsPerHive&attr[]=pollenCellsPerHive&attr[]=nonBroodFramesWeightPerHive&attr[]=broodFramesWeightPerHive&attr[]=beeFramesPerHive',
    },
});

export const fetchAggregatedMessages = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_AGGREGATED_MESSAGES.default,
        url: '/bhomes/get-aggregated-messages',
    },
});

export const fetchAggregatedTemperature = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_AGGREGATED_TEMPERATURE.default,
        url: '/bhomes/temperature',
    },
});
