import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIsLoggedInAsSuperAdmin } from 'components/views/Admin/selectors';
import isSignInOrUpView from 'utils/isSignInOrUpView';
import { changeCurrentUser } from 'actions';

/* eslint-disable camelcase, react/jsx-no-useless-fragment */
const AdminProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isFirstLoadHappened, setFirstLoad] = useState(false);
    const isLoggedInAsSuperAdmin = useSelector(getIsLoggedInAsSuperAdmin);
    const dispatch = useDispatch();
    const isSignUpView = useMemo(() => isSignInOrUpView(location), [location]);

    const adminToken = useMemo(() => {
        const { admin_token } = queryString.parse(location.search);
        return admin_token;
    }, [location.search]);

    useEffect(() => {
        if (!isFirstLoadHappened && ((isLoggedInAsSuperAdmin && !isSignUpView) || adminToken)) {
            setFirstLoad(true);

            if (adminToken) {
                dispatch(changeCurrentUser(adminToken));
                navigate(location.pathname, { replace: true });
            }
        }
    }, [navigate, isFirstLoadHappened, isLoggedInAsSuperAdmin, isSignUpView, adminToken, dispatch, location?.pathname]);

    return (isFirstLoadHappened || !isLoggedInAsSuperAdmin || isSignUpView) && !adminToken ? <>{children}</> : null;
};

AdminProvider.propTypes = {
    children: PropTypes.node,
};

export default AdminProvider;
