import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import Tooltip from '@beewise/tooltip';
import Checkbox from '@beewise/checkbox-field';
import constants from 'appConstants';
import getFrameTooltip from 'utils/getFrameTooltip';
import useGetFrameStationHiveAndFrameNumbers from 'utils/useGetFrameStationHiveAndFrameNumbers';
import { getCapacity, getIsFoundationFrame } from 'components/reusables/Hive/utils';
import Comb from 'components/reusables/Hive/components/Frame/components/Comb';
import BeeCount from 'components/reusables/Hive/components/Frame/components/BeeCount';
import FullFrame from 'components/reusables/Hive/components/Frame/components/FullFrame';
import { getIsBalanceHiveModeShown, getSelectedFramesForBalance } from 'components/views/BeeHome/selectors';
import StationFrameStatus from './StationFrameStatus';

const getFrameComponent = ({ frame, isBeeCountMode, isFoundationFrame, capacity, showFramesHistory }) => {
    const isCombFrame = frame.type && frame.type === constants.FRAME_TYPES.COMB_FRAME && !isFoundationFrame;

    if (isBeeCountMode && (isFoundationFrame || isCombFrame)) {
        return <BeeCount frame={frame} showFramesHistory={showFramesHistory} />;
    }
    if (isCombFrame) {
        return <Comb frame={frame} capacity={capacity} showFramesHistory={showFramesHistory} />;
    }
    return <FullFrame type={frame.type} isFoundationFrame={isFoundationFrame} isEmptySlot={!frame.frameId} />;
};

const StationFrame = ({
    frame,
    showFramesHistory,
    onFrameClick,
    stationEnd,
    stationStart,
    currentStationHives,
    isInspectedFrame,
    isBeeCountMode,
    onFrameCheckboxSelect,
}) => {
    const [isAddedToBalanceHive, setIsAddedToBalanceHive] = useState(false);
    const frameInfo = useGetFrameStationHiveAndFrameNumbers(frame);
    const tooltipText = useMemo(
        () =>
            getFrameTooltip({
                frame: { ...frame, frameIdx: frame.slotId },
                frameInfo,
                isBeeCountMode,
                showFramesHistory,
            }),
        [frame, frameInfo, isBeeCountMode, showFramesHistory]
    );
    const selectedFramesForBalance = useSelector(getSelectedFramesForBalance, shallowEqual);
    const isBalanceHiveModeShown = useSelector(getIsBalanceHiveModeShown);
    const isFoundationFrame = getIsFoundationFrame(frame, showFramesHistory);
    const isStaticPartition = frame.type === constants.FRAME_TYPES.STATIC_PARTITION;
    const capacity = getCapacity(frame, showFramesHistory);
    const relatedMarkedHive =
        frame?.frameId &&
        currentStationHives.find(
            hive =>
                frame?.place?.station === hive?.station &&
                frame?.place?.position?.x >= hive?.from &&
                frame?.place?.position?.x < hive?.to
        );
    const isInsideMarkedHive = !!relatedMarkedHive;
    const isCheckboxShown =
        isBalanceHiveModeShown &&
        frame.type === constants.FRAME_TYPES.COMB_FRAME &&
        isInsideMarkedHive &&
        onFrameCheckboxSelect;
    const isCheckboxDisabled =
        (Object.keys(selectedFramesForBalance).length > 1 &&
            selectedFramesForBalance[relatedMarkedHive?.hiveId]?.id !== frame.id) ||
        (selectedFramesForBalance[relatedMarkedHive?.hiveId] &&
            selectedFramesForBalance[relatedMarkedHive?.hiveId]?.id !== frame.id);

    const style = {
        left: `${((frame.place.position.x - stationStart) / (stationEnd - stationStart)) * 100}%`,
        ...(isStaticPartition && { transform: 'translateX(4px)' }),
    };

    return (
        <div
            className={cx('station-frame-wrapper', {
                'station-frame-wrapper-outside': !isInsideMarkedHive,
                'station-frame-wrapper-inspected': isInspectedFrame,
                'station-frame-wrapper-static-partition': isStaticPartition,
            })}
            style={style}
        >
            {isCheckboxShown && (
                <Tooltip
                    content="You can only choose two frames to switch. Unselect one of the frames"
                    position="top center"
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    disabled={!isCheckboxDisabled}
                >
                    <Checkbox
                        className="balance-checkbox"
                        onChange={() => {
                            const updatedIsAddedToBalanceHive = !isAddedToBalanceHive;
                            onFrameCheckboxSelect({
                                frame,
                                hiveId: relatedMarkedHive?.hiveId,
                                isSelected: updatedIsAddedToBalanceHive,
                            });
                            setIsAddedToBalanceHive(updatedIsAddedToBalanceHive);
                        }}
                        disabled={isCheckboxDisabled}
                    />
                </Tooltip>
            )}
            <StationFrameStatus frame={frame} isInsideMarkedHive={isInsideMarkedHive} />
            <Tooltip
                content={tooltipText}
                position="top center"
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                hideOnScroll
                disabled={!tooltipText}
            >
                <div
                    className={cx('station-frame', {
                        'station-frame-empty': !frame?.type,
                    })}
                    role="presentation"
                    onClick={onFrameClick}
                >
                    {getFrameComponent({ frame, isBeeCountMode, isFoundationFrame, capacity, showFramesHistory })}
                </div>
            </Tooltip>
        </div>
    );
};

StationFrame.propTypes = {
    frame: PropTypes.shape().isRequired,
    showFramesHistory: PropTypes.bool.isRequired,
    onFrameClick: PropTypes.func.isRequired,
    stationStart: PropTypes.number.isRequired,
    stationEnd: PropTypes.number.isRequired,
    currentStationHives: PropTypes.arrayOf(PropTypes.shape()),
    onFrameCheckboxSelect: PropTypes.func,
    isInspectedFrame: PropTypes.bool,
    isBeeCountMode: PropTypes.bool,
};

export default StationFrame;
