import { FETCH_ALL_BHOMES, FETCH_ALL_COMPANIES } from '../actionTypes';

const admin = (
    state = {
        companies: [],
        bhomes: [],
    },
    action
) => {
    switch (action.type) {
        case FETCH_ALL_COMPANIES.success:
            return {
                ...state,
                companies: action.data.companies,
            };
        case FETCH_ALL_BHOMES.success:
            return {
                ...state,
                bhomes: action.data.bhomes.sort((a, b) => a.id - b.id),
            };
        default:
            return state;
    }
};

export default admin;
