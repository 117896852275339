import { useEffect, useState } from 'react';

export default () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return isSmallScreen;
};
