import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNaN } from 'lodash-es';
import { loading } from '@beewise/react-utils';
import ProgressBar from '@beewise/progress-bar';
import { FETCH_ALL_BHOMES_ONLINE } from '../../actionTypes';

import './OnlineWidget.scss';

const OnlineWidget = ({ bhomeConnectivityList = [] }) => {
    const [onlineBhomes, setOnlineBhomes] = useState([]);
    const [offlineBhomes, setOfflineBhomes] = useState([]);

    useEffect(() => {
        if (!bhomeConnectivityList?.length) {
            return;
        }
        const { online, offline } = bhomeConnectivityList.reduce(
            (acc, bhome) =>
                bhome.connected
                    ? { ...acc, online: [...acc.online, bhome] }
                    : { ...acc, offline: [...acc.offline, bhome] },
            { online: [], offline: [] }
        );
        setOnlineBhomes(online);
        setOfflineBhomes(offline);
    }, [bhomeConnectivityList]);

    const percentage = ((onlineBhomes?.length ?? 0) / (bhomeConnectivityList?.length ?? 1)) * 100;

    return (
        <div className="widget online-widget">
            <div className="secondary-text">Beehomes Connectivity</div>
            <div className="online-widget-content">
                <div className="online-widget-content-numbers">
                    <div className="online-widget-content-numbers-online">
                        <div>{onlineBhomes?.length ?? '-'} Online</div>
                        <div className="online-widget-content-numbers-online-percent">
                            {isNaN(percentage) ? '-' : `${percentage?.toFixed(0)}%`}
                        </div>
                    </div>
                    <div className="secondary-text">{offlineBhomes?.length} Offline</div>
                </div>
                <ProgressBar completed={percentage} />
            </div>
        </div>
    );
};

OnlineWidget.propTypes = {
    bhomeConnectivityList: PropTypes.arrayOf(PropTypes.shape()),
};

export default loading(FETCH_ALL_BHOMES_ONLINE.default)(OnlineWidget, 'online-widget-wrapper');
