import React, { useEffect, useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';
import OutsideClickHandler from 'react-outside-click-handler';
import AlertListItem from './AlertListItem';
import { groupMultipleBroodAlerts } from './utils';

import './Alerts.scss';

const TooltipAlertsList = ({ maxHeightClass, alerts, type, handleClose, onLoad }) => {
    const groupedAlerts = useMemo(() => groupMultipleBroodAlerts(alerts, type), [alerts, type]);

    const onMouseLeave = useCallback(() => {
        window.addEventListener('scroll', handleClose, true);
    }, [handleClose]);
    const onMouseEnter = useCallback(() => {
        window.removeEventListener('scroll', handleClose, true);
    }, [handleClose]);

    useEffect(() => {
        onLoad?.(true);
        return () => onLoad?.(false);
    }, [onLoad]);

    const handleCloseClick = useCallback(
        e => {
            e.stopPropagation();
            handleClose();
        },
        [handleClose]
    );

    return (
        <OutsideClickHandler onOutsideClick={handleClose}>
            <div className="alerts-list">
                <div className="list-header">
                    <div className="list-header-title">
                        {groupedAlerts.length} {`Notification${groupedAlerts.length > 1 ? 's' : ''}`}
                    </div>
                    <Icon type="x" onClick={handleCloseClick} />
                </div>
                <div
                    className={cx('list-content', maxHeightClass)}
                    onMouseLeave={onMouseLeave}
                    onMouseEnter={onMouseEnter}
                >
                    {!!groupedAlerts.length &&
                        groupedAlerts.map(alert => (
                            <AlertListItem
                                type={type}
                                key={`header-alert-${alert.id}`}
                                alert={alert}
                                closeAlertList={handleClose}
                            />
                        ))}
                </div>
            </div>
        </OutsideClickHandler>
    );
};

TooltipAlertsList.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    maxHeightClass: PropTypes.string,
    handleClose: PropTypes.func,
    type: PropTypes.string,
    onLoad: PropTypes.func,
};
export default TooltipAlertsList;
