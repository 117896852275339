import React, { memo } from 'react';
import Button from '@beewise/button';
import ProgressBar from '@beewise/progress-bar';
import PropTypes from 'prop-types';

import './ProgressBlock.scss';

const ProgressBlock = ({ title, buttonText, progressBar = false, handleSpecialMode, completed }) => (
    <>
        <div className="progress-bar">
            {title}
            <Button onClick={handleSpecialMode}>{buttonText}</Button>
        </div>
        {progressBar && <ProgressBar completed={completed} />}
    </>
);

ProgressBlock.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    progressBar: PropTypes.bool,
    handleSpecialMode: PropTypes.func,
    completed: PropTypes.number,
};

export default memo(ProgressBlock);
