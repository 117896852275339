import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition } from 'react-transition-state';
import MarkerTooltip from '../MarkerTooltip';

const MarkerWrapper = forwardRef(({ map, id, name, bhomeIds, children }, ref) => {
    const [{ status, isMounted }, toggle] = useTransition({
        mountOnEnter: true,
        unmountOnExit: true,
        preEnter: true,
        timeout: 500,
    });

    const handleHideTooltip = () => toggle(false);

    return (
        <div
            role="presentation"
            className="marker-wrapper"
            onMouseEnter={() => toggle(true)}
            onMouseLeave={handleHideTooltip}
        >
            <div>
                {children}
                {isMounted && (
                    <MarkerTooltip
                        ref={ref}
                        map={map}
                        status={status}
                        id={id}
                        name={name}
                        bhomeIds={bhomeIds}
                        handleHideTooltip={handleHideTooltip}
                    />
                )}
            </div>
        </div>
    );
});

MarkerWrapper.propTypes = {
    children: PropTypes.node,
    map: PropTypes.shape(),
    id: PropTypes.string,
    name: PropTypes.string,
    bhomeIds: PropTypes.arrayOf(PropTypes.number),
};

export default MarkerWrapper;
