import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';

const YardTooltipBadge = ({ iconType, name, text }) => (
    <div className="yard-tooltip-line-badge">
        <div className="yard-tooltip-line-badge-header">
            <Icon type={iconType} className="yard-tooltip-line-badge-header-icon" />
            <div className="yard-tooltip-line-badge-header-subtitle">{name}</div>
        </div>
        <div className="yard-tooltip-line-badge-text">{text}</div>
    </div>
);

YardTooltipBadge.propTypes = {
    iconType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
};

export default YardTooltipBadge;
