import React from 'react';
import PropTypes from 'prop-types';
import { loading } from '@beewise/react-utils';
import Icon from '@beewise/icon';
import { FETCH_ALL_BHOMES_ONLINE } from '../../actionTypes';

import './TotalBeehomesWidget.scss';

const TotalBeehomesWidget = ({ bhomeConnectivityList = [] }) => (
    <div className="widget total-beehomes-widget">
        <Icon type="hive" className="bhome-icon" />
        <div className="total-beehomes-widget-text">
            <div className="secondary-text">Total beehomes</div>
            <div className="total-beehomes-widget-beehome-amount">{bhomeConnectivityList?.length}</div>
        </div>
    </div>
);

TotalBeehomesWidget.propTypes = {
    bhomeConnectivityList: PropTypes.arrayOf(PropTypes.shape()),
};

export default loading(FETCH_ALL_BHOMES_ONLINE.default)(TotalBeehomesWidget, 'total-beehomes-widget-wrapper');
