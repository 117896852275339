import React from 'react';
import cn from 'classnames';
import Icon from '@beewise/icon';
import Tooltip from '@beewise/tooltip';
import { faCalendarCheck, faCamera, faTruck } from '@fortawesome/pro-light-svg-icons';
import NameBlock from '../../NameBlock';

export const ACTION_TYPES = {
    SCAN: 'SCAN',
    FEED: 'FEED',
    VISIT: 'VISIT',
    TRANSPORT: 'TRANSPORT',
};

export const SUB_ACTION_TYPES = {
    FULL_SCAN: 'scan',
    BEE_SCAN: 'beeScan',
};

export const ACTION_MESSAGES_TYPES = {
    FULL_SCAN: 'scan',
    COUNT_BEES: 'countBees',
};

export const SUB_ACTIONS = {
    [SUB_ACTION_TYPES.BEE_SCAN]: {
        name: <NameBlock name="Bee scan" title="Bee population scan" />,
        icon: 'bordered-bee',
        tooltipInfo: 'Single bee scan for unscanned hives',
    },
    [SUB_ACTION_TYPES.FULL_SCAN]: {
        name: <NameBlock name="Full scan" title="Bee population and frame content scan" />,
        icon: 'bordered-box',
        tooltipInfo: 'Single scan for unscanned hives',
    },
};

export const ACTIONS = {
    [ACTION_TYPES.SCAN]: {
        name: 'Scan',
        icon: faCamera,
        subActions: SUB_ACTIONS,
        tooltipInfo: 'Single scan for unscanned hives',
    },
    [ACTION_TYPES.FEED]: {
        name: 'Feed',
        icon: 'feed',
        tooltipInfo: 'Single feeding of 0.45 gal for unfed hives',
    },
    [ACTION_TYPES.VISIT]: {
        name: 'Visit',
        icon: faCalendarCheck,
        tooltipInfo: (
            <p>
                Beehome will stop operating. Visit <br />
                will end automatically tomorrow
            </p>
        ),
    },
    [ACTION_TYPES.TRANSPORT]: {
        name: 'Transport',
        icon: faTruck,
        tooltipInfo: (
            <p>
                Beehome will stop operating until <br />
                transport is marked as finished
            </p>
        ),
    },
};

export const getScanOptions = (
    subActions,
    areAllHivesHaveBeeCountAction,
    areAllHivesHavingScanAction,
    areSelectedHivesHavingBeeCountAction,
    areSelectedHivesHavingScanAction
) =>
    Object.entries(subActions).map(([key, { name, icon, tooltipInfo }]) => {
        const isBeeScanDisabled =
            key === SUB_ACTION_TYPES.BEE_SCAN &&
            (areAllHivesHaveBeeCountAction || areSelectedHivesHavingBeeCountAction);
        const isScanDisabled =
            key === SUB_ACTION_TYPES.FULL_SCAN && (areAllHivesHavingScanAction || areSelectedHivesHavingScanAction);

        return {
            label: (
                <Tooltip content={tooltipInfo}>
                    <div
                        className={cn('tooltip-block', {
                            disabled: isBeeScanDisabled || isScanDisabled,
                        })}
                    >
                        <Icon type={icon} />
                        {name}
                    </div>
                </Tooltip>
            ),
            value: key,
        };
    });
