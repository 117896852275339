const addBhomeToLocation = ({ item, bhomeId }) => ({
    ...item,
    bhome_ids: [...item.bhome_ids, +bhomeId],
    movingToLocation: true,
});

const removeBhomeFromLocation = ({ item, bhomeId }) => ({
    ...item,
    bhome_ids: item.bhome_ids.filter(id => id !== +bhomeId),
});
export const updateBhomeLocation = ({ locations, data }) =>
    locations.map(item => {
        const args = { item, bhomeId: +data.id };
        if (item.id === data.locationId) {
            return addBhomeToLocation(args);
        } else if (item.id === data.prevLocationId) {
            return removeBhomeFromLocation(args);
        }
        return item;
    });
