import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { framesUtils } from '@beewise/utils';
import { isProduction } from 'utils';

const multiplier = isProduction() ? 1.5 : 1;

const calculateHeight = (frame, elementType, showFramesHistory, capacity) => {
    if (!capacity) {
        return 0;
    }

    const elementSum = framesUtils.getElementSum(frame, elementType, showFramesHistory);

    return ((elementSum * multiplier) / capacity) * 100;
};

const normalizeHeights = heights => {
    const total = heights.reduce((acc, height) => acc + height, 0);

    return heights.map(height => (height / total / 2) * 100);
};

const Comb = ({ frame, capacity, showFramesHistory = false }) => {
    const broodEggHeight = calculateHeight(frame, 'larva_0_to_1', showFramesHistory, capacity);
    const broodOpenHeight =
        calculateHeight(frame, 'larva_2_to_3', showFramesHistory, capacity) +
        calculateHeight(frame, 'larva_4_to_5', showFramesHistory, capacity) +
        calculateHeight(frame, 'larva_6_to_7', showFramesHistory, capacity);
    const broodCappedHeight = calculateHeight(frame, 'larva_8_to_21', showFramesHistory, capacity);
    const pollenHeight = calculateHeight(frame, 'pollen', showFramesHistory, capacity);
    const openHoneyHeight = calculateHeight(frame, 'worker-honey', showFramesHistory, capacity);
    const cappedHoneyHeight = calculateHeight(frame, 'caped_honey', showFramesHistory, capacity);

    const heights = [cappedHoneyHeight, openHoneyHeight, pollenHeight, broodCappedHeight, broodOpenHeight];

    const normalizedHeights = normalizeHeights(heights);

    const totalHeight = heights.reduce((acc, height) => acc + height, 0) + broodEggHeight;

    return (
        <div className="comb-frame" style={{ height: `${totalHeight}%` }}>
            <div className="capped-honey" style={{ height: `${normalizedHeights[0]}%` }} />
            <div className="opened-honey" style={{ height: `${normalizedHeights[1]}%` }} />
            <div className="pollen" style={{ height: `${normalizedHeights[2]}%` }} />
            <div className="brood brood-capped" style={{ height: `${normalizedHeights[3]}%` }} />
            <div className="brood brood-open" style={{ height: `${normalizedHeights[4]}%` }} />
            <div className="brood brood-egg" style={{ height: `${broodEggHeight}%` }} />
            <div className="brood brood-open" style={{ height: `${normalizedHeights[4]}%` }} />
            <div className="brood brood-capped" style={{ height: `${normalizedHeights[3]}%` }} />
            <div className="pollen" style={{ height: `${normalizedHeights[2]}%` }} />
            <div className="opened-honey" style={{ height: `${normalizedHeights[1]}%` }} />
            <div className="capped-honey" style={{ height: `${normalizedHeights[0]}%` }} />
        </div>
    );
};

Comb.propTypes = {
    frame: PropTypes.shape().isRequired,
    capacity: PropTypes.number.isRequired,
    showFramesHistory: PropTypes.bool,
};

export default memo(Comb);
