export default ({ modalsConfig }) => ({
  modal: null,
  subs: new Map(),
  subscribe(handler) {
    this.subs.set(handler, handler);

    return () => {
      this.subs.delete(handler);
    };
  },

  set(newModal) {
    this.modal = newModal;
    this.subs.forEach((handler) => handler(this.modal));
  },

  get(modalName) {
    return modalsConfig[modalName] ?? null;
  }
});
