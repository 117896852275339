import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentBhome } from 'components/views/BeeHome/actions';
import { toggleMap } from 'actions';
import { getCurrentBhome } from 'components/views/BeeHome/selectors';
import PropTypes from 'prop-types';

const BhomeList = ({ bhomeIds }) => {
    const navigate = useNavigate();

    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const dispatch = useDispatch();
    const handleBhomeClick = id => {
        if (currentBhome.id !== id) {
            navigate(`/bhome/${id}`);
        }
        dispatch(setCurrentBhome({}));
        dispatch(toggleMap());
    };

    if (!bhomeIds?.length) {
        return null;
    }

    return (
        <>
            <span className="location-name">BH IDs:</span>
            {bhomeIds.map(id => (
                <div
                    key={`location-name-item-${id}`}
                    className="bhome-item"
                    role="presentation"
                    onClick={() => handleBhomeClick(id)}
                >
                    BH-{id}
                </div>
            ))}
        </>
    );
};

BhomeList.propTypes = {
    bhomeIds: PropTypes.arrayOf(PropTypes.number),
};

export default BhomeList;
