import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@beewise/modal';
import Button from '@beewise/button';
import { arrayOfObjectsShallowEqual, shallowEqual } from '@beewise/react-utils';
import constants from 'appConstants';
import { getCurrentBhomeFrames, getCurrentBhome } from 'components/views/BeeHome/selectors';
import { fetchMoveFramesV4 } from 'components/reusables/Hives/actions/move.frames.v4.actions';
import { prepareFrames } from 'components/reusables/RightPanel/components/utils';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

import './HiveManagementModal.scss';

const getPartitionBoundsForHive = ({ frames, selectedHive }) => {
    const { startPartitionX } = frames.reduce(
        (acc, frame) => {
            if (frame.place.station !== selectedHive.station || frame.type !== constants.FRAME_TYPES.STATIC_PARTITION) {
                return acc;
            }

            const { x: frameX } = frame.place.position;

            if (frameX < selectedHive.from && frameX > acc.startPartitionX) {
                acc.startPartitionX = frameX;
            }

            return acc;
        },
        { startPartitionX: 0 }
    );

    return { startPartitionX, endPartitionX: selectedHive.to };
};

const getNewSelectedFrames = ({ frames, selectedHive }) => {
    const { startPartitionX, endPartitionX } = getPartitionBoundsForHive({ frames, selectedHive });

    return frames.filter(({ place }) => {
        const {
            position: { x: frameX },
            station,
        } = place;

        return frameX > startPartitionX && frameX <= endPartitionX && station === selectedHive.station;
    });
};

const ModalContent = ({ body, footerText, submitButton, handleClose }) => (
    <>
        <div className="body">{body}</div>
        <div className="footer">
            <p className="text">{footerText}</p>
            <div className="buttons">
                <Button className="btn-secondary" onClick={handleClose}>
                    Cancel
                </Button>
                {submitButton}
            </div>
        </div>
    </>
);

ModalContent.propTypes = {
    body: PropTypes.node.isRequired,
    footerText: PropTypes.string.isRequired,
    submitButton: PropTypes.element.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const HiveManagementModal = ({ isOpen, title, onClose }) => {
    const dispatch = useDispatch();
    const frames = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);
    const bhome = useSelector(getCurrentBhome, shallowEqual);
    const [currentStep, setCurrentStep] = useState(1);
    const [selectedHive, setSelectedHive] = useState();
    const [selectedFrames, setSelectedFrames] = useState([]);
    const [movedFrames, setMovedFrames] = useState({ frames: [], actions: [] });

    const handleClose = useCallback(() => {
        setCurrentStep(1);
        setSelectedHive(null);
        setMovedFrames({ frames: [], actions: [] });
        onClose();
    }, [onClose]);

    const handleNextClick = useCallback(() => {
        if (!selectedHive) {
            return;
        }

        const newSelectedFrames = getNewSelectedFrames({ frames, selectedHive });

        setSelectedFrames(newSelectedFrames);
        setMovedFrames({
            frames: newSelectedFrames.map((frame, index) => ({
                ...frame,
                initialLayoutFrameIndex: index,
            })),
            actions: [],
        });
        setCurrentStep(2);
    }, [frames, selectedHive]);

    const handleDone = useCallback(() => {
        try {
            const changedHiveStartIndex = frames.findIndex(frame => frame.id === selectedFrames[0].id);
            const preparedFrames = frames.map(prepareFrames);

            preparedFrames.splice(
                changedHiveStartIndex,
                movedFrames.frames.length,
                ...movedFrames.frames.map(prepareFrames)
            );

            movedFrames.actions?.length &&
                dispatch(
                    fetchMoveFramesV4(bhome.id, {
                        actions: movedFrames.actions,
                        newFramesLayout: preparedFrames.map((frame, index) => ({ ...frame, id: index })),
                    })
                );

            handleClose();
        } catch (e) {
            handleClose();
        }
    }, [bhome.id, dispatch, frames, handleClose, movedFrames, selectedFrames]);

    return (
        <Modal className="hive-managment-modal" header={title} isOpen={isOpen} onClose={handleClose} size="large">
            {currentStep === 1 && (
                <ModalContent
                    body={<StepOne selectedHive={selectedHive} onHiveSelect={setSelectedHive} />}
                    footerText="Select the hive you would like to move frames in"
                    submitButton={
                        <Button className="btn-primary" disabled={!selectedHive} onClick={handleNextClick}>
                            Next
                        </Button>
                    }
                    handleClose={handleClose}
                />
            )}
            {currentStep === 2 && (
                <ModalContent
                    body={
                        <StepTwo
                            selectedFrames={selectedFrames}
                            movedFrames={movedFrames}
                            setMovedFrames={setMovedFrames}
                            settings={bhome?.settings}
                            frames={frames}
                        />
                    }
                    footerText="Select a frame and drag it to desired position"
                    submitButton={
                        <Button
                            className="btn-primary"
                            onClick={handleDone}
                            disabled={
                                !movedFrames.frames.some((frame, index) => frame.initialLayoutFrameIndex !== index)
                            }
                        >
                            Done
                        </Button>
                    }
                    handleClose={handleClose}
                />
            )}
        </Modal>
    );
};

HiveManagementModal.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default memo(HiveManagementModal);
