import React, { useCallback } from 'react';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@beewise/icon';
import { FixedSizeList as List } from 'react-window';
import constants from 'appConstants';
import { getNotifications } from 'components/reusables/Alerts/selectors';
import { changeAlertPaneState } from 'actions';
import AlertListItem from './AlertListItem';

import './Alerts.scss';
import { groupMultipleBroodAlerts } from './utils';

const AlertListRow = ({ index, style, alerts, closeAlertList }) => (
    <AlertListItem
        style={style}
        type={constants.ALERT_COMPONENT_LEVEL.GENERAL}
        key={`header-alert-${alerts[index].id}`}
        alert={alerts[index]}
        closeAlertList={closeAlertList}
    />
);

AlertListRow.propTypes = {
    index: PropTypes.number,
    style: PropTypes.shape(),
    alerts: PropTypes.arrayOf(PropTypes.shape()),
    closeAlertList: PropTypes.func,
};

const AlertsList = ({ onClose }) => {
    const dispatch = useDispatch();
    const type = constants.ALERT_COMPONENT_LEVEL.GENERAL;
    const alerts = useSelector(getNotifications, arrayOfObjectsShallowEqual);
    const groupedAlerts = groupMultipleBroodAlerts(alerts, type);

    const handleClose = useCallback(() => {
        dispatch(changeAlertPaneState(false));
    }, [dispatch]);

    const renderRow = useCallback(
        ({ index, style }) => (
            <AlertListRow index={index} style={style} alerts={groupedAlerts} closeAlertList={handleClose} />
        ),
        [groupedAlerts, handleClose]
    );

    return (
        <div className="alerts-list alerts-list-general">
            <div className="list-header">
                <div className="list-header-title">
                    <span className="strong">{groupedAlerts.length}</span> unread{' '}
                </div>
                <Icon type="x" onClick={onClose} />
            </div>
            <div className="list-content">
                {!!groupedAlerts.length && (
                    <List
                        className="left-panel-bhomes-list"
                        height={groupedAlerts.length ? 585 : 0}
                        itemCount={groupedAlerts.length}
                        itemSize={115}
                        width={groupedAlerts.length ? 320 : 0}
                    >
                        {renderRow}
                    </List>
                )}
            </div>
        </div>
    );
};

AlertsList.propTypes = {
    onClose: PropTypes.func,
};

export default AlertsList;
