import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { framesUtils } from '@beewise/utils';
import { max } from 'lodash-es';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getCurrentBhomeFrames } from '../../components/views/BeeHome/selectors';
import { getAppSettings } from '../../selectors';
import { getCombFramesWithinHive } from '..';

const FRAME_SIDES = ['sideA', 'sideB'];
const PREV_FRAMES_SIDES = ['previousSideA', 'previousSideB'];

export const getBroodBeeAmounts = (hive, appSettings) => {
    const broodFrameThreshold = appSettings?.broodFrameThreshold || 0;
    const beePopulationThreshold = appSettings?.beePopulationThreshold || 0;

    return hive.reduce(
        (acc, frame) => {
            acc.hasScans =
                acc.hasScans ||
                !!frame?.sideA?.scanTimestamp ||
                !!frame?.sideB?.scanTimestamp ||
                !!frame?.sideA?.beeCount?.scanTimestamp ||
                !!frame.sideB?.beeCount?.scanTimestamp;
            const hasBeeCount = FRAME_SIDES.some(side => frame[side]?.beeCount);
            const hasPrevBeeCount = PREV_FRAMES_SIDES.some(side => frame[side]?.beeCount);

            if (!acc.beeFramesAmount && hasBeeCount) {
                acc.beeFramesAmount = 0;
            }
            if (!acc.historyBeeFramesAmount && hasPrevBeeCount) {
                acc.historyBeeFramesAmount = 0;
            }
            if (framesUtils?.isBroodFrame({ frame, threshold: broodFrameThreshold })) {
                acc.broodFramesAmount += 1;
            }
            if (framesUtils?.isBroodFrame({ frame, threshold: broodFrameThreshold, isHistory: true })) {
                acc.historyBroodFramesAmount += 1;
            }
            if (framesUtils?.isBeeFrame({ frame, threshold: beePopulationThreshold })) {
                acc.beeFramesAmount += 1;
            }
            if (framesUtils?.isBeeFrame({ frame, threshold: beePopulationThreshold, isHistory: true })) {
                acc.historyBeeFramesAmount += 1;
            }

            return acc;
        },
        {
            broodFramesAmount: 0,
            historyBroodFramesAmount: 0,
            beeFramesAmount: null,
            historyBeeFramesAmount: null,
        }
    );
};

export default hive => {
    const frames = useSelector(getCurrentBhomeFrames, arrayOfObjectsShallowEqual);
    const appSettings = useSelector(getAppSettings, shallowEqual);

    const framesInCurrentHive = useMemo(() => getCombFramesWithinHive(frames, hive), [frames, hive]);

    const { broodFramesAmount, historyBroodFramesAmount, beeFramesAmount, historyBeeFramesAmount } = useMemo(
        () => getBroodBeeAmounts(framesInCurrentHive, appSettings),
        [appSettings, framesInCurrentHive]
    );

    const lastScanDate = useMemo(() => {
        const scanTimestamps = framesInCurrentHive.flatMap(frame =>
            FRAME_SIDES.flatMap(side => [frame[side]?.scanTimestamp].filter(Boolean))
        );

        return max(scanTimestamps);
    }, [framesInCurrentHive]);

    const lastBeeScanDate = useMemo(() => {
        const scanTimestamps = framesInCurrentHive.flatMap(frame =>
            FRAME_SIDES.flatMap(side => [frame[side]?.beeCount?.scanTimestamp].filter(Boolean))
        );

        return max(scanTimestamps);
    }, [framesInCurrentHive]);

    return {
        broodFramesAmount,
        historyBroodFramesAmount,
        beeFramesAmount,
        historyBeeFramesAmount,
        lastScanDate,
        lastBeeScanDate,
        framesAmount: framesInCurrentHive.length,
    };
};
