import { createAsyncAction } from '@beewise/utils';

export const FETCH_ACTIVITIES = createAsyncAction('FETCH_ACTIVITIES');
export const CANCEL_ACTIVITIES = createAsyncAction('CANCEL_ACTIVITIES');
export const TOGGLE_HALLWAY_STREAM = createAsyncAction('TOGGLE_HALLWAY_STREAM');
export const SET_HALLWAY_VIDEO_URL = 'SET_HALLWAY_VIDEO_URL';
export const CHANGE_ACTIVITES_POSITION_STATE = 'CHANGE_ACTIVITES_POSITION_STATE';
export const SET_ACTION_MODE = 'SET_ACTION_MODE';
export const SET_ENTRANCES_TO_OPERATE = 'SET_ENTRANCES_TO_OPERATE';
export const ADD_UNSAVED_ACTIVITIES = 'ADD_UNSAVED_ACTIVITIES';
export const REMOVE_UNSAVED_ACTIVITIES = 'REMOVE_UNSAVED_ACTIVITIES';
export const CLEAR_UNSAVED_ACTIVITIES = 'CLEAR_UNSAVED_ACTIVITIES';
export const FETCH_MODE_IN_PROGRESS = createAsyncAction('FETCH_MODE_IN_PROGRESS');
