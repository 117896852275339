const parseSide = (frame, frameImages, sideName) => {
    if (!frame[sideName] || !frameImages[sideName]?.fileName) {
        return null;
    }

    return {
        annotatedFileName: frameImages[sideName]?.annotatedFileName,
        resizedAnnotatedFileName: frameImages[sideName]?.resizedAnnotatedFileName,
    };
};

export const parseImageName = (frame, frameImages, sideName) => {
    if (!frame || !frameImages || !sideName) {
        return {};
    }

    const sideResult = parseSide(frame, frameImages, sideName);

    return sideResult
        ? {
              annotatedFileName: sideResult.annotatedFileName,
              resizedAnnotatedFileName: sideResult.resizedAnnotatedFileName,
          }
        : {};
};
