import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { SelectField } from '@beewise/select-field';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { noop } from 'lodash-es';
import { getDoneMessagesWithHives, getPendingMessagesWithHives } from 'components/views/BeeHome/selectors';
import { cancelActions, fetchHistoryMessages } from 'components/views/BeeHome/actions';
import Icon from 'components/reusables/Icon';
import { getCurrentSnapshotDate } from 'components/views/Bhome/selectors';
import { FILTER_OPTIONS, ALL_ACTIONS, GAP, HEADERS, PANEL_WIDTH, isHighlighted } from './utils';
import MessageItem from './ActionListItem';
import ActionsHeader from './ActionsHeader';
import useVirtualizedList from './useVirtualizedList';
import { getCurrentBhomeId } from '../../../../selectors';

import './ActionPanel.scss';

const EmptyStateComponent = ({ style, name }) => (
    <div style={style} className="action-list__empty">
        <Icon icon="empty-box" />
        No actions in {name}
    </div>
);

EmptyStateComponent.propTypes = {
    style: PropTypes.string,
    name: PropTypes.string,
};

const ActionPanel = ({ onClose }) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState(ALL_ACTIONS.value);
    const pendingMessages = useSelector(state => getPendingMessagesWithHives(state, filter));
    const historyMessages = useSelector(state => getDoneMessagesWithHives(state, filter), arrayOfObjectsShallowEqual);
    const bhomeId = useSelector(getCurrentBhomeId);
    const historyDate = useSelector(getCurrentSnapshotDate);

    const listData = useMemo(
        () => [
            HEADERS[0],
            ...(pendingMessages.length
                ? pendingMessages.map(it => ({ ...it, isCancelable: true }))
                : [{ isEmpty: true, name: 'queue' }]),
            HEADERS[1],
            ...(historyMessages.length ? historyMessages : [{ isEmpty: true, name: 'history' }]),
        ],
        [pendingMessages, historyMessages]
    );

    const handleCancelActions = messageIds =>
        dispatch(
            cancelActions({
                bhomeId,
                messageIds,
                toastText: 'Actions were canceled',
                resolver: () => {
                    dispatch(fetchHistoryMessages(bhomeId));
                },
            })
        );

    const handleCancelAll = () => handleCancelActions(pendingMessages.flatMap(({ data }) => data.messages));

    const { visibleStartIndex, listRef, getItemSize, setVisibleStartIndex } = useVirtualizedList({ data: listData });

    const renderRow = ({ index, style }) => {
        const item = listData[index];

        const styleProps = {
            ...style,
            top: style.top + (index - visibleStartIndex) * GAP,
            maxWidth: PANEL_WIDTH,
        };

        if (item.isEmpty) {
            return <EmptyStateComponent key={index} style={styleProps} name={item.name} />;
        }

        return item?.header ? (
            <ActionsHeader key={index} handleCancelAll={handleCancelAll} style={styleProps} {...item} />
        ) : (
            <MessageItem
                key={index}
                style={styleProps}
                handleCancel={item.isCancelable ? handleCancelActions : noop}
                className={cn({
                    'is-highlighted': isHighlighted(item, historyDate),
                })}
                {...item}
            />
        );
    };

    useEffect(() => {
        const highlightedIndex = listData.findIndex(item => isHighlighted(item, historyDate));

        if (highlightedIndex < 0) {
            return;
        }

        const checkListRefAndScroll = () => {
            if (listRef.current) {
                listRef.current.scrollToItem(highlightedIndex, 'start');
            } else {
                requestAnimationFrame(checkListRefAndScroll);
            }
        };

        requestAnimationFrame(checkListRefAndScroll);
    }, [listData, listRef, historyDate]);

    return (
        <div className="right-panel-content action-panel">
            <header>
                <FontAwesomeIcon icon={faXmark} className="frame-panel-header-top-left-icon" onClick={onClose} />
                <h3>Beehome information</h3>
                <SelectField options={FILTER_OPTIONS} value={filter} onChange={setFilter} size="xsmall" />
            </header>
            <section>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            onItemsRendered={({ visibleStartIndex }) => setVisibleStartIndex(visibleStartIndex)}
                            ref={listRef}
                            height={height}
                            itemCount={listData?.length}
                            itemSize={getItemSize}
                            width={width}
                            className="action-list"
                        >
                            {renderRow}
                        </List>
                    )}
                </AutoSizer>
            </section>
        </div>
    );
};

ActionPanel.propTypes = {
    onClose: PropTypes.func,
};

export default ActionPanel;
