import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '@beewise/modal';
import Button from '@beewise/button';

import './AssessmentModal.scss';

const AssessmentModal = ({ isOpen, title, contentText, submitText, onSubmit, onClose }) => (
    <Modal className="assessment-modal" header={title} isOpen={isOpen} onClose={onClose} size="small">
        <p className="body">{contentText}</p>
        <div className="buttons">
            <Button className="btn-secondary" onClick={onClose}>
                Cancel
            </Button>
            <Button className="btn-primary" onClick={onSubmit}>
                {submitText}
            </Button>
        </div>
    </Modal>
);

AssessmentModal.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    contentText: PropTypes.string,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

export default memo(AssessmentModal);
