import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, useAsyncPoll } from '@beewise/react-utils';
import OnlineWidget from './components/OnlineWidget';
import { fetchAllBhomesOnline, fetchDashboardGridData, fetchBhomesFeedStatuses } from './actions';
import TotalBeehomesWidget from './components/TotalBeehomesWidget';
import DashboardGrid from './components/DashboardGrid';
import PoliciesWidget from './components/PoliciesWidget';
import PerformanceWidget from './components/PerformanceWidget';
import { getBhomesOnline, getGridData, getIsGridFetched } from './selectors';
import { POLL_BHOMES_ONLINE_TIME } from './utils';

import './Dashboard.scss';

const Dashboard = () => {
    const dispatch = useDispatch();
    const bhomeConnectivityList = useSelector(getBhomesOnline, arrayOfObjectsShallowEqual);
    const gridData = useSelector(getGridData, arrayOfObjectsShallowEqual);
    const isGridFetched = useSelector(getIsGridFetched);

    useEffect(() => {
        dispatch(fetchAllBhomesOnline());
        // TODO: add offset / limit logic
        dispatch(
            fetchDashboardGridData({ offset: 0, limit: 10000, type: '' }, () => dispatch(fetchBhomesFeedStatuses()))
        );
    }, [dispatch]);

    useAsyncPoll(() => {
        dispatch(fetchAllBhomesOnline());
    }, POLL_BHOMES_ONLINE_TIME);

    return (
        <div className="dashboard">
            <div className="dashboard-widgets">
                <TotalBeehomesWidget bhomeConnectivityList={bhomeConnectivityList} />
                <OnlineWidget bhomeConnectivityList={bhomeConnectivityList} />
                <PoliciesWidget data={gridData} />
                <PerformanceWidget data={gridData} />
            </div>
            <div className="dashboard-grid">
                <DashboardGrid data={gridData} isFetched={isGridFetched} />
            </div>
        </div>
    );
};

export default Dashboard;
