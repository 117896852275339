import React from 'react';
import Icon from 'components/reusables/Icon';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import './LastActionStatus.scss';

const LastActionStatus = ({ date, icon, name }) => (
    <span className="last-status">
        <Icon icon={icon} />
        <p>
            {name}: <b>{date ? dayjs(date).format('D MMM') : 'N/A'} </b>
        </p>
    </span>
);

export default LastActionStatus;

LastActionStatus.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]).isRequired,
    name: PropTypes.string,
};
