import {
    SHOW_ACTIVITY_BAR,
    CHANGE_ALERT_PANE_STATE,
    CHANGE_CURRENT_USER,
    SET_ENTRANCES_STATE,
    SET_FULL_SCREEN_IMAGE,
    TOGGLE_MAP,
    TOGGLE_INITIAL_RENDER,
    TOGGLE_RIGHT_PANEL,
    TOGGLE_BLOG_PANEL,
    FETCH_APP_SETTINGS,
} from 'actionTypes';

export const fetchAppSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_APP_SETTINGS.default,
        method: 'GET',
        url: '/settings/app/beekeeper',
    },
});

export const showActivityBar = () => ({
    type: SHOW_ACTIVITY_BAR,
});

export const changeAlertPaneState = isAlertPaneShown => ({
    type: CHANGE_ALERT_PANE_STATE,
    isAlertPaneShown,
});

export const changeCurrentUser = token => ({
    type: CHANGE_CURRENT_USER,
    data: {
        token,
    },
});

export const setEntrancesState = entrances => ({
    type: SET_ENTRANCES_STATE,
    entrances,
});

export const setFullScreenImage = (image, side) => ({
    type: SET_FULL_SCREEN_IMAGE,
    image,
    side,
});

export const toggleMap = isShown => ({
    type: TOGGLE_MAP,
    isShown,
});

export const toggleRightPanel = isShown => ({
    type: TOGGLE_RIGHT_PANEL,
    isShown,
});

export const toggleBlogPanel = isShown => ({
    type: TOGGLE_BLOG_PANEL,
    isShown,
});

// Do not delete this code. It is necessary for handling changes of the company and displaying all bhomes.
// When an item is clicked in the left panel, it shows the current bhome.
export const toggleInitialRender = isInitialRender => ({
    type: TOGGLE_INITIAL_RENDER,
    isInitialRender,
});
