import {
    FETCH_BHOMES_FRAMES_CONTENT_COUNT,
    FETCH_AGGREGATED_MESSAGES,
    FETCH_AGGREGATED_TEMPERATURE,
} from '../actionTypes';

const initialState = {
    broodFramesCount: [],
    aggregatedMessages: [],
    aggregatedTemperature: [],
};

const reports = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BHOMES_FRAMES_CONTENT_COUNT.success:
            return {
                ...state,
                framesDataCount: action.data,
            };
        case FETCH_AGGREGATED_MESSAGES.success:
            return {
                ...state,
                aggregatedMessages: action.data,
            };
        case FETCH_AGGREGATED_TEMPERATURE.success:
            return {
                ...state,
                aggregatedTemperature: action.data,
            };
        default:
            return state;
    }
};

export default reports;
