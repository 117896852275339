import {
    CHANGE_USER_ROLE,
    CLOSE_INVITATION_MODEL,
    FETCH_DELETE_USER,
    GET_COMPANY,
    INVITE_USERS,
    OPEN_INVITATION_MODEL,
    FETCH_USER_SETTINGS,
    FETCH_UPDATE_USER_ALERTS_SETTINGS,
    FETCH_PREFERENCES,
    FETCH_UPDATE_PREFERENCES,
    CHANGE_COMING_SOON_PREFERENCES,
    FETCH_REGIONS,
    FETCH_CREATE_REGION,
    FETCH_UPDATE_REGION,
    FETCH_DELETE_REGION,
    CREATE_NEW_REGION,
    DELETE_NEW_REGION,
} from '../actionTypes';

export const inviteUsers = (emails, role, resolver) => ({
    type: 'FETCH',
    params: {
        type: INVITE_USERS.default,
        method: 'POST',
        url: '/users/invite',
        body: {
            users: emails.split(',').map(email => ({ email: email.trim(), role })),
        },
        resolver,
    },
});

export const fetchUpdateUserAlertsSettings = (settings, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_USER_ALERTS_SETTINGS.default,
        method: 'PUT',
        url: '/users/update-settings',
        body: {
            settings,
        },
        resolver,
    },
});

export const deleteUser = (id, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_USER.default,
        method: 'DELETE',
        url: `/users/${id}`,
        resolver,
    },
});

export const getCompany = () => ({
    type: 'FETCH',
    params: {
        type: GET_COMPANY.default,
        method: 'GET',
        url: '/companies/company',
    },
});

export const fetchUserSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER_SETTINGS.default,
        method: 'GET',
        url: '/users/get-settings',
    },
});

export const fetchUserPreferences = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_PREFERENCES.default,
        method: 'GET',
        url: '/settings/get-preferences',
    },
});

export const fetchRegions = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_REGIONS.default,
        method: 'GET',
        url: '/regions',
    },
});

export const fetchCreateRegion = body => ({
    type: 'FETCH',
    params: {
        type: FETCH_CREATE_REGION.default,
        method: 'POST',
        url: '/regions',
        body,
    },
});

export const fetchUpdateRegion = (id, body) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_REGION.default,
        method: 'PUT',
        url: `/regions/${id}`,
        body,
    },
});

export const fetchDeleteRegion = id => ({
    type: 'FETCH',
    params: {
        type: FETCH_DELETE_REGION.default,
        method: 'DELETE',
        url: `/regions/${id}`,
    },
});

export const updatePreferences = ({ preferences }, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_PREFERENCES.default,
        method: 'PUT',
        url: '/bhomes/update-bhomes-preferences',
        body: {
            preferences,
        },
        resolver,
    },
});

export const updateRole = (id, role) => ({
    type: 'FETCH',
    params: {
        type: CHANGE_USER_ROLE.default,
        method: 'PUT',
        url: `/users/${id}`,
        body: {
            user: {
                role,
            },
        },
    },
});

export const openInvitationModel = () => ({
    type: OPEN_INVITATION_MODEL,
});

export const changeComingSoonPreferences = key => ({
    type: CHANGE_COMING_SOON_PREFERENCES,
    key,
});

export const closeInvitationModel = () => ({
    type: CLOSE_INVITATION_MODEL,
});

export const createNewRegion = () => ({
    type: CREATE_NEW_REGION,
});
export const deleteNewRegion = () => ({
    type: DELETE_NEW_REGION,
});
