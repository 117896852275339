import React from 'react';
import PropTypes from 'prop-types';
import RegionListItem from './RegionListItem';

const RegionsList = ({ regions, yards, newRegion, availableYards, ranches, availableRanches }) => (
    <div className="regions-list">
        {regions.map(region => (
            <RegionListItem
                key={region.id}
                region={region}
                yards={yards}
                availableYards={availableYards}
                ranches={ranches}
                availableRanches={availableRanches}
            />
        ))}
        {newRegion && (
            <RegionListItem
                region={newRegion}
                yards={yards}
                isCreate
                availableYards={availableYards}
                ranches={ranches}
                availableRanches={availableRanches}
            />
        )}
    </div>
);

RegionsList.propTypes = {
    regions: PropTypes.arrayOf(PropTypes.shape()),
    yards: PropTypes.arrayOf(PropTypes.shape()),
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    availableYards: PropTypes.arrayOf(PropTypes.shape()),
    availableRanches: PropTypes.arrayOf(PropTypes.shape()),
    newRegion: PropTypes.shape(),
};

export default RegionsList;
