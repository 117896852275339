import { useEffect, useState } from 'react';

const useLoadingEffect = dependencies => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 50);
        return () => {
            clearTimeout(timer);
            setIsLoading(true);
        };
    }, [dependencies]);

    return isLoading;
};

export default useLoadingEffect;
