import React from 'react';
import PropTypes from 'prop-types';

const ActionsHeader = ({ handleCancelAll, name, isCancelable, style }) => (
    <div className="row" style={style}>
        <h4>{name}</h4>
        {isCancelable && (
            <span className="action-panel-cancel-all" role="presentation" onClick={handleCancelAll}>
                Cancel all
            </span>
        )}
    </div>
);

ActionsHeader.propTypes = {
    handleCancelAll: PropTypes.func,
    name: PropTypes.string,
    isCancelable: PropTypes.bool,
    style: PropTypes.shape(),
};

export default ActionsHeader;
