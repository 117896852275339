import React from 'react';
import { SelectField } from '@beewise/select-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './SelectBadge.scss';

const SelectBadge = ({ icon, ...props }) => (
    <SelectField
        {...props}
        className="select-badge"
        placeholder={
            <span className="placeholder">
                <FontAwesomeIcon icon={icon} />
                Scan
            </span>
        }
        isSearchable={false}
    />
);

SelectBadge.propTypes = {
    icon: PropTypes.shape().isRequired,
};

export default SelectBadge;
