import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import SearchableGrid, { useGridApiRef } from '@beewise/searchable-grid';
import PropTypes from 'prop-types';
import gridStyles from './styles';

const INITIAL_STATE = {
    pagination: { paginationModel: { pageSize: 50 } },
};

const Grid = ({ data, isFetched, setApiRef, ...props }) => {
    const navigate = useNavigate();
    const apiRef = useGridApiRef();

    useEffect(() => {
        setApiRef(apiRef);
    }, [apiRef, setApiRef]);

    const handleOnCellClick = ({ field, row }) => {
        if (field === 'id') {
            navigate(`/bhome/${row.id}`);
        }
    };
    return (
        <SearchableGrid
            apiRef={apiRef}
            rows={data}
            className="searchable-grid"
            isSearchHidden
            sx={gridStyles}
            pagination
            initialState={INITIAL_STATE}
            disableColumnSelector
            disableDensitySelector
            loading={!isFetched}
            onCellClick={handleOnCellClick}
            {...props}
        />
    );
};

Grid.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    isFetched: PropTypes.bool,
    setApiRef: PropTypes.func,
    disableRowSelectionOnClick: PropTypes.bool,
    checkboxSelection: PropTypes.bool,
};

export default Grid;
