import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faXmark } from '@fortawesome/pro-light-svg-icons';
import Icon from 'components/reusables/Icon';
import constants from 'appConstants';
import { ACTIONS, CANCELED_TITLE, FRAME_COMMANDS } from '../utils';
import FrameAction from './FrameAction';
import './ActionListItem.scss';

const formatDate = date => {
    const options = { day: 'numeric', month: 'long' };

    return date.toLocaleDateString('en-US', options);
};

const getPrefix = command => {
    switch (command) {
        case constants.COMMANDS.MOVE_TO_VISIT_POSITION:
            return 'At';
        case constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION:
            return 'From';
        default:
            return 'To';
    }
};

const CloudAction = ({ command, data: { assignedTo } }) => (
    <>
        <Icon icon={faLocationDot} />
        {assignedTo ? `${getPrefix(command)} ${assignedTo}` : null}
    </>
);

CloudAction.propTypes = {
    command: PropTypes.string.isRequired,
    data: PropTypes.shape({
        assignedTo: PropTypes.string,
    }),
};

const getSubheader = (command, messageData) => {
    const isFrameCommand = FRAME_COMMANDS.includes(command);

    if (isFrameCommand) {
        return <FrameAction {...messageData} />;
    }

    return <CloudAction {...messageData} command={command} />;
};

const transformCommand = command =>
    [constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION, constants.COMMANDS.END_TRANSPORTATION_MODE].includes(command)
        ? constants.COMMANDS.MOVE_TO_TRANSPORTATION_POSITION
        : command;

const ActionListItem = ({ command, user, sentAt, data, handleCancel, style, isCancelable, status, className }) => {
    const { icon, label } = ACTIONS[transformCommand(command)] ?? {};
    const handleCancelAllActions = () => handleCancel(data.messages);

    const handleCancelHiveAction = index => () => handleCancel([data.messages[index]]);

    return (
        <div className={cn('message-item', { [className]: !!className })} style={style}>
            <div className="row message-item-title">
                <Icon icon={icon} />
                <h3>{label}</h3>
                {status === constants.MESSAGE_STATUS.CANCELED && (
                    <div className="message-item-status">{CANCELED_TITLE}</div>
                )}
                <span className="message-item-date">
                    {formatDate(sentAt)}
                    {isCancelable && (
                        <FontAwesomeIcon
                            icon={faXmark}
                            className="message-item-cancel"
                            onClick={handleCancelAllActions}
                        />
                    )}
                </span>
            </div>
            <div className="row message-item-subtitle">
                {getSubheader(command, { data, handleCancelHiveAction, isCancelable })}
                <span className="message-item-date">{user || 'system'}</span>
            </div>
        </div>
    );
};

ActionListItem.propTypes = {
    command: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    sentAt: PropTypes.instanceOf(Date).isRequired,
    data: PropTypes.shape(),
    handleCancel: PropTypes.func.isRequired,
    style: PropTypes.shape(),
    isCancelable: PropTypes.bool,
    status: PropTypes.string,
    className: PropTypes.string,
};

export default ActionListItem;
